import { Dropdown } from "react-bootstrap";
import classNames from "classnames";
import { includes, without } from "lodash";
import PropTypes from "prop-types";

import { Button } from "@dpdgroupuk/mydpd-ui";

import { FILTERS, LOCKER, SHOP } from "~/constants/strings";
import {
  PICKUP_POINT_AMENITIES_LABELS,
  PICKUP_POINT_AMENITIES_TYPE,
  PICKUP_POINT_TYPE,
} from "~/constants/pickupPoint";
import { Check, Filter } from "~/components/Icon";
import SearchInput from "./SearchInput";

import styles from "./Filters.module.scss";

const FilterButton = ({ label, isSelected, onClick }) => (
  <Button
    className={classNames(
      "mr-2 w-100 rounded",
      isSelected ? styles.filterButtonSelected : styles.filterButton
    )}
    variant={isSelected ? "danger" : "outline-dark"}
    onClick={onClick}
  >
    {label.toUpperCase()}
  </Button>
);

FilterButton.propTypes = {
  label: PropTypes.string,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

const Filters = ({
  selectedTypes,
  setSelectedTypes,
  selectedAmenities,
  setSelectedAmenities,
  deliveryAddress,
  onSearchPickupLocations,
}) => {
  const isShopFilterSelected = includes(selectedTypes, PICKUP_POINT_TYPE.SHOP);
  const isLockerFilterSelected = includes(
    selectedTypes,
    PICKUP_POINT_TYPE.LOCKER
  );

  const onFilterButtonClick = type => {
    if (includes(selectedTypes, type)) {
      if (selectedTypes.length === 1) {
        setSelectedTypes([
          type === PICKUP_POINT_TYPE.SHOP
            ? PICKUP_POINT_TYPE.LOCKER
            : PICKUP_POINT_TYPE.SHOP,
        ]);
      } else {
        setSelectedTypes(without(selectedTypes, type));
      }
    } else {
      setSelectedTypes([...selectedTypes, type]);
    }
  };

  const AmenitiesFilterButton = ({ id, label }) => {
    const isChecked = includes(selectedAmenities, id);

    return (
      <div
        className={classNames(
          styles.dropdownButton,
          styles.amenitiesFilterButton,
          "py-2 px-4"
        )}
        onClick={() =>
          setSelectedAmenities(
            isChecked
              ? without(selectedAmenities, id)
              : [...selectedAmenities, id]
          )
        }
      >
        <span className="mr-1">{label}</span>
        {isChecked && <Check className={styles.checkIcon} />}
      </div>
    );
  };

  return (
    <div className="d-flex justify-content-between px-4 py-3 bg-white">
      <SearchInput
        className="mr-2"
        deliveryAddress={deliveryAddress}
        onSubmit={onSearchPickupLocations}
      />
      <div className="d-flex">
        <Dropdown autoClose="inside" className="mr-2">
          <Dropdown.Toggle
            variant="outline-dark"
            id="dropdown-autoclose-inside"
            className={classNames(
              styles.toggleButton,
              styles.amenitiesFilterToggle,
              "d-flex align-items-center rounded"
            )}
          >
            <span className="mr-1">{FILTERS.toUpperCase()}</span>
            <Filter size="20" />
          </Dropdown.Toggle>

          <Dropdown.Menu className={styles.amenitiesFilterMenu}>
            <AmenitiesFilterButton
              id={PICKUP_POINT_AMENITIES_TYPE.LABEL_PRINTING}
              label={PICKUP_POINT_AMENITIES_LABELS.LABEL_PRINTING}
            />
            <AmenitiesFilterButton
              id={PICKUP_POINT_AMENITIES_TYPE.OPEN_LATE}
              label={PICKUP_POINT_AMENITIES_LABELS.OPEN_LATE}
            />
            <AmenitiesFilterButton
              id={PICKUP_POINT_AMENITIES_TYPE.CAR_PARKING}
              label={PICKUP_POINT_AMENITIES_LABELS.CAR_PARKING}
            />
            <AmenitiesFilterButton
              id={PICKUP_POINT_AMENITIES_TYPE.OPEN_SATURDAYS}
              label={PICKUP_POINT_AMENITIES_LABELS.OPEN_SATURDAYS}
            />
            <AmenitiesFilterButton
              id={PICKUP_POINT_AMENITIES_TYPE.OPEN_SUNDAYS}
              label={PICKUP_POINT_AMENITIES_LABELS.OPEN_SUNDAYS}
            />
            <AmenitiesFilterButton
              id={PICKUP_POINT_AMENITIES_TYPE.WHEELCHAIR_ACCESS}
              label={PICKUP_POINT_AMENITIES_LABELS.WHEELCHAIR_ACCESS}
            />
          </Dropdown.Menu>
        </Dropdown>

        <FilterButton
          label={SHOP}
          isSelected={isShopFilterSelected}
          onClick={() => onFilterButtonClick(PICKUP_POINT_TYPE.SHOP)}
        />
        <FilterButton
          label={LOCKER}
          isSelected={isLockerFilterSelected}
          onClick={() => onFilterButtonClick(PICKUP_POINT_TYPE.LOCKER)}
        />
      </div>
    </div>
  );
};

Filters.propTypes = {
  selectedTypes: PropTypes.string,
  setSelectedTypes: PropTypes.func,
  selectedAmenities: PropTypes.array,
  setSelectedAmenities: PropTypes.func,
  deliveryAddress: PropTypes.object,
  onSearchPickupLocations: PropTypes.func,
};

export default Filters;
