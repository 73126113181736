import { find, get, isEmpty, set } from "lodash";
import { initialize } from "redux-form";
import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
  createPayloadAction,
} from "@dpdgroupuk/redux-action-creator";
import { TEMPLATE_TYPE } from "@dpdgroupuk/mydpd-enums";
import { getFileAttributes } from "@dpdgroupuk/mydpd-ui";

import {
  importsApi,
  labelsApi,
  localApi,
  manifestApi,
  shipmentApi,
} from "~/apis";
import * as S from "~/constants/strings";
import { EXPORT_MANIFEST_DUMP, PING_TIMEOUT } from "~/constants/exportActivity";
import {
  END_DAY_FORM,
  ShipmentEntity,
  TOOLS_FIELDS,
  TOOLS_FORM,
} from "~/constants/forms";
import {
  AddressBookSelectors,
  ProfilesSelectors,
  TemplateActions,
  TemplateSelectors,
  UmsSelectors,
} from "~/redux";
import { ModalSelectors } from "~/redux/modals";
import { ManifestActions } from "~/features";
import { getValue } from "~/utils/object";
import { CustomError, getErrorMessage } from "~/utils/error";
import { formatISODate } from "~/utils/date";
import { GroupDispatchModels } from "~/pages/Tools/pages/GroupDispatch/models";
import { ToolsSelectors } from "~/pages/Tools/redux/index";
import { BulkReverseModels } from "~/pages/Tools/pages/BulkReverse/models";
import { getLabelQuery } from "~/pages/Tools/models";
import { ImportActivityActions } from "~/pages/ImportActivity/redux";

export const ActionTypes = createActionTypes("TOOLS", {
  GET_START_LABEL: createAsyncActionTypes("GET_START_LABEL"),
  GET_END_LABEL: createAsyncActionTypes("GET_END_LABEL"),
  GET_LABELS_BY_JOB_ID: createAsyncActionTypes("GET_LABELS_BY_JOB_ID"),
  GET_RESULTS_BY_JOB_ID: createAsyncActionTypes("GET_RESULTS_BY_JOB_ID"),
  CREATE_JOB_ID: createAsyncActionTypes("CREATE_JOB_ID"),
  RUN_JOB_ID: createAsyncActionTypes("RUN_JOB_ID"),
  RUN_END_OF_DAY: createAsyncActionTypes("RUN_END_OF_DAY"),
  END_OF_DAY_EXPORT_TO_FILE: createAsyncActionTypes(
    "END_OF_DAY_EXPORT_TO_FILE"
  ),
  SET_PROGRESS_MODAL: "SET_PROGRESS_MODAL",
  RESET_PROGRESS_MODAL: "RESET_PROGRESS_MODAL",
  SET_PRINTING_PROGRESS: "SET_PRINTING_PROGRESS",
  RESET_PRINTING_PROGRESS: "RESET_PRINTING_PROGRESS",
  GET_GENERATED_MANIFEST: "GET_GENERATED_MANIFEST",
  DELETE_UNPRINTED_SHIPMENTS: createAsyncActionTypes(
    "DELETE_UNPRINTED_SHIPMENTS"
  ),
  CREATE_SHIPMENT_EOD: createAsyncActionTypes("CREATE_SHIPMENT_EOD"),
});

export const createJobId = createAsyncAction(
  (isGroupsField, options) => (dispatch, getState) => {
    const formValues = ToolsSelectors.getValues(getState());
    const fileData = isGroupsField
      ? {
          fileName: "GROUPDISPATCH.txt",
          fileImportMode: S.GROUP_DISPATCH_MODE,
        }
      : {
          fileName: "BULKREVERSE.txt",
          fileImportMode: S.BULK_REVERSE_MODE,
        };

    return importsApi.createImportJobId({
      body: {
        ...fileData,
        profileCode: formValues[TOOLS_FIELDS.PROFILE_CODE],
      },
      options,
    });
  },
  ActionTypes.CREATE_JOB_ID
);
export const runJobId = createAsyncAction(
  (jobId, address, isGroupsField, options) => (dispatch, getState) => {
    const state = getState();
    const formValues = ToolsSelectors.getValues(state);
    const returnAddresses = ToolsSelectors.getReturnAddressesKeyValue(state);
    const labelData = getLabelQuery(
      formValues,
      address,
      isGroupsField,
      returnAddresses
    );

    return importsApi.runImportJobId(jobId, labelData, options);
  },
  ActionTypes.RUN_JOB_ID
);
export const getResultByJobId = createAsyncAction(
  (jobId, isGroupsField, options) =>
    importsApi.getImportResultByJobId(
      jobId,
      {
        fileImportMode: isGroupsField
          ? S.GROUP_DISPATCH_MODE
          : S.BULK_REVERSE_MODE,
      },
      options
    ),
  ActionTypes.GET_RESULTS_BY_JOB_ID
);
export const fetchStartLabel = createAsyncAction(
  (query, isGroupsField, options) =>
    isGroupsField
      ? labelsApi.getGroupStartLabel(query, options)
      : labelsApi.getBulkStartLabel(query, options),
  ActionTypes.GET_START_LABEL
);
export const fetchEndLabel = createAsyncAction(
  (query, isGroupsField, options) =>
    isGroupsField
      ? labelsApi.getGroupEndLabel(query, options)
      : labelsApi.getBulkEndLabel(query, options),
  ActionTypes.GET_END_LABEL
);
export const fetchLabelsByJobId = createAsyncAction(
  importsApi.getImportLabelsByJobId,
  ActionTypes.GET_LABELS_BY_JOB_ID
);

export const setPrintingProgress = progress =>
  createPayloadAction(ActionTypes.SET_PRINTING_PROGRESS, progress);

export const resetPrintingProgress = () =>
  createPayloadAction(ActionTypes.RESET_PRINTING_PROGRESS);

export const setModalProgress = progress =>
  createPayloadAction(ActionTypes.SET_PROGRESS_MODAL, progress);

export const resetModalProgress = () =>
  createPayloadAction(ActionTypes.RESET_PROGRESS_MODAL);

export const initializeGroupDispatch = () => (dispatch, getState) => {
  const state = getState();
  const profiles = ProfilesSelectors.getProfiles(state);
  const preferences = UmsSelectors.getPreferences(state);

  dispatch(
    initialize(
      TOOLS_FORM,
      GroupDispatchModels.getInitialValues(profiles[0], preferences)
    )
  );
};
export const initializeBulkReverse = () => (dispatch, getState) => {
  const state = getState();
  const profiles = ProfilesSelectors.getProfiles(state);
  const preferences = UmsSelectors.getPreferences(state);
  const addressBooks = AddressBookSelectors.getAddressBooks(state);
  const defaultAddressBook = find(addressBooks?.results, "isDefault");
  const selectedAddressBook = !isEmpty(defaultAddressBook)
    ? defaultAddressBook
    : getValue(addressBooks, "results[0]");

  dispatch(
    initialize(
      TOOLS_FORM,
      BulkReverseModels.getInitialValues(
        profiles[0],
        preferences,
        selectedAddressBook
      )
    )
  );
};

export const deleteUnprintedShipments = createAsyncAction(
  shipmentApi.deleteUnprintedShipments,
  ActionTypes.DELETE_UNPRINTED_SHIPMENTS
);

export const fetchGeneratedManifest = createAsyncAction(
  manifestApi.getGeneratedManifest,
  ActionTypes.GET_GENERATED_MANIFEST
);

export const createShipmentEOD = createAsyncAction(
  shipmentApi.postShipmentEod,
  ActionTypes.CREATE_SHIPMENT_EOD
);

const LINE_END = "\n";

export const runEodOfDay = createAsyncAction(
  () => async (dispatch, getState) => {
    const formValues = ModalSelectors.getModalFormValues(
      getState(),
      END_DAY_FORM
    );
    const shipmentDate = formValues[[ShipmentEntity.SHIPMENT_DATE]];

    dispatch(
      setModalProgress({
        progress: 10,
        title: S.EOD_IS_RUNNING,
      })
    );

    await dispatch(
      createShipmentEOD({
        shipmentDate: formatISODate(shipmentDate),
      })
    );

    dispatch(
      setModalProgress({
        progress: 70,
        title: S.MANIFESTING_SHIPMENTS,
      })
    );

    const manifest = await dispatch(
      ManifestActions.createManifest({
        shipmentDate: formatISODate(shipmentDate),
      })
    );

    if (!manifest) {
      dispatch(resetModalProgress());
      return;
    }

    await dispatch(TemplateActions.fetchCacheableShipmentExportTemplate());

    dispatch(
      setModalProgress({
        progress: 100,
        title: S.GETTING_SHIPMENTS_FOR_EXPORT,
      })
    );

    const { printString } = await dispatch(
      ManifestActions.generateManifestLabelById(manifest.manifestId)
    );

    if (!printString) {
      dispatch(resetModalProgress());
      return;
    }

    const manifestResult = {
      label: printString,
    };

    const appAccess = await hasAccessToLocalApp();

    if (appAccess) {
      const { csvString } = await dispatch(
        ImportActivityActions.fetchGeneratedManifest({
          manifestId: manifest.manifestId,
        })
      ).catch(error => {
        const message = getErrorMessage(error);

        if (message.includes(S.FAILED_TO_GET_THE_EXPORT_FILE_TEMPLATE)) {
          throw new CustomError(
            S.PROCESS_FINISHED_WAS_NOT_GENERATED_PLEASE_SELECT_EXPORT_FILE_BEHAVIOR_FOR_EXPORT_TEMPLATE
          );
        }

        if (!get(error, "errors[0].message")) {
          set(error, "errors[0].message", S.UNABLE_TO_RETRIEVE_EXPORT_DATA);
        }

        throw new CustomError(error);
      });

      await dispatch(exportShipmentsManifest(csvString));

      const template = TemplateSelectors.getTemplateByType(
        getState(),
        TEMPLATE_TYPE.SHIPMENT_EXPORT
      );

      manifestResult.filePath = template.exportFile;
    }
    dispatch(resetModalProgress());
    return manifestResult;
  },
  ActionTypes.RUN_END_OF_DAY
);

const hasAccessToLocalApp = async () => {
  try {
    const data = await Promise.race([
      localApi.ping(0),
      new Promise(resolve => setTimeout(resolve, PING_TIMEOUT)),
    ]);

    return !!data;
  } catch {
    return false;
  }
};

const exportManifestToAppend = async ({
  filePath,
  fileName,
  content,
  directoryPath,
}) => {
  try {
    await localApi.fs.stat(filePath);
    await localApi.fs.append(filePath, `${LINE_END}${content}`).catch(error => {
      set(error, "errors[0].message", S.CANNOT_APPEND_EXPORT_FILE);
      throw error;
    });
  } catch (err) {
    if (err.message.includes("Not Found")) {
      return exportManifestWithOverride({ content, directoryPath, fileName });
    }

    throw err;
  }
};

const exportManifestWithOverrideAndBackup = async ({
  directoryPath,
  fileName,
  filePath,
  content,
}) => {
  const files = await localApi.fs.list(directoryPath);
  const currentFilePath = files.find(filePath => filePath.endsWith(fileName));
  const currentFileBackupPath = files.find(filePath =>
    filePath.endsWith(EXPORT_MANIFEST_DUMP)
  );

  if (currentFilePath) {
    if (currentFileBackupPath) {
      try {
        if (!localApi.fs.appendFromFile) {
          throw new CustomError("Method was Not Found");
        }
        await localApi.fs.appendFromFile({
          sourceFilePath: currentFilePath,
          targetFilePath: currentFileBackupPath,
        });
      } catch (err) {
        if (err.message.includes("Not Found")) {
          // handle read and append for older electron app version //
          const fileContent = await localApi.fs.read(currentFilePath);

          if (fileContent) {
            await localApi.fs
              .append(currentFileBackupPath, `${LINE_END}${fileContent}`)
              .catch(error => {
                set(error, "errors[0].message", S.CANNOT_READ_EXPORT_FILE);
                throw error;
              });
          }
        }
      }
    } else {
      const backupFilePath = `${filePath.replace(fileName, "")}${EXPORT_MANIFEST_DUMP}`;

      await localApi.fs.move(currentFilePath, backupFilePath).catch(error => {
        set(error, "errors[0].message", S.CANNOT_MOVE_EXPORT_FILE);
        throw error;
      });
    }
  }

  return localApi.fs.write(directoryPath, fileName, content).catch(error => {
    set(error, "errors[0].message", S.CANNOT_WRITE_EXPORT_FILE);
    throw error;
  });
};

const exportManifestWithOverride = ({ content, directoryPath, fileName }) =>
  localApi.fs.write(directoryPath, fileName, content).catch(error => {
    set(error, "errors[0].message", S.CANNOT_CREATE_EXPORT_FILE);
    throw error;
  });

const exportStrategy = {
  A: exportManifestToAppend,
  OW_BK: exportManifestWithOverrideAndBackup,
  OW: exportManifestWithOverride,
};

export const exportShipmentsManifest = createAsyncAction(
  content => async (_, getState) => {
    const template = TemplateSelectors.getTemplateByType(
      getState(),
      TEMPLATE_TYPE.SHIPMENT_EXPORT
    );
    const { filePath, fileName, directoryPath } = getFileAttributes(
      template.exportFile
    );

    const exportManifest = exportStrategy[template.exportFileBehaviour];

    if (!exportManifest) {
      throw new CustomError(S.PROCESS_FINISHED_EXPORT_FILE_WOS_NOT_GENERATED);
    }

    try {
      await exportManifest({ directoryPath, fileName, filePath, content });
    } catch (err) {
      // eslint-disable-next-line no-restricted-globals
      console.log(err);
      throw new CustomError(S.CANNOT_CREATE_EXPORT_FILE);
    }
  },
  ActionTypes.END_OF_DAY_EXPORT_TO_FILE
);
