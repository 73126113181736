import PropTypes from "prop-types";
import classNames from "classnames";
import { get, toUpper } from "lodash";

import { PICKUP_LOCATION } from "~/constants/strings";

import styles from "./PickupLocationDetails.module.scss";

const PickupDetails = ({ pickupLocation }) => {
  const { organisation, name, street, town, city, postcode } = get(
    pickupLocation,
    "pickupLocation.address",
    {}
  );

  return (
    <div className="mb-3 p-2 border rounded">
      <span className={classNames("mb-0 font-weight-bold", styles.title)}>
        {PICKUP_LOCATION}
      </span>
      {[organisation || name, street, town, city].map(
        (addressLine, index) =>
          addressLine && <div key={index}>{`${toUpper(addressLine)},`}</div>
      )}
      {postcode && <div>{toUpper(postcode)}</div>}
    </div>
  );
};
PickupDetails.propTypes = {
  pickupLocation: PropTypes.object,
};

export default PickupDetails;
