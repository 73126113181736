import moment from "moment";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";
import { compose } from "recompose";
import { Field, reduxForm, reset } from "redux-form";

import { FormControl } from "@dpdgroupuk/mydpd-ui";

import { DEFAULT_DATE_FORMAT } from "~/constants/dateFormats";
import {
  Fields,
  SHIPMENT_REVIEW_PRINT_UNPRINTED_FORM,
  ShipmentEntity,
} from "~/constants/forms";
import * as S from "~/constants/strings";

import Modal from "../../../../components/ShipmentReviewModal";
import styles from "./PrintUnprintedModal.module.scss";
import { connect } from "react-redux";
import { ModalSelectors } from "~/redux/modals";
import withUpdateDate from "~/pages/Shipment/hocs/withUpdateDate";
import Checkbox from "~/components/Checkbox";
import { getValue } from "~/utils/object";
import { useMemo } from "react";
import { UmsSelectors } from "~/redux";

const PrintUnprintedModal = ({
  onSubmit,
  filterDate,
  getDatesRange,
  handleSubmit,
  open,
  handleCancel,
  formValues,
  advancedCustomerConfiguration,
}) => {
  const disableDatePicker = useMemo(
    () => getValue(formValues, Fields.PRINT_ALL, false),
    [getValue(formValues, Fields.PRINT_ALL, false)]
  );

  return (
    <Modal
      open={open}
      onCancel={handleCancel}
      title={S.PRINT_UNPRINTED}
      className={styles.printUnprintedModal}
      saveButton={{ visible: true, text: S.PRINT }}
      onSave={handleSubmit(onSubmit)}
    >
      <Container className="p-0">
        <Row>
          <Col sm={8}>
            <Field
              readOnly
              component={FormControl.DatePicker}
              label={S.SHIPMENT_DATE}
              name={ShipmentEntity.SHIPMENT_DATE}
              helperText={S.SELECT_PRINT_DATE}
              dateFormat={DEFAULT_DATE_FORMAT}
              getDatesRange={getDatesRange}
              filterDate={filterDate}
              required
              disabled={disableDatePicker}
            />
          </Col>
          <Col sm={2}>
            <Field
              component={Checkbox}
              label={S.ALL}
              name={Fields.PRINT_ALL}
              disabled={advancedCustomerConfiguration.extendedHoldPolicy}
            />
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

PrintUnprintedModal.propTypes = {
  handleSubmit: PropTypes.func,
  onSubmit: PropTypes.func,
  filterDate: PropTypes.func,
  getDatesRange: PropTypes.func,
  open: PropTypes.bool,
  onCancel: PropTypes.func,
  handleCancel: PropTypes.func,
  formValues: PropTypes.object,
  advancedCustomerConfiguration: PropTypes.object,
};

export default compose(
  connect(
    state => ({
      formValues: ModalSelectors.getModalFormValues(
        state,
        SHIPMENT_REVIEW_PRINT_UNPRINTED_FORM
      ),
      advancedCustomerConfiguration:
        UmsSelectors.getAdvancedCustomerConfiguration(state),
    }),
    (dispatch, { onCancel }) => ({
      handleCancel: () => {
        onCancel();
        dispatch(reset(SHIPMENT_REVIEW_PRINT_UNPRINTED_FORM));
      },
    })
  ),
  reduxForm({
    form: SHIPMENT_REVIEW_PRINT_UNPRINTED_FORM,
    initialValues: {
      [ShipmentEntity.SHIPMENT_DATE]: moment().format(DEFAULT_DATE_FORMAT),
      [Fields.PRINT_ALL]: false,
    },
    destroyOnUnmount: false,
    enableReinitialize: true,
    onSubmitSuccess: (_, dispatch) =>
      dispatch(reset(SHIPMENT_REVIEW_PRINT_UNPRINTED_FORM)),
  }),
  withUpdateDate({
    formName: SHIPMENT_REVIEW_PRINT_UNPRINTED_FORM,
    dateFormat: DEFAULT_DATE_FORMAT,
    fieldPath: ShipmentEntity.SHIPMENT_DATE,
  })
)(PrintUnprintedModal);
