import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import { Field } from "redux-form";

import CommodityCodeFinder from "@dpdgroupuk/mydpd-commodity-finder";
import { FormControl } from "@dpdgroupuk/mydpd-ui";

import { PackageContentModels } from "~/components/PackageContent/models";
import SearchProduct from "~/components/SearchProduct";
import { PRODUCT_BOOK_SEARCH_FORM, ProductEntity } from "~/constants/forms";
import * as S from "~/constants/strings";
import { Normalizers } from "~/models";
import { formatMessage } from "~/utils/string";

import Autocomplete from "../Autocomplete";
import CountriesAutocomplete from "../Autocomplete/CountriesAutocomplete";
import styles from "./PackageContent.module.scss";

const NewEditProduct = ({
  selectedProduct,
  onFieldEntry,
  requiredFields,
  hiddenFields,
  countries,
  onSearch,
  onSelectionChange,
  onProductBookClear,
  isAdditionalCommCodeCheckRequired,
  currencyLabel,
  onCountryChange,
  editProductSelectedCountry,
  onProductBookSelectionChange,
  onCommoditySearch,
  onCommodityCodeSearch,
  change,
  disabledFields,
  onCommodityChange,
  getSearchQuery,
  shouldSearchCommodityCode,
}) => (
  <>
    <SearchProduct
      disabled={!selectedProduct || disabledFields.packageContent}
      onFieldEntry={onFieldEntry}
      form={PRODUCT_BOOK_SEARCH_FORM}
      onClear={onProductBookClear}
      onSelectionChange={values => {
        onProductBookSelectionChange(values);
      }}
    />
    <Row>
      <Col>
        <Field
          component={FormControl.Input}
          label={S.DETAILED_PRODUCT_DESCRIPTION}
          name={ProductEntity.PRODUCT_DESCRIPTION}
          helperText={S.DETAILED_PRODUCT_DESCRIPTION_EXAMPLE}
          onBlur={onFieldEntry}
          disabled={!selectedProduct || disabledFields.packageContent}
          required={requiredFields[ProductEntity.PRODUCT_DESCRIPTION]}
          maxLength={200}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <Field
          component={FormControl.Input}
          label={S.PRODUCT_QUANTITY}
          name={ProductEntity.PRODUCT_QUANTITY}
          helperText={S.TOTAL_PRODUCT_QUANTITY_ITEMS_PER_PARCEL}
          onBlur={onFieldEntry}
          disabled={!selectedProduct || disabledFields.packageContent}
          normalize={Normalizers.integerNormalize}
          required={requiredFields[ProductEntity.PRODUCT_QUANTITY]}
          maxLength={5}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <Field
          component={FormControl.Input}
          label={formatMessage(S.PRODUCT_UNIT_VALUE_$, currencyLabel)}
          name={ProductEntity.UNIT_VALUE}
          helperText={S.INDIVIDUAL_VALUE_FOR_EACH_PRODUCT_IN_PARCEL}
          onBlur={onFieldEntry}
          disabled={!selectedProduct || disabledFields.packageContent}
          normalize={Normalizers.floatNormalize}
          required={requiredFields[ProductEntity.UNIT_VALUE]}
          maxLength={8}
        />
      </Col>
    </Row>
    <Row>
      <Col>
        <Field
          component={FormControl.Input}
          label={S.PRODUCT_UNIT_WEIGHT}
          name={ProductEntity.UNIT_WEIGHT}
          helperText={S.MAXIMUM_TOTAL_WEIGHT_PER_PACKAGE}
          onBlur={onFieldEntry}
          disabled={!selectedProduct || disabledFields.packageContent}
          normalize={Normalizers.floatNormalize}
          required={requiredFields[ProductEntity.UNIT_WEIGHT]}
          maxLength={8}
        />
      </Col>
    </Row>
    {!hiddenFields[ProductEntity.COMMODITY_CODE] && (
      <Row>
        <Col>
          <div className="d-flex align-items-center">
            <Field
              component={Autocomplete}
              label={S.COMMODITY_CODE}
              id={ProductEntity.COMMODITY_CODE}
              name={ProductEntity.COMMODITY_CODE}
              helperText={S.CLARIFY_YOUR_GOODS}
              disabled={!selectedProduct || disabledFields.packageContent}
              required={requiredFields[ProductEntity.COMMODITY_CODE]}
              key={requiredFields[ProductEntity.COMMODITY_CODE]}
              minLength={3}
              maxLength={10}
              onSearch={onSearch}
              labelKey="commodityCode"
              optionLabelMapper={PackageContentModels.getCommodityDescription}
              onSelectionChange={onSelectionChange}
              withAutocomplete={isAdditionalCommCodeCheckRequired}
              type="search"
              getSearchQuery={getSearchQuery}
              shouldSearch={shouldSearchCommodityCode}
              pageSize={25}
              onChange={onCommodityChange}
            />
            <CommodityCodeFinder
              buttonClassName={styles.findCommodityButton}
              disabled={!selectedProduct || disabledFields.packageContent}
              onSearch={onCommoditySearch}
              onCommodityCodeSearch={onCommodityCodeSearch}
              onSubmit={value => change(ProductEntity.COMMODITY_CODE, value)}
            />
          </div>
        </Col>
      </Row>
    )}
    {!hiddenFields[ProductEntity.COUNTRY_OF_ORIGIN] && (
      <Row>
        <Col>
          <Field
            component={CountriesAutocomplete}
            id={ProductEntity.COUNTRY_OF_ORIGIN}
            name={ProductEntity.COUNTRY_OF_ORIGIN}
            label={S.COUNTRY_OF_ORIGIN}
            helperText={S.ENTER_OR_SELECT_DESTINATION_COUNTRY}
            onCountryChange={onCountryChange}
            countries={countries}
            disabled={!selectedProduct || disabledFields.packageContent}
            required={requiredFields[ProductEntity.COUNTRY_OF_ORIGIN]}
            selectedCountry={editProductSelectedCountry}
          />
        </Col>
      </Row>
    )}
    {!hiddenFields[ProductEntity.PRODUCT_CODE] && (
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={S.PRODUCT_CODE}
            name={ProductEntity.PRODUCT_CODE}
            helperText={S.ENTER_PRODUCT_CODE_IDENTIFIER}
            onBlur={onFieldEntry}
            disabled={!selectedProduct || disabledFields.packageContent}
            required={requiredFields[ProductEntity.PRODUCT_CODE]}
            maxLength={255}
          />
        </Col>
      </Row>
    )}
    {!hiddenFields[ProductEntity.PRODUCT_TYPE] && (
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={S.PRODUCT_TYPE}
            name={ProductEntity.PRODUCT_TYPE}
            helperText={S.PRODUCT_TYPE_EXAMPLE}
            onBlur={onFieldEntry}
            disabled={!selectedProduct || disabledFields.packageContent}
            required={requiredFields[ProductEntity.PRODUCT_TYPE]}
            maxLength={40}
          />
        </Col>
      </Row>
    )}
    {!hiddenFields[ProductEntity.PRODUCT_URL] && (
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={S.PRODUCT_URL}
            name={ProductEntity.PRODUCT_URL}
            helperText={S.ENTER_PRODUCT_URL}
            onBlur={onFieldEntry}
            disabled={!selectedProduct || disabledFields.packageContent}
            required={requiredFields[ProductEntity.PRODUCT_URL]}
            maxLength={255}
          />
        </Col>
      </Row>
    )}
    {!hiddenFields[ProductEntity.PRODUCT_COMPOSITION] && (
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={S.PRODUCT_COMPOSITION}
            name={ProductEntity.PRODUCT_COMPOSITION}
            helperText={S.PRODUCT_COMPOSITION_EXAMPLE}
            onBlur={onFieldEntry}
            disabled={!selectedProduct || disabledFields.packageContent}
            required={requiredFields[ProductEntity.PRODUCT_COMPOSITION]}
            maxLength={200}
          />
        </Col>
      </Row>
    )}
  </>
);

NewEditProduct.propTypes = {
  selectedProduct: PropTypes.object,
  onFieldEntry: PropTypes.func,
  requiredFields: PropTypes.object,
  disabledFields: PropTypes.object,
  countries: PropTypes.array,
  onSearch: PropTypes.func,
  onCommoditySearch: PropTypes.func,
  onCommodityCodeSearch: PropTypes.func,
  onCommodityChange: PropTypes.func,
  onSelectionChange: PropTypes.func,
  onProductBookClear: PropTypes.func,
  getSearchQuery: PropTypes.func,
  shouldSearchCommodityCode: PropTypes.func,
  isAdditionalCommCodeCheckRequired: PropTypes.bool,
  currencyLabel: PropTypes.string,
  onCountryChange: PropTypes.func,
  editProductSelectedCountry: PropTypes.object,
  hiddenFields: PropTypes.object,
  onProductBookSelectionChange: PropTypes.func,
};

export default NewEditProduct;
