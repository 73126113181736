import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import omit from "lodash/omit";
import {
  arrayPush,
  arrayRemove,
  arraySplice,
  autofill,
  change,
  destroy,
  reset,
  resetSection,
  SubmissionError,
  touch,
  untouch,
} from "redux-form";

import {
  createActionTypes,
  createAsyncAction,
  createAsyncActionTypes,
} from "@dpdgroupuk/redux-action-creator";
import { createPayloadAction } from "@dpdgroupuk/redux-action-creator/lib/actionTypes";

import { addressBookApi, labelsApi, shipmentApi } from "~/apis";
import {
  ADDRESS,
  CONTACT_DETAILS,
  DELIVERY_CONTACT,
  DELIVERY_DETAILS_SEARCH_FORM,
  EDIT_IMPORTER_FORM,
  EDIT_PRODUCT_FORM,
  EXPORTER_DETAILS,
  ExporterDetailsEntity,
  Fields,
  FilterFields,
  IMPORTER_DETAILS,
  ImporterDetailsEntity,
  INBOUND_CONSIGNMENT,
  INVOICE,
  InvoiceEntity,
  OUTBOUND_CONSIGNMENT,
  PRODUCT_DESCRIPTIONS_FORM,
  ProductDescriptionsEntity,
  ProductEntity,
  RETURN_DETAILS_SEARCH_FORM,
  ShipmentEntity,
} from "~/constants/forms";
import {
  AddressModels,
  CustomsModels,
  InvoiceModels,
  LiabilityModels,
  NewShipmentModels,
  ParcelModels,
  ServiceModels,
  SettingsModels,
  ShipmentModels,
} from "~/models";
import {
  AddressBookActions,
  ProfilesSelectors,
  ReferenceActions,
  ReferenceSelectors,
  UmsSelectors,
} from "~/redux";
import {
  flattenEntityRoutes,
  getDeepKeys,
  getValue,
  omitNilValues,
  toUppercaseValues,
} from "~/utils/object";
import { initializeForm } from "~/utils/reduxForm";
import { getProductDescription } from "~/components/ProductDescriptionsContent/selectors";
import { cloneDeep, isUndefined, pick } from "lodash";
import { DEFAULT_ZERO_VALUE } from "~/constants/strings";
import { ShipmentSelectors } from "~/pages/Shipment/redux/index";
import omitBy from "lodash/omitBy";
import isNull from "lodash/isNull";
import { ShipmentReviewSelectors } from "~/pages/ShipmentReview/redux";
import { getProductOmittedFields } from "~/models/parcel/parcel";

export const ActionTypes = createActionTypes("SHIPMENT", {
  CREATE_SHIPMENT: createAsyncActionTypes("CREATE_SHIPMENT"),
  UPDATE_SHIPMENT: createAsyncActionTypes("UPDATE_SHIPMENT"),
  FETCH_BY_ID: createAsyncActionTypes("FETCH_BY_ID"),
  GET_LABEL: createAsyncActionTypes("GET_LABEL"),
  GET_LABELS_BY_SHIPMENT_IDS: createAsyncActionTypes(
    "GET_LABELS_BY_SHIPMENT_IDS"
  ),
  GET_INVOICE_LABEL: createAsyncActionTypes("GET_INVOICE_LABEL"),
  GET_UNPRINTED_LABELS: createAsyncActionTypes("GET_UNPRINTED_LABELS"),
  GENERATE_UNIQUE_REFERENCE_1: createAsyncActionTypes(
    "GENERATE_UNIQUE_REFERENCE_1"
  ),
  VALIDATE_UNIQUE_REFERENCE_1: createAsyncActionTypes(
    "VALIDATE_UNIQUE_REFERENCE_1"
  ),
  SET_SELECTED_ADDRESS_BOOK: "SET_SELECTED_ADDRESS_BOOK",
  SET_SELECTED_RETURN_ADDRESS_BOOK: "SET_SELECTED_RETURN_ADDRESS_BOOK",
  CREATE_UPDATE: createAsyncActionTypes("CREATE_UPDATE"),
  CLEAR_SELECTED_RETURN_ADDRESSBOOK: "CLEAR_SELECTED_RETURN_ADDRESSBOOK",
  CLEAR_SELECTED_ADDRESSBOOK: "CLEAR_SELECTED_ADDRESSBOOK",
  CLEAR_PAGE: "CLEAR_PAGE",
});

export const clearContactDetails = formName => dispatch =>
  dispatch(resetSection(formName, DELIVERY_CONTACT));

export const resetInboundConsignment = formName => dispatch =>
  dispatch(resetSection(formName, INBOUND_CONSIGNMENT));

export const clearReturnItemDescription = formName => dispatch =>
  dispatch(
    autofill(formName, ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION)
  );

export const updateReturnShortName = shortName => dispatch =>
  dispatch(
    autofill(
      RETURN_DETAILS_SEARCH_FORM,
      FilterFields.SEARCH_CRITERIA_VALUE,
      shortName
    )
  );

export const touchShipmentFormFields =
  (formName, fields, formValues) => dispatch => {
    fields.forEach(
      field =>
        isEmpty(get(formValues, field)) && dispatch(touch(formName, field))
    );
  };

export const initializeShippingInformation =
  pageConfig => (dispatch, getState) => {
    const data = ShipmentSelectors.getInitialInvoiceData(
      getState(),
      pageConfig
    );

    return dispatch(initializeForm(pageConfig.formName, data));
  };

export const createShipment = createAsyncAction(
  pageConfig => (dispatch, getState) => {
    const state = getState();
    const query = ShipmentSelectors.getShipmentQuery(state, pageConfig);
    const selectedCountry = ShipmentSelectors.getSelectedCountry(
      state,
      pageConfig
    );
    const isSwapItOrReverseIt =
      ShipmentSelectors.isSwapItOrReverseItShipmentType(state, pageConfig);
    const isDomestic = AddressModels.isCountryDomestic(
      selectedCountry.countryKey
    );

    if (isSwapItOrReverseIt) {
      return shipmentApi
        .createShipmentCollection(query)
        .then(({ data }) => data);
    }

    if (isDomestic) {
      return shipmentApi.createShipmentDomestic(query).then(({ data }) => data);
    }

    return shipmentApi
      .createShipmentInternational(query)
      .then(({ data }) => data);
  },
  ActionTypes.CREATE_SHIPMENT
);

// @see  https://it.dpduk.live/version/customer-shipping/sprint-1.18/diag_fGPa5D6FYFxijSYL.html
export const updateShipment = createAsyncAction(
  (pageConfig, shipmentId, isScanned = false) =>
    (dispatch, getState) => {
      const state = getState();
      const query = omit(
        ShipmentSelectors.getShipmentQuery(state, pageConfig),
        ["outboundConsignment.collectionDetails"]
      );

      // NOTE: passed isScanned it only from Scan page (allow edit ref2/ref3 fields)
      if (isScanned) {
        query.isScanned = isScanned;
      }

      const selectedCountry = ShipmentSelectors.getSelectedCountry(
        state,
        pageConfig
      );
      const isSwapItOrReverseIt =
        ShipmentSelectors.isSwapItOrReverseItShipmentType(state, pageConfig);
      const isDomestic = AddressModels.isCountryDomestic(
        selectedCountry.countryKey
      );

      if (isSwapItOrReverseIt) {
        return shipmentApi
          .updateShipmentCollection(shipmentId, query)
          .then(({ data }) => data);
      }

      if (isDomestic) {
        return shipmentApi
          .updateShipmentDomestic(shipmentId, query)
          .then(({ data }) => data);
      }

      return shipmentApi
        .updateShipmentInternational(shipmentId, query)
        .then(({ data }) => data);
    },
  ActionTypes.UPDATE_SHIPMENT
);

export const fetchLabel = createAsyncAction(
  (shipmentId, query) =>
    shipmentApi
      .getLabel(shipmentId, query)
      .then(({ data: { printString } }) => printString),
  ActionTypes.GET_LABEL
);

export const fetchLabels = createAsyncAction(
  query =>
    labelsApi
      .getLabelsByShipmentIds(query)
      .then(({ data: { printString } }) => printString),
  ActionTypes.GET_LABELS_BY_SHIPMENT_IDS
);

export const fetchInvoiceLabel = createAsyncAction(
  (shipmentId, query) =>
    shipmentApi
      .getInvoiceLabel(shipmentId, query)
      .then(({ data: { printString } }) => printString),
  ActionTypes.GET_INVOICE_LABEL
);

export const fetchUnprintedLabels = createAsyncAction(
  query =>
    labelsApi
      .getUnprintedLabels(query)
      .then(({ data: { printString } }) => printString),
  ActionTypes.GET_UNPRINTED_LABELS
);

export const generateUniqueReference1 = createAsyncAction(
  () =>
    shipmentApi
      .generateUniqueReference1()
      .then(({ data: { reference } }) => reference),
  ActionTypes.GENERATE_UNIQUE_REFERENCE_1
);

export const validateUniqueReference1 = createAsyncAction(
  (customerRef, options) =>
    shipmentApi
      .validateUniqueReference1(customerRef, options)
      .then(({ data }) => data),
  ActionTypes.VALIDATE_UNIQUE_REFERENCE_1
);

export const setSelectedAddressBook = addressBook =>
  createPayloadAction(ActionTypes.SET_SELECTED_ADDRESS_BOOK, addressBook);

export const setSelectedReturnAddressBook = addressBook =>
  createPayloadAction(
    ActionTypes.SET_SELECTED_RETURN_ADDRESS_BOOK,
    addressBook
  );

export const handleSubmitShipment =
  ({ pageConfig, notifier, isEditingProductDescription }) =>
  (dispatch, getState) =>
  onSuccess => {
    const state = getState();

    if (ShipmentSelectors.isEditProductFormActive(state)) {
      throw new SubmissionError({
        [ShipmentEntity.OUTBOUND_CONSIGNMENT.PARCELS]: Fields.PARCELS,
      });
    }

    if (isEditingProductDescription) {
      throw new SubmissionError({
        [ProductDescriptionsEntity.PRODUCT_DESCRIPTION]:
          Fields.DELIVERY_DESCRIPTION,
      });
    }

    const isLoadingOutboundServices =
      ShipmentSelectors.isLoadingOutboundServices(state, pageConfig);
    const isLoadingInboundServices = ShipmentSelectors.isLoadingInboundServices(
      state,
      pageConfig
    );
    const disableButton = ShipmentSelectors.shouldDisablePrintButtons(
      state,
      pageConfig
    );

    if (isLoadingOutboundServices || isLoadingInboundServices) return;

    if (!disableButton) {
      return onSuccess();
    }

    // Test for form errors at the page, display and scroll if any
    const errors = ShipmentSelectors.getShipmentFormSyncErrors(
      state,
      pageConfig
    );

    const flat = getDeepKeys(errors);
    dispatch(touch(pageConfig.formName, ...flat));
    const isErrorVisible = notifier.scrollToError(flat);

    if (isErrorVisible) return;

    // Otherwise, look for hidden errors (Parcel, Invoice) at the page
    const isValidInvoiceSection = ShipmentModels.isValidInvoiceSection(errors);
    const isOnlyInvoiceImporterSectionInvalid =
      ShipmentModels.isOnlyInvoiceImporterSectionInvalid(errors);
    const isParcelsDataValid = ShipmentSelectors.isParcelsDataValid(
      state,
      pageConfig
    );

    if (!isValidInvoiceSection || !isParcelsDataValid) {
      const invalidSection = isOnlyInvoiceImporterSectionInvalid
        ? IMPORTER_DETAILS
        : EXPORTER_DETAILS;
      const invoiceErrorField = `${INVOICE}.${invalidSection}`;

      const error = isParcelsDataValid
        ? { invoiceErrorField }
        : { [ShipmentEntity.OUTBOUND_CONSIGNMENT.PARCELS]: Fields.PARCELS };

      throw new SubmissionError(error);
    } else {
      return onSuccess();
    }
  };

export const handleSubmitAddressbook =
  ({ pageConfig, notifier }) =>
  (dispatch, getState) =>
  onSuccess => {
    const errors = ShipmentSelectors.getAddressBookErrors(
      getState(),
      pageConfig
    );

    if (!isEmpty(errors)) {
      const flat = getDeepKeys(errors);
      dispatch(touch(pageConfig.formName, ...flat));
      notifier.scrollToError(flat);
    } else {
      onSuccess();
    }
  };

export const createOrUpdateAddressBook = createAsyncAction(
  (addressbookId, body, abortController) => async () => {
    const updateObject = {
      addressBookType: body.addressBookType,
    };

    let result;

    if (addressbookId) {
      result = await addressBookApi.updateAddressBook(
        addressbookId,
        updateObject,
        body,
        {
          signal: abortController.signal,
        }
      );
    } else {
      result = await addressBookApi.insertAddressBook(body, {
        signal: abortController.signal,
      });
    }

    const { data } = await addressBookApi.fetchAddressBookById(
      result.data.addressbookId,
      updateObject,
      {
        signal: abortController.signal,
      }
    );

    return data;
  },
  ActionTypes.CREATE_UPDATE
);

export const clearAddressBook = form => ({
  type:
    form === DELIVERY_DETAILS_SEARCH_FORM
      ? ActionTypes.CLEAR_SELECTED_ADDRESSBOOK
      : ActionTypes.CLEAR_SELECTED_RETURN_ADDRESSBOOK,
});

export const clearCustoms = formName => dispatch =>
  dispatch(
    resetSection(
      formName,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.CUSTOMS_VALUE,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.CURRENCY
    )
  );

export const setDefaultCurrency = pageConfig => (dispatch, getState) => {
  const userCurrency = CustomsModels.getDefaultCurrency(
    UmsSelectors.getPreferences(getState()),
    ProfilesSelectors.getActiveProfile(getState(), pageConfig)
  );
  dispatch(
    autofill(
      pageConfig.formName,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.CURRENCY,
      userCurrency
    )
  );
};

// @see: comments from https://geopost.jira.com/browse/CSHIP-6395
export const setDefaultDeliveryDescription =
  (pageConfig, allowedFields) => (dispatch, getState) => {
    const state = getState();
    const isDeliveryDescriptionVisible =
      allowedFields[ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION];
    const userDescription = getValue(
      UmsSelectors.getPreferences(state),
      "shippingDefaults.international.intContentDescription",
      ""
    ).toUpperCase();
    const createShipmentValues = ShipmentSelectors.getShipmentFormValues(
      state,
      pageConfig
    );

    if (
      isDeliveryDescriptionVisible &&
      userDescription &&
      !getValue(
        createShipmentValues,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION,
        ""
      )
    ) {
      dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION,
          userDescription
        )
      );
    }
  };

export const resetShippingInformation = pageConfig => dispatch => {
  dispatch(resetSection(pageConfig.formName, INVOICE));
  dispatch(initializeShippingInformation(pageConfig));
};

export const deleteParcelProduct = (formName, selectedProduct) => dispatch => {
  if (selectedProduct.productIndex !== undefined) {
    dispatch(
      arrayRemove(
        formName,
        `outboundConsignment.parcels[${selectedProduct.packageNumber}].products`,
        selectedProduct.productIndex
      )
    );
  }
};

export const clearShipment = () => ({
  type: ActionTypes.CLEAR_PAGE,
});

export const changeCountry = (pageConfig, path, country) => dispatch => {
  dispatch(autofill(pageConfig.formName, path, country));
};

export const setupDefaultAdditionalDataRequirements =
  pageConfig => (dispatch, getState) => {
    const { destinationTaxId, gstVatPaid } = ShipmentModels.getInitialTaxValues(
      ProfilesSelectors.getActiveProfile(getState(), pageConfig),
      UmsSelectors.getPreferences(getState())
    );

    dispatch(
      change(
        pageConfig.formName,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO,
        destinationTaxId
      )
    );
    destinationTaxId &&
      dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.GST_VAT_PAID,
          gstVatPaid
        )
      );
  };

export const resetAdditionalDataRequirements = pageConfig => dispatch => {
  dispatch(
    change(
      pageConfig.formName,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO,
      undefined
    )
  );
  dispatch(
    change(
      pageConfig.formName,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.GST_VAT_PAID,
      undefined
    )
  );
};

export const resetInvoiceAdditionalDataRequirements =
  pageConfig => dispatch => {
    dispatch(
      change(
        pageConfig.formName,
        ShipmentEntity.INVOICE.EXPORTER_DETAILS.DESTINATION_TAX_ID_REG_NO,
        undefined
      )
    );
    dispatch(
      change(
        pageConfig.formName,
        ShipmentEntity.INVOICE.EXPORTER_DETAILS.GST_VAT_PAID,
        undefined
      )
    );
  };

// @see:https://it.dpduk.live/it/diagram/diag_GCJhVN6GAqAAhVnC.html?id=1633609607280
export const setupAdditionalDataRequirements =
  (isVisibleTaxId, pageConfig) => dispatch =>
    isVisibleTaxId
      ? dispatch(setupDefaultAdditionalDataRequirements(pageConfig))
      : dispatch(resetAdditionalDataRequirements(pageConfig));

export const unmountShipmentPage = () => dispatch => {
  dispatch(clearShipment());
  dispatch(AddressBookActions.clearAddressBook());
  dispatch(ReferenceActions.clearReference());
  dispatch(destroy(DELIVERY_DETAILS_SEARCH_FORM));
  dispatch(destroy(RETURN_DETAILS_SEARCH_FORM));
};

export const getShipmentById = createAsyncAction(
  shipmentId =>
    shipmentApi.getShipmentById(shipmentId).then(({ data }) => data),
  ActionTypes.FETCH_BY_ID
);

export const resetReference1 = pageConfig => (dispatch, getState) => {
  const state = getState();
  dispatch(
    initializeForm(pageConfig.formName, {
      [ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1]:
        ShipmentModels.getDefaultUniqueShippingRef1(
          get(
            ShipmentSelectors.getShipmentFormValues(state, pageConfig),
            ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
              .COUNTRY_CODE
          ),
          ShipmentSelectors.getUniqueSenderRef1(state),
          {
            preferences: UmsSelectors.getPreferences(state),
            shippingSettings: UmsSelectors.getShippingSettings(state),
          }
        ),
    })
  );
};

// manage only manual changing liability, dynamic changing is managed in the handleSaebiFlags
// @see https://it.dpduk.live/it/diagram/diag_VLeqsR6C48kIGApK.html?id=1639737191640
export const onLiabilityChange =
  (liability, pageConfig) => (dispatch, getState) => {
    const state = getState();
    const preferences = UmsSelectors.getPreferences(state);
    const formValues = ShipmentSelectors.getShipmentFormValues(
      state,
      pageConfig
    );
    const countryCode = getValue(
      formValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
      ""
    );

    dispatch(
      change(
        pageConfig.formName,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY_VALUE,
        LiabilityModels.getDefaultLiabilityValue(
          preferences,
          countryCode,
          liability
        )
      )
    );
  };

const onBusinessChangeOld = (pageConfig, value) => (dispatch, getState) => {
  const state = getState();
  const selectedAddressBook = ShipmentSelectors.getSelectedAddressBook(state);

  if (value) {
    dispatch(change(EDIT_IMPORTER_FORM, ImporterDetailsEntity.PID_NUMBER, ""));

    if (!isEmpty(selectedAddressBook?.vatNumber)) {
      dispatch(
        autofill(
          EDIT_IMPORTER_FORM,
          ImporterDetailsEntity.VAT_NUMBER,
          selectedAddressBook.vatNumber
        )
      );
    }
    if (!isEmpty(selectedAddressBook?.eoriNumber)) {
      dispatch(
        autofill(
          EDIT_IMPORTER_FORM,
          ImporterDetailsEntity.EORI_NUMBER,
          selectedAddressBook.eoriNumber
        )
      );
    }
  } else {
    dispatch(change(EDIT_IMPORTER_FORM, ImporterDetailsEntity.EORI_NUMBER, ""));
    dispatch(change(EDIT_IMPORTER_FORM, ImporterDetailsEntity.VAT_NUMBER, ""));

    if (!isEmpty(selectedAddressBook?.pid)) {
      dispatch(
        autofill(
          EDIT_IMPORTER_FORM,
          ImporterDetailsEntity.PID_NUMBER,
          selectedAddressBook.pid
        )
      );
    }
  }
};

const getDefaultFields = pageConfig => (dispatch, getState) => {
  const state = getState();
  const customer = UmsSelectors.getCustomer(state);
  const customerPrefs = UmsSelectors.getCustomerPrefs(state);
  const preferences = UmsSelectors.getPreferences(state);
  const profile = ProfilesSelectors.getActiveProfile(state, pageConfig);
  const selectedService = ReferenceSelectors.getActiveOutboundService(state);
  const userCustomerAddress = UmsSelectors.getCustomerAddress(state);
  const formValues = ShipmentSelectors.getShipmentFormValues(state, pageConfig);
  const countryCode = getValue(
    formValues,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
    ""
  );
  const postcode = getValue(
    formValues,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
    ""
  );
  const selectedCountry = ShipmentSelectors.getSelectedCountry(
    state,
    pageConfig
  );
  const { invoiceDetailsSection, exporterImporterDetails } =
    NewShipmentModels.isShippingInformationVisible(customer, selectedService, {
      generateCustomsData: getValue(
        formValues,
        ShipmentEntity.GENERATE_CUSTOMS_DATA,
        false
      ),
      countryCode,
      postCode: postcode,
    });
  const {
    defaultInvoiceType,
    internationalBillings,
    eoriNumber,
    vatNumber,
    fdaRegistrationNumber,
    destinationTaxId,
    gstVatPaid,
  } = InvoiceModels.getShipmentInfoDefault({
    selectedCountry,
    customerPrefs,
    shipment: formValues,
    selectedService,
    profile,
    preferences,
  });
  const visibleExporterFields =
    ServiceModels.isVisibleExporterTaxRequiredFields(
      selectedService,
      destinationTaxId,
      customer.shippingVersion
    );
  const defaultExporterAddress = AddressModels.getDefaultExporterAddress({
    customer,
    userCustomerAddress,
    profile,
    prefsInvoiceSettings: preferences.prefsInvoiceSettings,
  });
  const defaultExporterDetails = {
    [ADDRESS]: {
      ...pick(defaultExporterAddress, [
        Fields.STREET,
        Fields.LOCALITY,
        Fields.TOWN,
        Fields.COUNTY,
        Fields.POSTCODE,
        Fields.ORGANISATION,
        Fields.COUNTRY_CODE,
      ]),
    },
    [CONTACT_DETAILS]: {
      ...pick(defaultExporterAddress, [Fields.CONTACT_NAME, Fields.TELEPHONE]),
    },
    [ExporterDetailsEntity.EORI_NUMBER]: eoriNumber,
    [ExporterDetailsEntity.VAT_NUMBER]: vatNumber,
    ...(visibleExporterFields.fdaRegistrationNumber && {
      [ExporterDetailsEntity.FDA_NUMBER]: fdaRegistrationNumber,
    }),
    ...(visibleExporterFields.destinationTaxId && {
      [ExporterDetailsEntity.DESTINATION_TAX_ID_REG_NO]: destinationTaxId,
    }),
    ...(visibleExporterFields.gstVatPaid && {
      [ExporterDetailsEntity.GST_VAT_PAID]: gstVatPaid,
    }),
  };
  const updatedInvoice = cloneDeep(getValue(formValues, INVOICE));
  const resetFields = [];

  if (invoiceDetailsSection) {
    if (!getValue(updatedInvoice, InvoiceEntity.INVOICE_TYPE)) {
      updatedInvoice[InvoiceEntity.INVOICE_TYPE] = defaultInvoiceType;
    }

    if (!getValue(updatedInvoice, InvoiceEntity.INTERNATIONAL_BILLING_TERMS)) {
      updatedInvoice[InvoiceEntity.INTERNATIONAL_BILLING_TERMS] =
        InvoiceModels.getDefaultInternationalBilling(internationalBillings);
    }

    if (InvoiceModels.isVisibleShippingCost(selectedService, customer)) {
      if (!getValue(updatedInvoice, InvoiceEntity.SHIPPING_COST)) {
        updatedInvoice[InvoiceEntity.SHIPPING_COST] = DEFAULT_ZERO_VALUE;
      }
    } else {
      resetFields.push(InvoiceEntity.SHIPPING_COST);
    }

    if (!InvoiceModels.isVisibleExportReason(selectedService, customer)) {
      resetFields.push(InvoiceEntity.EXPORT_REASON);
    }
  } else {
    resetFields.push(
      ShipmentEntity.INVOICE.INVOICE_TYPE,
      ShipmentEntity.INVOICE.INTERNATIONAL_BILLING_TERMS,
      ShipmentEntity.INVOICE.SHIPPING_COST,
      ShipmentEntity.INVOICE.EXPORT_REASON,
      ShipmentEntity.INVOICE.INVOICE_REFERENCE
    );
  }

  if (exporterImporterDetails) {
    if (
      isEmpty(updatedInvoice.importerDetails?.address) ||
      isEmpty(updatedInvoice.importerDetails?.contactDetails)
    ) {
      updatedInvoice.importerDetails = {
        ...updatedInvoice.importerDetails,
        ...InvoiceModels.addImporterAddressDetails(formValues),
      };
    }

    if (isEmpty(updatedInvoice.exporterDetails?.address)) {
      updatedInvoice.exporterDetails = defaultExporterDetails;
    }
  } else {
    resetFields.push(
      "invoice.importerDetails.address",
      "invoice.importerDetails.contactDetails",
      ShipmentEntity.INVOICE.IMPORTER_DETAILS.VAT_NUMBER,
      ShipmentEntity.INVOICE.IMPORTER_DETAILS.EORI_NUMBER,
      ShipmentEntity.INVOICE.IMPORTER_DETAILS.PID_NUMBER,
      ShipmentEntity.INVOICE.IMPORTER_DETAILS.UKIMS_NUMBER,
      "invoice.exporterDetails"
    );
  }

  return {
    fields: { resetFields, updatedInvoice },
    invoiceDetailsSection,
    exporterImporterDetails,
  };
};

// NOTE: pass service as argument from handleNetworkChange
export const updateParcels = (pageConfig, service) => (dispatch, getState) => {
  const createShipmentValues = ShipmentSelectors.getShipmentFormValues(
    getState(),
    pageConfig
  );
  const parcels = getValue(
    createShipmentValues,
    "outboundConsignment.parcels",
    []
  );

  if (
    ServiceModels.isFlagDescription(service, "prodRequired") &&
    parcels.length
  ) {
    const updatedParcels = ParcelModels.getProductDescriptions(parcels);
    // NOTE: only first element was changed
    dispatch(
      arraySplice(
        pageConfig.formName,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.PARCELS,
        0,
        1,
        updatedParcels[0]
      )
    );
  }
};

const onBusinessChangeNew =
  (pageConfig, isBusiness, atRisk) => (dispatch, getState) => {
    const state = getState();
    const customer = UmsSelectors.getCustomer(state);
    const selectedAddressBook = ShipmentSelectors.getSelectedAddressBook(state);
    const selectedService = ReferenceSelectors.getActiveOutboundService(state);
    const formValues = ShipmentSelectors.getShipmentFormValues(
      state,
      pageConfig
    );
    const isNiShipment = AddressModels.isNiShipment(
      getValue(
        formValues,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
          .COUNTRY_CODE,
        ""
      ),
      getValue(
        formValues,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
        ""
      )
    );
    const { ukimsNumber: defaultExporterUkims } =
      ShipmentModels.getInitialTaxValues(
        ProfilesSelectors.getActiveProfile(state, pageConfig),
        UmsSelectors.getPreferences(state)
      );
    const isVisibleVatNumber = InvoiceModels.isVisibleVatNumber(
      selectedService,
      customer,
      isBusiness
    );
    const isVisiblePidNumber = InvoiceModels.isVisiblePidNumber(
      selectedService,
      customer,
      isBusiness
    );
    const {
      fields: { resetFields, updatedInvoice },
      exporterImporterDetails,
    } = dispatch(getDefaultFields(pageConfig));

    if (isBusiness) {
      if (isNiShipment) {
        updatedInvoice.importerDetails.atRisk = atRisk;
      }

      if (exporterImporterDetails) {
        resetFields.push(ShipmentEntity.INVOICE.IMPORTER_DETAILS.PID_NUMBER);

        if (isVisibleVatNumber) {
          if (
            !isEmpty(selectedAddressBook?.vatNumber) &&
            isEmpty(
              getValue(
                formValues,
                ShipmentEntity.INVOICE.IMPORTER_DETAILS.VAT_NUMBER,
                ""
              )
            )
          ) {
            updatedInvoice.importerDetails.vatNumber =
              selectedAddressBook.vatNumber;
          }
        } else {
          resetFields.push(ShipmentEntity.INVOICE.IMPORTER_DETAILS.VAT_NUMBER);
        }

        if (!isEmpty(selectedAddressBook?.eoriNumber)) {
          updatedInvoice.importerDetails.eoriNumber =
            selectedAddressBook.eoriNumber;
        }

        if (
          InvoiceModels.isVisibleUkims(
            {
              isBusiness,
              atRisk,
              countryCode: getValue(
                formValues,
                ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
                  .COUNTRY_CODE
              ),
              postcode: getValue(
                formValues,
                ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
                  .POSTCODE
              ),
            },
            customer.shippingVersion,
            selectedService
          )
        ) {
          updatedInvoice.exporterDetails.ukimsNumber = defaultExporterUkims;

          if (!isEmpty(selectedAddressBook?.ukimsNumber)) {
            updatedInvoice.importerDetails.ukimsNumber =
              selectedAddressBook.ukimsNumber;
          }
        }
      }
    } else {
      if (isNiShipment) {
        updatedInvoice.importerDetails.atRisk = false;
      }

      if (exporterImporterDetails) {
        resetFields.push(
          ShipmentEntity.INVOICE.IMPORTER_DETAILS.EORI_NUMBER,
          ShipmentEntity.INVOICE.IMPORTER_DETAILS.VAT_NUMBER,
          ShipmentEntity.INVOICE.EXPORTER_DETAILS.UKIMS_NUMBER,
          ShipmentEntity.INVOICE.IMPORTER_DETAILS.UKIMS_NUMBER
        );

        if (isVisiblePidNumber && !isEmpty(selectedAddressBook?.pid)) {
          updatedInvoice.importerDetails.pidNumber = selectedAddressBook.pid;
        }
      }
    }

    dispatch(updateParcels(pageConfig, selectedService));
    dispatch(
      initializeForm(pageConfig.formName, {
        [INVOICE]: toUppercaseValues(updatedInvoice),
      })
    );

    if (resetFields.length) {
      dispatch(resetSection(pageConfig.formName, ...resetFields));
    }
  };

export const onImporterTypeChange =
  (pageConfig, isBusiness, atRisk) => (dispatch, getState) => {
    const customer = UmsSelectors.getCustomer(getState());
    SettingsModels.isNewVersion(customer.shippingVersion)
      ? dispatch(onBusinessChangeNew(pageConfig, isBusiness, atRisk))
      : dispatch(onBusinessChangeOld(pageConfig, isBusiness));
  };

export const onAtRiskChange = (pageConfig, atRisk) => (dispatch, getState) => {
  const state = getState();
  const customer = UmsSelectors.getCustomer(state);
  const selectedAddressBook = ShipmentSelectors.getSelectedAddressBook(state);
  const selectedService = ReferenceSelectors.getActiveOutboundService(state);
  const { ukimsNumber: defaultExporterUkims } =
    ShipmentModels.getInitialTaxValues(
      ProfilesSelectors.getActiveProfile(state, pageConfig),
      UmsSelectors.getPreferences(state)
    );
  const formValues = ShipmentSelectors.getShipmentFormValues(state, pageConfig);
  const isBusiness = getValue(
    formValues,
    ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS,
    false
  );
  const {
    fields: { resetFields, updatedInvoice },
    exporterImporterDetails,
  } = dispatch(getDefaultFields(pageConfig));
  const isVisibleVatNumber = InvoiceModels.isVisibleVatNumber(
    selectedService,
    customer,
    isBusiness
  );
  const isVisiblePidNumber = InvoiceModels.isVisiblePidNumber(
    selectedService,
    customer,
    isBusiness
  );

  if (atRisk) {
    if (exporterImporterDetails) {
      resetFields.push(
        ShipmentEntity.INVOICE.EXPORTER_DETAILS.UKIMS_NUMBER,
        ShipmentEntity.INVOICE.IMPORTER_DETAILS.UKIMS_NUMBER
      );
    }
  } else {
    if (
      exporterImporterDetails &&
      InvoiceModels.isVisibleUkims(
        {
          isBusiness,
          atRisk,
          countryCode: getValue(
            formValues,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
              .COUNTRY_CODE
          ),
          postcode: getValue(
            formValues,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
              .POSTCODE
          ),
        },
        customer.shippingVersion,
        selectedService
      )
    ) {
      updatedInvoice.exporterDetails.ukimsNumber = defaultExporterUkims;
      updatedInvoice.importerDetails.ukimsNumber = getValue(
        selectedAddressBook,
        Fields.UKIMS_NUMBER,
        ""
      );
    }
  }

  if (isVisibleVatNumber) {
    if (
      !isEmpty(selectedAddressBook?.vatNumber) &&
      isEmpty(
        getValue(
          formValues,
          ShipmentEntity.INVOICE.IMPORTER_DETAILS.VAT_NUMBER,
          ""
        )
      )
    ) {
      updatedInvoice.importerDetails.vatNumber = selectedAddressBook.vatNumber;
    }
  } else {
    resetFields.push(ShipmentEntity.INVOICE.IMPORTER_DETAILS.VAT_NUMBER);
  }

  if (isVisiblePidNumber) {
    if (
      !isEmpty(selectedAddressBook?.pid) &&
      isEmpty(
        getValue(
          formValues,
          ShipmentEntity.INVOICE.IMPORTER_DETAILS.PID_NUMBER,
          ""
        )
      )
    ) {
      updatedInvoice.importerDetails.pidNumber = selectedAddressBook.pid;
    }
  } else {
    resetFields.push(ShipmentEntity.INVOICE.IMPORTER_DETAILS.PID_NUMBER);
  }

  dispatch(
    initializeForm(pageConfig.formName, {
      [INVOICE]: toUppercaseValues(updatedInvoice),
    })
  );

  if (resetFields.length) {
    dispatch(resetSection(pageConfig.formName, ...resetFields));
  }
};

export const setProductDescription = pageConfig => (dispatch, getState) => {
  const state = getState();

  const currentProductDescription = getProductDescription(
    PRODUCT_DESCRIPTIONS_FORM
  )(state);

  if (isEmpty(currentProductDescription)) {
    return;
  }

  dispatch(
    arrayPush(
      pageConfig.formName,
      `${ShipmentEntity.OUTBOUND_CONSIGNMENT.PARCELS}[0].products`,
      currentProductDescription
    )
  );
  dispatch(
    change(
      PRODUCT_DESCRIPTIONS_FORM,
      ProductDescriptionsEntity.PRODUCT_DESCRIPTION,
      ""
    )
  );
  dispatch(
    untouch(
      PRODUCT_DESCRIPTIONS_FORM,
      ProductDescriptionsEntity.PRODUCT_DESCRIPTION
    )
  );
};

export const removeProductDescription =
  (pageConfig, productDescriptionIndex) => dispatch => {
    dispatch(
      arrayRemove(
        pageConfig.formName,
        `${ShipmentEntity.OUTBOUND_CONSIGNMENT.PARCELS}[0].products`,
        productDescriptionIndex
      )
    );
    dispatch(
      untouch(
        PRODUCT_DESCRIPTIONS_FORM,
        ProductDescriptionsEntity.PRODUCT_DESCRIPTION
      )
    );
  };

export const insertProductDescription =
  (value, index, pageConfig) => dispatch =>
    dispatch(
      arraySplice(
        pageConfig.formName,
        `${ShipmentEntity.OUTBOUND_CONSIGNMENT.PARCELS}[0].products`,
        index,
        1,
        value
      )
    );

export const createParcelProduct =
  ({ productIndex, packageNumber, ...values }, pageConfig) =>
  (dispatch, getState) => {
    const state = getState();
    const hiddenFields = ShipmentSelectors.getProductHiddenFields(
      state,
      pageConfig
    );
    const omittedFields = getProductOmittedFields(hiddenFields);
    const product = omitNilValues(
      omit(omitBy(pick(values, flattenEntityRoutes(ProductEntity)), isNull), [
        ProductEntity.SHORT_NAME,
        ProductEntity.CURRENCY,
        ...omittedFields,
      ])
    );

    if (isUndefined(productIndex)) {
      return dispatch(
        arrayPush(
          pageConfig.formName,
          `outboundConsignment.parcels[${packageNumber}].products`,
          product
        )
      );
    }

    const formProducts = getValue(
      ShipmentSelectors.getShipmentFormValues(state, pageConfig),
      `${ShipmentEntity.OUTBOUND_CONSIGNMENT.PARCELS}.${packageNumber}.products`,
      []
    );
    const index =
      productIndex > formProducts.length ? formProducts.length : productIndex;

    dispatch(
      arraySplice(
        pageConfig.formName,
        `${ShipmentEntity.OUTBOUND_CONSIGNMENT.PARCELS}[${packageNumber}].products`,
        index,
        0,
        product
      )
    );
  };

export const defineParcels = pageConfig => (dispatch, getState) => {
  const state = getState();
  const shipmentParcels = getValue(
    ShipmentReviewSelectors.getSelectedShipment(state),
    ShipmentEntity.OUTBOUND_CONSIGNMENT.PARCELS,
    []
  );
  const formParcels = getValue(
    ShipmentSelectors.getShipmentFormValues(state, pageConfig),
    ShipmentEntity.OUTBOUND_CONSIGNMENT.PARCELS,
    []
  );

  if (shipmentParcels.length && !formParcels.length) {
    const parcels = shipmentParcels.map(({ parcelId }) => ({
      parcelId,
      products: [],
    }));

    return dispatch(
      initializeForm(pageConfig.formName, {
        [OUTBOUND_CONSIGNMENT]: {
          parcels,
        },
      })
    );
  }
};

const handleFdaNumber =
  ({ pageConfig, service, prevService }) =>
  (dispatch, getState) => {
    const state = getState();
    const profile = ProfilesSelectors.getActiveProfile(state, pageConfig);
    const preferences = UmsSelectors.getPreferences(state);
    const isVisibleFdaNumber = ServiceModels.isVisibleFdaNumber(service);
    const prevIsVisibleFdaNumber =
      ServiceModels.isVisibleFdaNumber(prevService);
    const defaultFda = !isEmpty(profile?.invDetailsPostcode)
      ? profile?.invFdaRegistrationNumber
      : preferences.prefsInvoiceSettings.fdaRegistrationNumber;

    if (isVisibleFdaNumber && !prevIsVisibleFdaNumber) {
      return dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.INVOICE.EXPORTER_DETAILS.FDA_NUMBER,
          defaultFda
        )
      );
    }

    if (!isVisibleFdaNumber && prevIsVisibleFdaNumber) {
      return dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.INVOICE.EXPORTER_DETAILS.FDA_NUMBER,
          undefined
        )
      );
    }
  };

const handleVatNumber =
  ({ pageConfig, service, prevService, prevFormValues }) =>
  (dispatch, getState) => {
    const state = getState();
    const customer = UmsSelectors.getCustomer(state);
    const selectedAddressBook = ShipmentSelectors.getSelectedAddressBook(state);
    const createShipmentValues = ShipmentSelectors.getShipmentFormValues(
      state,
      pageConfig
    );
    const isVisibleVatNumber = InvoiceModels.isVisibleVatNumber(
      service,
      customer,
      getValue(
        createShipmentValues,
        ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS,
        false
      )
    );
    const prevIsVisibleVatNumber = InvoiceModels.isVisibleVatNumber(
      prevService,
      customer,
      getValue(
        prevFormValues,
        ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS,
        false
      )
    );

    if (
      isVisibleVatNumber &&
      !prevIsVisibleVatNumber &&
      !isEmpty(selectedAddressBook?.vatNumber)
    ) {
      return dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.INVOICE.IMPORTER_DETAILS.VAT_NUMBER,
          selectedAddressBook?.vatNumber
        )
      );
    }

    if (!isVisibleVatNumber && prevIsVisibleVatNumber) {
      return dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.INVOICE.IMPORTER_DETAILS.VAT_NUMBER,
          undefined
        )
      );
    }
  };

const handlePidNumber =
  ({ pageConfig, service, prevService, prevFormValues }) =>
  (dispatch, getState) => {
    const state = getState();
    const customer = UmsSelectors.getCustomer(state);
    const selectedAddressBook = ShipmentSelectors.getSelectedAddressBook(state);
    const createShipmentValues = ShipmentSelectors.getShipmentFormValues(
      state,
      pageConfig
    );
    const isVisiblePidNumber = InvoiceModels.isVisiblePidNumber(
      service,
      customer,
      getValue(
        createShipmentValues,
        ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS,
        false
      )
    );
    const prevIsVisiblePidNumber = InvoiceModels.isVisiblePidNumber(
      prevService,
      customer,
      getValue(
        prevFormValues,
        ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS,
        false
      )
    );

    if (
      isVisiblePidNumber &&
      !prevIsVisiblePidNumber &&
      !isEmpty(selectedAddressBook?.pid)
    ) {
      return dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.INVOICE.IMPORTER_DETAILS.PID_NUMBER,
          selectedAddressBook?.pid
        )
      );
    }

    if (!isVisiblePidNumber && prevIsVisiblePidNumber) {
      return dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.INVOICE.IMPORTER_DETAILS.PID_NUMBER,
          undefined
        )
      );
    }
  };

const handleUkimsNumbers =
  ({ pageConfig, service, prevService, prevFormValues }) =>
  (dispatch, getState) => {
    const state = getState();
    const customer = UmsSelectors.getCustomer(state);
    const profile = ProfilesSelectors.getActiveProfile(state, pageConfig);
    const preferences = UmsSelectors.getPreferences(state);
    const selectedAddressBook = ShipmentSelectors.getSelectedAddressBook(state);
    const createShipmentValues = ShipmentSelectors.getShipmentFormValues(
      state,
      pageConfig
    );
    const isVisibleUkims = InvoiceModels.isVisibleUkims(
      {
        isBusiness: getValue(
          createShipmentValues,
          ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS,
          false
        ),
        atRisk: getValue(
          createShipmentValues,
          getValue(
            createShipmentValues,
            ShipmentEntity.INVOICE.IMPORTER_DETAILS.AT_RISK,
            false
          )
        ),
        countryCode: getValue(
          createShipmentValues,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
            .COUNTRY_CODE
        ),
        postcode: getValue(
          createShipmentValues,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE
        ),
      },
      customer.shippingVersion,
      service
    );
    const prevIsVisibleUkims = InvoiceModels.isVisibleUkims(
      {
        isBusiness: getValue(
          prevFormValues,
          ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS,
          false
        ),
        atRisk: getValue(
          prevFormValues,
          getValue(
            prevFormValues,
            ShipmentEntity.INVOICE.IMPORTER_DETAILS.AT_RISK,
            false
          )
        ),
        countryCode: getValue(
          prevFormValues,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
            .COUNTRY_CODE
        ),
        postcode: getValue(
          prevFormValues,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE
        ),
      },
      customer.shippingVersion,
      prevService
    );
    const { ukimsNumber: defaultExporterUkims } =
      ShipmentModels.getInitialTaxValues(profile, preferences);

    if (isVisibleUkims && !prevIsVisibleUkims) {
      !isEmpty(selectedAddressBook?.ukimsNumber) &&
        dispatch(
          change(
            pageConfig.formName,
            ShipmentEntity.INVOICE.IMPORTER_DETAILS.UKIMS_NUMBER,
            selectedAddressBook?.ukimsNumber
          )
        );
      return dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.INVOICE.EXPORTER_DETAILS.UKIMS_NUMBER,
          defaultExporterUkims
        )
      );
    }

    if (!isVisibleUkims && prevIsVisibleUkims) {
      dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.INVOICE.IMPORTER_DETAILS.UKIMS_NUMBER,
          undefined
        )
      );
      return dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.INVOICE.EXPORTER_DETAILS.UKIMS_NUMBER,
          undefined
        )
      );
    }
  };

const handleExportReason =
  ({ pageConfig, service }) =>
  (dispatch, getState) => {
    const state = getState();
    const customer = UmsSelectors.getCustomer(state);
    const createShipmentValues = ShipmentSelectors.getShipmentFormValues(
      state,
      pageConfig
    );
    const exportReason = getValue(
      createShipmentValues,
      ShipmentEntity.INVOICE.EXPORT_REASON
    );
    const selectedCountry = ShipmentSelectors.getSelectedCountry(
      state,
      pageConfig
    );
    const exportReasons = ReferenceSelectors.getExportReasons(state);
    const newExportReasons = InvoiceModels.getReasons(
      exportReasons,
      selectedCountry,
      service
    );
    const isUnavailableExportReason = InvoiceModels.isUnavailableExportReason(
      createShipmentValues,
      newExportReasons
    );
    const isVisibleExportReason = InvoiceModels.isVisibleExportReason(
      service,
      customer
    );

    if (isUnavailableExportReason || (exportReason && !isVisibleExportReason)) {
      return dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.INVOICE.EXPORT_REASON,
          undefined
        )
      );
    }
  };

const handleShippingCost =
  ({ pageConfig, service, prevService }) =>
  (dispatch, getState) => {
    const customer = UmsSelectors.getCustomer(getState());
    const isVisibleShippingCost = InvoiceModels.isVisibleShippingCost(
      service,
      customer
    );
    const prevIsVisibleShippingCost = InvoiceModels.isVisibleShippingCost(
      prevService,
      customer
    );

    if (service && !isVisibleShippingCost && prevIsVisibleShippingCost) {
      return dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.INVOICE.SHIPPING_COST,
          undefined
        )
      );
    }

    if (service && isVisibleShippingCost && !prevIsVisibleShippingCost) {
      return dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.INVOICE.SHIPPING_COST,
          DEFAULT_ZERO_VALUE
        )
      );
    }
  };

const handleExtendedLiability =
  ({ pageConfig, service, prevService }) =>
  (dispatch, getState) => {
    const state = getState();
    const securitySettings = UmsSelectors.getSecuritySettings(state);
    const preferences = UmsSelectors.getPreferences(state);
    const createShipmentValues = ShipmentSelectors.getShipmentFormValues(
      state,
      pageConfig
    );
    const countryCode = getValue(
      createShipmentValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
      ""
    );
    const allowedLiability = LiabilityModels.getIsExtendedLiabilityFlagAllowed(
      securitySettings,
      service
    );
    const prevAllowedLiability =
      LiabilityModels.getIsExtendedLiabilityFlagAllowed(
        securitySettings,
        prevService
      );
    const isLiabilityValueVisible =
      LiabilityModels.isLiabilityValueVisible(createShipmentValues);
    const liabilityValue = getValue(
      createShipmentValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY_VALUE
    );

    // @see https://it.dpduk.live/version/customer-shipping/sprint-1.19/diag_veA81N6GAqAAha2L.html?id=1639728457038
    // @see https://it.dpduk.live/version/customer-shipping/sprint-1.19/diag_veA81N6GAqAAha2L.html?id=1639728457038
    if (service && !allowedLiability && prevAllowedLiability) {
      dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY,
          undefined
        )
      );
      return dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY_VALUE,
          undefined
        )
      );
    }

    if (allowedLiability && !prevAllowedLiability) {
      const defaultLiability = LiabilityModels.getDefaultLiability(
        preferences,
        countryCode
      );
      const defaultLiabilityValue = LiabilityModels.getDefaultLiabilityValue(
        preferences,
        countryCode,
        defaultLiability
      );

      dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY,
          defaultLiability
        )
      );
      defaultLiabilityValue &&
        dispatch(
          change(
            pageConfig.formName,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY_VALUE,
            defaultLiabilityValue
          )
        );

      return;
    }

    if (service && !isLiabilityValueVisible && liabilityValue) {
      return dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY_VALUE,
          undefined
        )
      );
    }

    if (service && isLiabilityValueVisible && !liabilityValue) {
      const defaultLiabilityValue = LiabilityModels.getDefaultLiabilityValue(
        preferences,
        countryCode,
        getValue(
          createShipmentValues,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY
        )
      );

      defaultLiabilityValue &&
        dispatch(
          change(
            pageConfig.formName,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY_VALUE,
            defaultLiabilityValue
          )
        );
    }
  };

const handleAdditionalDataRequirements =
  ({ pageConfig, service, prevService, prevFormValues }) =>
  (dispatch, getState) => {
    const state = getState();
    const preferences = UmsSelectors.getPreferences(state);
    const customer = UmsSelectors.getCustomer(state);
    const profile = ProfilesSelectors.getActiveProfile(state, pageConfig);
    const createShipmentValues = ShipmentSelectors.getShipmentFormValues(
      state,
      pageConfig
    );
    const {
      destinationTaxId: defaultDestinationTaxId,
      gstVatPaid: defaultGstVatPaid,
    } = ShipmentModels.getInitialTaxValues(profile, preferences);
    const { preSelectedValue: generateCustomsData } =
      ShipmentModels.getGenerateCustomsDataSettings(
        service,
        preferences,
        profile,
        createShipmentValues,
        customer
      );
    const { preSelectedValue: prevGenerateCustomsData } =
      ShipmentModels.getGenerateCustomsDataSettings(
        prevService,
        preferences,
        profile,
        prevFormValues,
        customer
      );
    const isVisibleTaxId = ServiceModels.isVisibleDestinationTaxIdRegNo(
      service,
      generateCustomsData,
      customer
    );
    const prevIsVisibleTaxId = ServiceModels.isVisibleDestinationTaxIdRegNo(
      prevService,
      prevGenerateCustomsData,
      customer
    );
    const isVisibleGstVatPaid =
      isVisibleTaxId &&
      !!getValue(
        createShipmentValues,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO
      ) &&
      !ServiceModels.isFlagNeedles(service, "vatPaidRequired");
    const prevIsVisibleGstVatPaid =
      prevIsVisibleTaxId &&
      !!getValue(
        prevFormValues,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO
      ) &&
      !ServiceModels.isFlagNeedles(prevService, "vatPaidRequired");

    if (!isVisibleTaxId && prevIsVisibleTaxId) {
      dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO,
          undefined
        )
      );
      return dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.GST_VAT_PAID,
          undefined
        )
      );
    }

    if (isVisibleTaxId && !prevIsVisibleTaxId) {
      const isVisibleDefaultGstVatPaid =
        isVisibleTaxId &&
        !!defaultDestinationTaxId &&
        !ServiceModels.isFlagNeedles(service, "vatPaidRequired");

      dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO,
          defaultDestinationTaxId
        )
      );
      isVisibleDefaultGstVatPaid &&
        dispatch(
          change(
            pageConfig.formName,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.GST_VAT_PAID,
            defaultGstVatPaid
          )
        );

      return;
    }

    if (service && !isVisibleGstVatPaid && prevIsVisibleGstVatPaid) {
      return dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.GST_VAT_PAID,
          undefined
        )
      );
    }

    if (service && isVisibleGstVatPaid && !prevIsVisibleGstVatPaid) {
      return dispatch(
        change(
          pageConfig.formName,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.GST_VAT_PAID,
          defaultGstVatPaid
        )
      );
    }
  };

const handleParcels =
  ({ pageConfig, service, prevService, prevFormValues }) =>
  (dispatch, getState) => {
    const state = getState();
    const customer = UmsSelectors.getCustomer(state);
    const isNewVersion = SettingsModels.isNewVersion(customer.shippingVersion);
    const createShipmentValues = ShipmentSelectors.getShipmentFormValues(
      state,
      pageConfig
    );
    const parcels = getValue(
      createShipmentValues,
      "outboundConsignment.parcels",
      []
    );
    const isEditProductFormActive =
      ShipmentSelectors.isEditProductFormActive(state);
    const hiddenFields = ShipmentSelectors.getProductHiddenFields(
      state,
      pageConfig
    );
    const prevHiddenFields = ParcelModels.getProductHiddenFields(
      prevService,
      getValue(prevFormValues, ShipmentEntity.GENERATE_CUSTOMS_DATA),
      customer
    );
    const omittedFields = [];

    if (hiddenFields.commodityCode && !prevHiddenFields.commodityCode) {
      omittedFields.push(ProductEntity.COMMODITY_CODE);
    }

    if (hiddenFields.countryOfOrigin && !prevHiddenFields.countryOfOrigin) {
      omittedFields.push(ProductEntity.COUNTRY_OF_ORIGIN);
    }

    if (hiddenFields.productType && !prevHiddenFields.productType) {
      omittedFields.push(ProductEntity.PRODUCT_TYPE);
    }

    if (
      hiddenFields.productComposition &&
      !prevHiddenFields.productComposition
    ) {
      omittedFields.push(ProductEntity.PRODUCT_COMPOSITION);
    }

    if (hiddenFields.productCode && !prevHiddenFields.productCode) {
      omittedFields.push(ProductEntity.PRODUCT_CODE);
    }

    if (hiddenFields.productUrl && !prevHiddenFields.productUrl) {
      omittedFields.push(ProductEntity.PRODUCT_URL);
    }

    if (isEditProductFormActive) {
      dispatch(resetSection(EDIT_PRODUCT_FORM, ...omittedFields));
    }

    if (
      !isNewVersion ||
      isEmpty(parcels) ||
      ServiceModels.isFlagDescription(service, "prodRequired")
    ) {
      return;
    }

    const updatedParcels = parcels.map(({ parcelId, products }) => {
      const updatedProducts = products.map(product =>
        omit(product, omittedFields)
      );

      return {
        products: updatedProducts,
        ...(parcelId && { parcelId }),
      };
    });
    // NOTE: only first element was changed
    dispatch(
      arraySplice(
        pageConfig.formName,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.PARCELS,
        0,
        1,
        updatedParcels[0]
      )
    );
  };

export const clearReturnDetailsData = pageConfig => dispatch => {
  dispatch(resetInboundConsignment(pageConfig.formName));
  dispatch(ReferenceActions.clearInboundServices());
  dispatch(clearAddressBook(pageConfig.formName));
  dispatch(reset(RETURN_DETAILS_SEARCH_FORM));
};

export const handleSaebiFlags =
  ({ pageConfig, service, prevService, prevFormValues }) =>
  (dispatch, getState) => {
    const state = getState();
    const createShipmentValues = ShipmentSelectors.getShipmentFormValues(
      state,
      pageConfig
    );
    const generateCustomsData = getValue(
      createShipmentValues,
      ShipmentEntity.GENERATE_CUSTOMS_DATA
    );
    const invoice = getValue(createShipmentValues, "invoice", {});
    const customer = UmsSelectors.getCustomer(state);
    const { invoiceDetailsSection, exporterImporterDetails, businessFields } =
      NewShipmentModels.isShippingInformationVisible(customer, service, {
        generateCustomsData,
        countryCode: getValue(
          createShipmentValues,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
            .COUNTRY_CODE,
          ""
        ),
        postCode: getValue(
          createShipmentValues,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
          ""
        ),
      });

    dispatch(
      handleExtendedLiability({
        pageConfig,
        service,
        prevService,
      })
    );
    dispatch(
      handleAdditionalDataRequirements({
        pageConfig,
        service,
        prevService,
        prevFormValues,
      })
    );
    dispatch(
      handleParcels({
        pageConfig,
        service,
        prevService,
        prevFormValues,
      })
    );

    if (
      isEmpty(invoice) &&
      generateCustomsData &&
      (invoiceDetailsSection || exporterImporterDetails || businessFields)
    ) {
      // NOTE: case select other service after dpd-direct services
      return dispatch(initializeShippingInformation(pageConfig));
    }

    if (!isEmpty(invoice) && generateCustomsData) {
      if (
        !invoiceDetailsSection &&
        !exporterImporterDetails &&
        !businessFields
      ) {
        // NOTE: case select dpd-direct services
        return dispatch(resetSection(pageConfig.formName, INVOICE));
      }

      if (invoiceDetailsSection) {
        dispatch(handleExportReason({ pageConfig, service }));
        dispatch(handleShippingCost({ pageConfig, service, prevService }));
      }

      if (exporterImporterDetails) {
        dispatch(handleFdaNumber({ pageConfig, service, prevService }));
        dispatch(
          handleVatNumber({ pageConfig, service, prevService, prevFormValues })
        );
        dispatch(
          handlePidNumber({ pageConfig, service, prevService, prevFormValues })
        );
        dispatch(
          handleUkimsNumbers({
            pageConfig,
            service,
            prevService,
            prevFormValues,
          })
        );
      }
    }
  };
