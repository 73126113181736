import { findIndex, get, isBoolean, isEmpty, set, values } from "lodash";

import {
  ADDRESS,
  CONTACT_DETAILS,
  DELIVERY_DETAILS,
  Fields,
  IMPORTER_DETAILS,
  INVOICE,
  InvoiceEntity,
  OUTBOUND_CONSIGNMENT,
  ShipmentEntity,
} from "~/constants/forms";
import {
  defaultReasons,
  deliveredAtPlace,
  deliveredDutyPaid,
  dutiesTaxesPrepaid,
  excludeReasons,
  INVOICE_TYPE,
  INVOICE_TYPE_STRING,
} from "~/constants/invoice";
import * as S from "~/constants/strings";
import { roundToDecimal } from "~/utils/number";
import { getValue, toUppercaseValues } from "~/utils/object";
import {
  AddressModels,
  NewShipmentModels,
  ServiceModels,
  SettingsModels,
  ShipmentModels,
} from "~/models";

export const getReasons = (
  exportReasons = [],
  selectedCountry = {},
  selectedService = {}
) => {
  if (
    excludeReasons.includes(selectedService.networkKey) ||
    (selectedCountry.countryKey === S.IE &&
      ServiceModels.getNetworkCode(selectedService) === "11")
  ) {
    return defaultReasons;
  }

  return exportReasons.map(({ code: value, description: label }) => ({
    label,
    value,
  }));
};

export const isProformaInvoiceType = type =>
  type === INVOICE_TYPE_STRING.PROFORMA;

export const isCommercialInvoiceType = type =>
  type === INVOICE_TYPE_STRING.COMMERCIAL;

// @see: https://it.dpduk.live/it/diagram/diag_1vnf3D6GAqAAhRU_.html
export const getInternationalBillings = (
  selectedService = {},
  customerPrefs = {}
) => {
  const billings = [deliveredAtPlace];

  // NOTE: order is required (see comments at https://geopost.jira.com/browse/CSHIP-7314)
  if (
    ServiceModels.isFlagOptional(selectedService, "ddpAvailable") &&
    customerPrefs.allowDdp
  ) {
    billings.unshift(deliveredDutyPaid);
  }

  if (
    ServiceModels.isFlagOptional(selectedService, "dt1Available") &&
    customerPrefs.allowDt1
  ) {
    billings.unshift(dutiesTaxesPrepaid);
  }

  return billings;
};

const parseInvoiceTypeValue = value => {
  switch (value) {
    case INVOICE_TYPE_STRING.PROFORMA:
      return INVOICE_TYPE_STRING.PROFORMA;
    case INVOICE_TYPE_STRING.COMMERCIAL:
      return INVOICE_TYPE_STRING.COMMERCIAL;
    default:
      return null;
  }
};

// @see: https://it.dpduk.live/it/diagram/diag_zEmhGl6GAqAADHXl.html
export const getDefaultInvoiceType = (
  profileInvoiceType,
  preferenceInvoiceType
) => {
  const profileValue = parseInvoiceTypeValue(profileInvoiceType);
  const preferenceValue = parseInvoiceTypeValue(preferenceInvoiceType);
  const invoiceType = profileValue === null ? preferenceValue : profileValue;

  return isProformaInvoiceType(invoiceType)
    ? INVOICE_TYPE.PROFORMA
    : INVOICE_TYPE.COMMERCIAL;
};

export const getInvoiceType = (
  preferenceInvoiceType,
  profileInvoiceType,
  shipmentInvoiceType
) => {
  if (shipmentInvoiceType) {
    const isAvailableInOption =
      values(INVOICE_TYPE).includes(shipmentInvoiceType);

    if (isAvailableInOption) {
      return shipmentInvoiceType;
    }
    return shipmentInvoiceType;
  }
  return getDefaultInvoiceType(profileInvoiceType, preferenceInvoiceType);
};

// @see: see comments at https://geopost.jira.com/browse/CSHIP-7314
export const getDefaultInternationalBilling = billings => billings[0].value;

// TODO: remove after successfully testing setupInitialInvoiceNewVersion
export const setupInitialInvoiceOldVersion = data => {
  const {
    shipment,
    selectedOutboundNetwork,
    customer,
    preferences,
    profile,
    customerPrefs,
  } = data;
  const invoice = getValue(shipment, "invoice", {});
  const generateCustomsData = getValue(
    shipment,
    ShipmentEntity.GENERATE_CUSTOMS_DATA
  );

  if (isEmpty(invoice) && !generateCustomsData) {
    return {
      ...data,
      shipment,
    };
  }

  const isBusiness = get(
    shipment,
    ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS
  );

  // for failed shipment set default values
  if (isEmpty(invoice) && generateCustomsData) {
    const { totalWeight, numberOfParcels } =
      ShipmentModels.getPackageDetails(shipment);
    set(invoice, InvoiceEntity.IMPORTER_DETAILS.IS_BUSINESS, isBusiness);
    set(invoice, InvoiceEntity.TOTAL_WEIGHT, totalWeight);
    set(invoice, InvoiceEntity.NUMBER_OF_PARCELS, numberOfParcels);

    return {
      ...data,
      shipment: {
        ...shipment,
        invoice,
      },
    };
  }

  // if international set default if absent () 4 section
  const exporterOrganisation = getValue(
    invoice,
    InvoiceEntity.EXPORTER_DETAILS.ADDRESS.ORGANISATION
  );
  const importerOrganisation = get(
    invoice,
    InvoiceEntity.IMPORTER_DETAILS.ADDRESS.ORGANISATION
  );
  const internationalBillings = getInternationalBillings(
    selectedOutboundNetwork,
    customerPrefs
  );

  if (!exporterOrganisation) {
    set(
      invoice,
      InvoiceEntity.EXPORTER_DETAILS.ADDRESS.ORGANISATION,
      getValue(customer, "customername")?.toUpperCase()
    );
  }

  if (!importerOrganisation) {
    const contactName = get(
      shipment,
      ShipmentEntity.INVOICE.IMPORTER_DETAILS.CONTACT_DETAILS.CONTACT_NAME
    );
    set(
      invoice,
      InvoiceEntity.IMPORTER_DETAILS.ADDRESS.ORGANISATION,
      contactName
    );
  }

  set(
    invoice,
    InvoiceEntity.TOTAL_WEIGHT,
    ServiceModels.roundTotalWeight(
      get(shipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT),
      get(
        shipment,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
          .COUNTRY_CODE
      ),
      get(selectedOutboundNetwork, "networkKey"),
      true
    )
  );
  set(
    invoice,
    InvoiceEntity.NUMBER_OF_PARCELS,
    ServiceModels.roundNumberOfParcels(
      get(shipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS)
    )
  );
  set(invoice, InvoiceEntity.IMPORTER_DETAILS.IS_BUSINESS, isBusiness);
  set(
    invoice,
    InvoiceEntity.SHIPPING_COST,
    roundToDecimal(get(shipment, ShipmentEntity.INVOICE.SHIPPING_COST))
  );

  if (!getValue(shipment, ShipmentEntity.INVOICE.INVOICE_REFERENCE)) {
    set(
      invoice,
      InvoiceEntity.INVOICE_REFERENCE,
      getValue(shipment, "outboundConsignment.consignmentNumber")
    );
  }

  set(
    invoice,
    InvoiceEntity.INVOICE_TYPE,
    getInvoiceType(
      preferences?.prefsInvoiceSettings?.invoiceType,
      profile?.invInvoiceType,
      getValue(shipment, ShipmentEntity.INVOICE.INVOICE_TYPE)
    )
  );

  if (
    internationalBillings.findIndex(
      ({ value }) =>
        value ===
        getValue(shipment, ShipmentEntity.INVOICE.INTERNATIONAL_BILLING_TERMS)
    ) === -1
  ) {
    set(
      invoice,
      InvoiceEntity.INTERNATIONAL_BILLING_TERMS,
      getDefaultInternationalBilling(internationalBillings)
    );
  }

  if (isBusiness) {
    delete invoice.importerDetails.pidNumber;
  } else {
    delete invoice.importerDetails.eoriNumber;
    delete invoice.importerDetails.vatNumber;
  }

  if (ServiceModels.isFlagNeedles(selectedOutboundNetwork, "taxRequired")) {
    delete invoice.exporterDetails.destinationTaxId;
    delete invoice.exporterDetails.gstVatPaid;
  }

  if (!ServiceModels.isVisibleFdaNumber(selectedOutboundNetwork)) {
    delete invoice.exporterDetails.fdaRegistrationNumber;
  }

  return {
    ...data,
    shipment: {
      ...shipment,
      invoice,
    },
  };
};

export const isVisibleExportReason = (service, customer) =>
  SettingsModels.isNewVersion(customer.shippingVersion)
    ? !ServiceModels.isFlagNeedles(service, "exportReasonRequired")
    : true;

export const isUnavailableExportReason = (shipment, exportReasons) => {
  const currentExportReason = get(
    shipment,
    ShipmentEntity.INVOICE.EXPORT_REASON
  );
  const currentExportReasonIndex = findIndex(
    exportReasons,
    ({ value }) => value === currentExportReason
  );

  return (
    currentExportReason &&
    exportReasons?.length &&
    currentExportReasonIndex === -1
  );
};

export const isVisibleShippingCost = (service, customer) =>
  SettingsModels.isNewVersion(customer.shippingVersion)
    ? !ServiceModels.isFlagNeedles(service, "costsRequired")
    : true;

export const isVisibleVatNumber = (service, customer, isBusiness) =>
  SettingsModels.isNewVersion(customer.shippingVersion)
    ? isBusiness && !ServiceModels.isFlagNeedles(service, "vatRequired")
    : true;

export const isVisiblePidNumber = (service, customer, isBusiness) =>
  SettingsModels.isNewVersion(customer.shippingVersion)
    ? !isBusiness && !ServiceModels.isFlagNeedles(service, "pidRequired")
    : !isBusiness &&
      !ServiceModels.shouldHideBusinessConsumerOptions(
        service,
        customer.shippingVersion
      );

export const isVisibleUkims = (
  { isBusiness, atRisk, countryCode, postcode },
  shippingVersion,
  service
) =>
  SettingsModels.isNewVersion(shippingVersion) &&
  AddressModels.isNiShipment(countryCode, postcode) &&
  isBusiness &&
  !atRisk &&
  !ServiceModels.isFlagNeedles(service, "ukimsRequired");

export const setupInitialInvoiceNewVersion = data => {
  const {
    shipment,
    selectedOutboundNetwork,
    customer,
    preferences,
    profile,
    customerPrefs,
  } = data;
  const invoice = getValue(shipment, "invoice", {});
  const generateCustomsData = getValue(
    shipment,
    ShipmentEntity.GENERATE_CUSTOMS_DATA
  );

  if (isEmpty(invoice) && !generateCustomsData) {
    return {
      ...data,
      shipment,
    };
  }
  //TODO: initial page values

  const isBusiness = getValue(
    shipment,
    ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS,
    false
  );
  const atRisk = getValue(
    shipment,
    ShipmentEntity.INVOICE.IMPORTER_DETAILS.AT_RISK,
    false
  );
  const countryCode = getValue(
    shipment,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
    ""
  );
  const postCode = getValue(
    shipment,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
    ""
  );
  const { invoiceDetailsSection, exporterImporterDetails, businessFields } =
    NewShipmentModels.isShippingInformationVisible(
      customer,
      selectedOutboundNetwork,
      {
        generateCustomsData,
        countryCode,
        postCode,
      }
    );
  const visibleExporterFields =
    ServiceModels.isVisibleExporterTaxRequiredFields(
      selectedOutboundNetwork,
      getValue(
        shipment,
        ShipmentEntity.INVOICE.EXPORTER_DETAILS.DESTINATION_TAX_ID_REG_NO,
        ""
      ),
      customer.shippingVersion
    );
  const shouldClearVatNumber = !isVisibleVatNumber(
    selectedOutboundNetwork,
    customer,
    isBusiness
  );

  // for failed shipment set default values
  if (isEmpty(invoice) && generateCustomsData) {
    return {
      ...data,
      shipment: {
        ...shipment,
        invoice,
      },
    };
  }

  if (!invoiceDetailsSection) {
    delete invoice.invoiceType;
    delete invoice.termsOfDelivery;
    delete invoice.invoiceReference;
    delete invoice.exportReason;
    delete invoice.shippingCost;
  } else {
    const internationalBillings = getInternationalBillings(
      selectedOutboundNetwork,
      customerPrefs
    );
    set(
      invoice,
      InvoiceEntity.SHIPPING_COST,
      roundToDecimal(get(shipment, ShipmentEntity.INVOICE.SHIPPING_COST))
    );
    set(
      invoice,
      InvoiceEntity.INVOICE_TYPE,
      getInvoiceType(
        preferences?.prefsInvoiceSettings?.invoiceType,
        profile?.invInvoiceType,
        getValue(shipment, ShipmentEntity.INVOICE.INVOICE_TYPE)
      )
    );

    if (!SettingsModels.isNewVersion(customer.shippingVersion)) {
      set(
        invoice,
        InvoiceEntity.TOTAL_WEIGHT,
        ServiceModels.roundTotalWeight(
          get(shipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT),
          get(
            shipment,
            ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
              .COUNTRY_CODE
          ),
          get(selectedOutboundNetwork, "networkKey"),
          true
        )
      );
      set(
        invoice,
        InvoiceEntity.NUMBER_OF_PARCELS,
        ServiceModels.roundNumberOfParcels(
          get(shipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS)
        )
      );
    }

    if (!getValue(shipment, ShipmentEntity.INVOICE.INVOICE_REFERENCE)) {
      set(
        invoice,
        InvoiceEntity.INVOICE_REFERENCE,
        getValue(shipment, "outboundConsignment.consignmentNumber")
      );
    }

    if (
      internationalBillings.findIndex(
        ({ value }) =>
          value ===
          getValue(shipment, ShipmentEntity.INVOICE.INTERNATIONAL_BILLING_TERMS)
      ) === -1
    ) {
      set(
        invoice,
        InvoiceEntity.INTERNATIONAL_BILLING_TERMS,
        getDefaultInternationalBilling(internationalBillings)
      );
    }

    if (!isVisibleExportReason(selectedOutboundNetwork, customer)) {
      delete invoice.exportReason;
    }

    if (!isVisibleShippingCost(selectedOutboundNetwork, customer)) {
      delete invoice.shippingCost;
    }
  }

  if (exporterImporterDetails) {
    // if international set default if absent () 4 section
    const exporterOrganisation = getValue(
      invoice,
      InvoiceEntity.EXPORTER_DETAILS.ADDRESS.ORGANISATION
    );
    const importerOrganisation = get(
      invoice,
      InvoiceEntity.IMPORTER_DETAILS.ADDRESS.ORGANISATION
    );

    if (!exporterOrganisation) {
      set(
        invoice,
        InvoiceEntity.EXPORTER_DETAILS.ADDRESS.ORGANISATION,
        getValue(customer, "customername")?.toUpperCase()
      );
    }

    if (!importerOrganisation) {
      const contactName = get(
        shipment,
        ShipmentEntity.INVOICE.IMPORTER_DETAILS.CONTACT_DETAILS.CONTACT_NAME
      );
      set(
        invoice,
        InvoiceEntity.IMPORTER_DETAILS.ADDRESS.ORGANISATION,
        contactName
      );
    }

    if (!isBusiness) {
      delete invoice.importerDetails.eoriNumber;
    }

    if (!isVisiblePidNumber(selectedOutboundNetwork, customer, isBusiness)) {
      delete invoice.importerDetails.pidNumber;
    }

    if (shouldClearVatNumber) {
      delete invoice.importerDetails.vatNumber;
    }

    if (!visibleExporterFields[Fields.DESTINATION_TAX_ID_REG_NO]) {
      delete invoice.exporterDetails.destinationTaxId;
    }

    if (!visibleExporterFields[Fields.GST_VAT_PAID]) {
      delete invoice.exporterDetails.gstVatPaid;
    }

    if (!visibleExporterFields[Fields.FDA_NUMBER]) {
      delete invoice.exporterDetails.fdaRegistrationNumber;
    }

    if (
      !isVisibleUkims(
        {
          isBusiness,
          atRisk,
          countryCode,
          postcode: postCode,
        },
        customer.shippingVersion,
        selectedOutboundNetwork
      )
    ) {
      delete invoice.exporterDetails.ukimsNumber;
      delete invoice.importerDetails.ukimsNumber;
    }
  } else {
    delete invoice.exporterDetails;
    delete invoice.importerDetails;
  }

  set(invoice, InvoiceEntity.IMPORTER_DETAILS.IS_BUSINESS, isBusiness);

  if (businessFields) {
    set(invoice, InvoiceEntity.IMPORTER_DETAILS.AT_RISK, atRisk);
  }

  return {
    ...data,
    shipment: {
      ...shipment,
      invoice,
    },
  };
};

export const setupInitialInvoice = data => setupInitialInvoiceNewVersion(data);

// @see: https://it.dpduk.live/version/customer-shipping/ni-protocol-shipping/sprint-2/diag_lfeXaN6GAqAAhXnF.html
// @see: https://it.dpduk.live/version/customer-shipping/ni-protocol-shipping/sprint-2/diag_F_RVHF6GAqAADOKk.html
export const getImporterVisibleFields = (customer, values, selectedService) => {
  const isNewVersion = SettingsModels.isNewVersion(customer.shippingVersion);
  const isBusiness = getValue(values, Fields.IS_BUSINESS, false);

  return {
    [Fields.EORI_NUMBER]: isNewVersion && isBusiness,
    [Fields.VAT_NUMBER]: isVisibleVatNumber(
      selectedService,
      customer,
      isBusiness
    ),
    [Fields.PID_NUMBER]: isVisiblePidNumber(
      selectedService,
      customer,
      isBusiness
    ),
    [Fields.UKIMS_NUMBER]: isVisibleUkims(
      {
        isBusiness,
        atRisk: getValue(values, Fields.AT_RISK, false),
        countryCode: getValue(values, Fields.COUNTRY_CODE, ""),
        postcode: getValue(values, Fields.POSTCODE, ""),
      },
      customer.shippingVersion,
      selectedService
    ),
  };
};

// @see https://it.dpduk.live/version/customer-shipping/sprint-2.6/diag_F_RVHF6GAqAADOKk.html?id=1650536806775
export const getShipmentInfoDefault = ({
  selectedCountry,
  customerPrefs,
  shipment,
  selectedService,
  profile,
  preferences,
  exportReasons,
}) => {
  const havePostcode = !isEmpty(profile?.invDetailsPostcode);

  return {
    ...ShipmentModels.getPackageDetails(shipment),
    ...ShipmentModels.getInitialTaxValues(profile, preferences),
    defaultInvoiceType: getDefaultInvoiceType(
      profile?.invInvoiceType,
      preferences.prefsInvoiceSettings.invoiceType
    ),
    eoriNumber: havePostcode
      ? profile?.invShippersEoriNumber
      : preferences.prefsInvoiceSettings.eoriNumber,
    vatNumber: havePostcode
      ? profile?.invShippersVatNumber
      : preferences.prefsInvoiceSettings.vatNumber,
    fdaRegistrationNumber: ServiceModels.isVisibleFdaNumber(selectedService)
      ? havePostcode
        ? profile?.invFdaRegistrationNumber
        : preferences.prefsInvoiceSettings.fdaRegistrationNumber
      : null,
    exportReasons: getReasons(exportReasons, selectedCountry, selectedService),
    internationalBillings: getInternationalBillings(
      selectedService,
      customerPrefs
    ),
  };
};

export const getInitialInvoiceData = ({
  preferences,
  customer,
  selectedCountry,
  shipment,
  selectedService,
  profile,
  exportReasons,
  userCustomerAddress,
  customerPrefs,
  selectedAddressBook,
}) => {
  const countryCode = getValue(
    shipment,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
    ""
  );
  const postCode = getValue(
    shipment,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
    ""
  );
  // TODO: populate properly invoice
  const {
    totalWeight,
    numberOfParcels,
    defaultInvoiceType,
    internationalBillings,
    eoriNumber,
    vatNumber,
    fdaRegistrationNumber,
    destinationTaxId,
    gstVatPaid,
    ukimsNumber,
  } = getShipmentInfoDefault({
    selectedCountry,
    customerPrefs,
    shipment,
    selectedService,
    profile,
    preferences,
    exportReasons,
  });
  const defaultExporterAddress = AddressModels.getDefaultExporterAddress({
    customer,
    userCustomerAddress,
    profile,
    prefsInvoiceSettings: preferences.prefsInvoiceSettings,
  });
  const hideImportsOptions = ServiceModels.shouldHideBusinessConsumerOptions(
    selectedService,
    customer.shippingVersion
  );
  const { isBusiness, atRisk, ...importerBusinessFields } =
    getImporterBusinessFields(
      customer,
      selectedAddressBook,
      shipment,
      selectedService
    );
  const visibleExporterFields =
    ServiceModels.isVisibleExporterTaxRequiredFields(
      selectedService,
      destinationTaxId,
      customer.shippingVersion
    );
  const { invoiceDetailsSection, exporterImporterDetails, businessFields } =
    NewShipmentModels.isShippingInformationVisible(customer, selectedService, {
      generateCustomsData: getValue(
        shipment,
        ShipmentEntity.GENERATE_CUSTOMS_DATA,
        false
      ),
      countryCode,
      postCode,
    });
  const isAvailableUkims = isVisibleUkims(
    {
      isBusiness,
      atRisk,
      countryCode,
      postcode: postCode,
    },
    customer.shippingVersion,
    selectedService
  );
  const isAvailableShippingCost = isVisibleShippingCost(
    selectedService,
    customer
  );

  return toUppercaseValues({
    ...(invoiceDetailsSection && {
      ...(!SettingsModels.isNewVersion(customer.shippingVersion) && {
        [ShipmentEntity.INVOICE.TOTAL_WEIGHT]: totalWeight,
        [ShipmentEntity.INVOICE.NUMBER_OF_PARCELS]: numberOfParcels,
      }),
      [ShipmentEntity.INVOICE.INVOICE_TYPE]: defaultInvoiceType,
      [ShipmentEntity.INVOICE.INTERNATIONAL_BILLING_TERMS]:
        getDefaultInternationalBilling(internationalBillings),
      ...(isAvailableShippingCost && {
        [ShipmentEntity.INVOICE.SHIPPING_COST]: S.DEFAULT_ZERO_VALUE,
      }),
    }),
    ...(exporterImporterDetails && {
      [ShipmentEntity.INVOICE.EXPORTER_DETAILS.ADDRESS.STREET]:
        defaultExporterAddress[Fields.STREET],
      [ShipmentEntity.INVOICE.EXPORTER_DETAILS.ADDRESS.LOCALITY]:
        defaultExporterAddress[Fields.LOCALITY],
      [ShipmentEntity.INVOICE.EXPORTER_DETAILS.ADDRESS.TOWN]:
        defaultExporterAddress[Fields.TOWN],
      [ShipmentEntity.INVOICE.EXPORTER_DETAILS.ADDRESS.COUNTY]:
        defaultExporterAddress[Fields.COUNTY],
      [ShipmentEntity.INVOICE.EXPORTER_DETAILS.ADDRESS.POSTCODE]:
        defaultExporterAddress[Fields.POSTCODE],
      [ShipmentEntity.INVOICE.EXPORTER_DETAILS.ADDRESS.COUNTRY_CODE]:
        defaultExporterAddress[Fields.COUNTRY_CODE],
      [ShipmentEntity.INVOICE.EXPORTER_DETAILS.CONTACT_DETAILS.CONTACT_NAME]:
        defaultExporterAddress[Fields.CONTACT_NAME],
      [ShipmentEntity.INVOICE.EXPORTER_DETAILS.CONTACT_DETAILS.TELEPHONE]:
        defaultExporterAddress[Fields.TELEPHONE],
      [ShipmentEntity.INVOICE.EXPORTER_DETAILS.ADDRESS.ORGANISATION]:
        defaultExporterAddress[Fields.ORGANISATION],
      [ShipmentEntity.INVOICE.EXPORTER_DETAILS.EORI_NUMBER]: eoriNumber,
      [ShipmentEntity.INVOICE.EXPORTER_DETAILS.VAT_NUMBER]: vatNumber,
      ...(visibleExporterFields.fdaRegistrationNumber && {
        [ShipmentEntity.INVOICE.EXPORTER_DETAILS.FDA_NUMBER]:
          fdaRegistrationNumber,
      }),
      ...(visibleExporterFields.destinationTaxId && {
        [ShipmentEntity.INVOICE.EXPORTER_DETAILS.DESTINATION_TAX_ID_REG_NO]:
          destinationTaxId,
      }),
      ...(visibleExporterFields.gstVatPaid && {
        [ShipmentEntity.INVOICE.EXPORTER_DETAILS.GST_VAT_PAID]: gstVatPaid,
      }),
      ...(isAvailableUkims && {
        [ShipmentEntity.INVOICE.EXPORTER_DETAILS.UKIMS_NUMBER]: ukimsNumber,
      }),
      [ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.COUNTRY_CODE]: get(
        shipment,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
          .COUNTRY_CODE
      ),
      [ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.POSTCODE]: get(
        shipment,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE
      ),
      [ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.ORGANISATION]: get(
        shipment,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
          .ORGANISATION
      ),
      [ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.TOWN]: get(
        shipment,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.TOWN
      ),
      [ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.COUNTY]: get(
        shipment,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTY
      ),
      [ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.STREET]: get(
        shipment,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.STREET
      ),
      [ShipmentEntity.INVOICE.IMPORTER_DETAILS.ADDRESS.LOCALITY]: get(
        shipment,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.LOCALITY
      ),
      [ShipmentEntity.INVOICE.IMPORTER_DETAILS.CONTACT_DETAILS.CONTACT_NAME]:
        get(
          shipment,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
            .CONTACT_NAME
        ),
      [ShipmentEntity.INVOICE.IMPORTER_DETAILS.CONTACT_DETAILS.TELEPHONE]: get(
        shipment,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
          .TELEPHONE
      ),
      [ShipmentEntity.INVOICE.IMPORTER_DETAILS.CONTACT_DETAILS.EMAIL]: get(
        shipment,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS
          .NOTIFICATION_DETAILS.EMAIL
      ),
    }),
    [INVOICE]: {
      [IMPORTER_DETAILS]: {
        isBusiness,
        ...(businessFields && {
          atRisk,
        }),
        ...(!hideImportsOptions &&
          exporterImporterDetails &&
          importerBusinessFields),
      },
    },
  });
};

export const setupDefaultValuesForInvalidInvoice = ({
  exportReasons,
  shipment,
  customer,
}) => {
  const invoice = getValue(shipment, "invoice", {});

  if (isEmpty(invoice)) {
    return shipment;
  }

  const isAvailableExportReason = exportReasons.some(
    item =>
      item.code === getValue(shipment, ShipmentEntity.INVOICE.EXPORT_REASON)
  );

  if (!isAvailableExportReason) {
    set(invoice, InvoiceEntity.EXPORT_REASON, null);
  }

  if (SettingsModels.isNewVersion(customer.shippingVersion)) {
    if (
      !isBoolean(
        getValue(invoice, InvoiceEntity.IMPORTER_DETAILS.IS_BUSINESS, false)
      )
    ) {
      set(invoice, InvoiceEntity.IMPORTER_DETAILS.IS_BUSINESS, false);
    }

    if (
      !isBoolean(
        getValue(invoice, InvoiceEntity.IMPORTER_DETAILS.AT_RISK, false)
      )
    ) {
      set(invoice, InvoiceEntity.IMPORTER_DETAILS.AT_RISK, false);
    }
  }

  return {
    ...shipment,
    invoice,
  };
};

const getDestinationTaxIdRegNo = (formValues, path) => {
  let result = getValue(formValues, path, "");

  if (typeof result === "string") {
    result = result.trim();
  }

  return result;
};

export const getExporterRequiredFields = ({
  formValues,
  paths,
  selectedCountry,
  selectedService,
  shippingVersion,
}) => {
  const destinationTaxIdRegNo = getDestinationTaxIdRegNo(
    formValues,
    paths[Fields.DESTINATION_TAX_ID_REG_NO]
  );
  const countryCode = get(formValues, paths[Fields.COUNTRY_CODE], "");
  const isPostcodeRequired = get(selectedCountry, "isPostcodeRequired", false);
  const isNewVersion = SettingsModels.isNewVersion(shippingVersion);

  return {
    [Fields.COUNTRY_CODE]: true,
    [Fields.POSTCODE]: !isEmpty(countryCode) && isPostcodeRequired,
    [Fields.STREET]: true,
    [Fields.TOWN]: true,
    [Fields.EORI_NUMBER]: true,
    [Fields.FDA_NUMBER]: ServiceModels.isFlagRequired(
      selectedService,
      "fdaRequired"
    ),
    [Fields.GST_VAT_PAID]:
      !isNewVersion &&
      !ServiceModels.isFlagNeedles(selectedService, "taxRequired") &&
      destinationTaxIdRegNo.length > 0,
    [Fields.DESTINATION_TAX_ID_REG_NO]:
      !isNewVersion &&
      ServiceModels.isFlagRequired(selectedService, "taxRequired"),
  };
};

export const getImporterBusinessFields = (
  customer,
  selectedAddressBook,
  formValues,
  selectedService
) => {
  if (SettingsModels.isNewVersion(customer.shippingVersion)) {
    const countryCode = getValue(
      formValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE
    );
    const postcode = getValue(
      formValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE
    );
    // @see: https://it.dpduk.live/version/customer-shipping/ni-protocol-shipping/sprint-2/diag_lfeXaN6GAqAAhXnF.html
    const defaultIsBusiness = getValue(
      selectedAddressBook,
      Fields.IS_BUSINESS,
      getValue(
        formValues,
        ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS,
        false
      )
    );
    const atRisk = getValue(
      selectedAddressBook,
      Fields.AT_RISK,
      getValue(
        formValues,
        ShipmentEntity.INVOICE.IMPORTER_DETAILS.AT_RISK,
        false
      )
    );
    const isNiShipment = AddressModels.isNiShipment(countryCode, postcode);
    const isVatNumberAllowed = isVisibleVatNumber(
      selectedService,
      customer,
      defaultIsBusiness
    );
    const isPidNumberAllowed = isVisiblePidNumber(
      selectedService,
      customer,
      defaultIsBusiness
    );
    const isUkimsAllowed = isVisibleUkims(
      {
        isBusiness: defaultIsBusiness,
        atRisk,
        countryCode,
        postcode,
      },
      customer.shippingVersion,
      selectedService
    );

    return defaultIsBusiness
      ? {
          ...(isVatNumberAllowed && {
            [Fields.VAT_NUMBER]: selectedAddressBook.vatNumber,
          }),
          [Fields.EORI_NUMBER]: selectedAddressBook.eoriNumber,
          [Fields.IS_BUSINESS]: defaultIsBusiness,
          ...(isPidNumberAllowed && { [Fields.PID_NUMBER]: null }),
          ...(isNiShipment && {
            [Fields.AT_RISK]: atRisk,
            ...(isUkimsAllowed && {
              [Fields.UKIMS_NUMBER]: selectedAddressBook.ukimsNumber,
            }),
          }),
        }
      : {
          ...(isVatNumberAllowed && { [Fields.VAT_NUMBER]: null }),
          [Fields.EORI_NUMBER]: null,
          ...(isNiShipment && {
            [Fields.AT_RISK]: false,
            ...(isUkimsAllowed && {
              [Fields.UKIMS_NUMBER]: null,
            }),
          }),
          [Fields.IS_BUSINESS]: defaultIsBusiness,
          ...(isPidNumberAllowed && {
            [Fields.PID_NUMBER]: selectedAddressBook?.pid,
          }),
        };
  }

  const isBusiness = !!(
    selectedAddressBook &&
    (selectedAddressBook.vatNumber || selectedAddressBook.eoriNumber)
  );

  return isBusiness
    ? {
        [Fields.VAT_NUMBER]: selectedAddressBook.vatNumber,
        [Fields.EORI_NUMBER]: selectedAddressBook.eoriNumber,
        [Fields.PID_NUMBER]: null,
        [Fields.IS_BUSINESS]: isBusiness,
      }
    : {
        [Fields.VAT_NUMBER]: null,
        [Fields.EORI_NUMBER]: null,
        [Fields.PID_NUMBER]: selectedAddressBook?.pid,
        [Fields.IS_BUSINESS]: isBusiness,
      };
};

export const addImporterAddressDetails = createShipmentValues => ({
  [ADDRESS]: {
    ...get(
      createShipmentValues,
      `${OUTBOUND_CONSIGNMENT}.${DELIVERY_DETAILS}.${ADDRESS}`
    ),
  },
  [CONTACT_DETAILS]: {
    ...get(
      createShipmentValues,
      `${OUTBOUND_CONSIGNMENT}.${DELIVERY_DETAILS}.${CONTACT_DETAILS}`
    ),
    [Fields.EMAIL]: get(
      createShipmentValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.NOTIFICATION_DETAILS
        .EMAIL
    ),
  },
});

export const addImporterBusinessFields = ({
  selectedService,
  selectedAddressBook,
  customer,
  createShipmentValues,
}) => {
  const hideOptions = ServiceModels.shouldHideBusinessConsumerOptions(
    selectedService,
    customer.shippingVersion
  );
  const { isBusiness, atRisk, ...fields } = getImporterBusinessFields(
    customer,
    selectedAddressBook,
    createShipmentValues,
    selectedService
  );
  const { businessFields, exporterImporterDetails } =
    NewShipmentModels.isShippingInformationVisible(customer, selectedService, {
      generateCustomsData: getValue(
        createShipmentValues,
        ShipmentEntity.GENERATE_CUSTOMS_DATA,
        false
      ),
      countryCode: getValue(
        createShipmentValues,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
          .COUNTRY_CODE,
        ""
      ),
      postCode: getValue(
        createShipmentValues,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
        ""
      ),
    });

  return {
    isBusiness,
    ...(businessFields && {
      atRisk,
    }),
    ...(!hideOptions && exporterImporterDetails && fields),
  };
};
