import { useMemo } from "react";

import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Field } from "redux-form";

import { FormControl } from "@dpdgroupuk/mydpd-ui";

import SearchAddress from "~/components/SearchAddress";
import {
  Fields,
  OUTBOUND_CONSIGNMENT_UPPERCASE,
  ShipmentEntity,
} from "~/constants/forms";
import * as S from "~/constants/strings";
import PostcodeFinderInput from "~/features/PostcodeFinder";
import { AddressModels, ShipmentModels } from "~/models";
import * as ShipmentTypes from "~/models/types/shipment";
import { joinStringsWithComma } from "~/utils/string";

import CountriesAutocomplete from "../Autocomplete/CountriesAutocomplete";
import styles from "./BaseAddressForm.module.scss";

const BaseAddressForm = ({
  onFieldEntry,
  onAddressBookSelectionChange,
  onPostcodeSelectionChange,
  countries,
  onSelectionChange,
  onFindClick,
  clearContactDetails,
  requiredFields,
  searchForm,
  addressbookType,
  shipmentSection,
  onCountryChange,
  disabledFields,
  allowedFields,
  pageConfig,
  selectedAddressBook,
  selectedCountry,
  onCountrySelectionChange,
  preferences,
  deliveryInformationOptions,
  form,
  setCsvOutboundPackageData,
  fetchPickupLocations,
  setDeliveryAddress,
}) => {
  const isCountryGB = useMemo(
    () => AddressModels.isCountryGB(selectedCountry?.countryKey),
    [selectedCountry]
  );
  const withAutocomplete = useMemo(
    () => isCountryGB && !ShipmentModels.isFailedForm(form)
  );
  const initialSearchAddressValues = useMemo(
    () => ShipmentModels.getSearchAddressInitialValues(preferences),
    [preferences]
  );

  return (
    <>
      {allowedFields.searchAddress[shipmentSection] && (
        <SearchAddress
          disabledFields={disabledFields}
          onFieldEntry={onFieldEntry}
          form={searchForm}
          addressbookType={addressbookType}
          onSelectionChange={values => {
            setCsvOutboundPackageData && setCsvOutboundPackageData();
            onAddressBookSelectionChange(values);
            onSelectionChange && onSelectionChange(values.address);
          }}
          initialSearchAddressValues={initialSearchAddressValues}
          pageConfig={pageConfig}
          selectedAddressBook={selectedAddressBook}
        />
      )}
      <Row>
        <Col>
          <Field
            component={CountriesAutocomplete}
            id={
              ShipmentEntity[shipmentSection].DELIVERY_DETAILS.ADDRESS
                .COUNTRY_CODE
            }
            name={
              ShipmentEntity[shipmentSection].DELIVERY_DETAILS.ADDRESS
                .COUNTRY_CODE
            }
            label={S.DESTINATION_COUNTRY}
            helperText={S.ENTER_OR_SELECT_DESTINATION_COUNTRY}
            onSelectionChange={onCountrySelectionChange}
            onCountryChange={onCountryChange}
            countries={countries}
            required={
              requiredFields[
                ShipmentEntity[shipmentSection].DELIVERY_DETAILS.ADDRESS
                  .COUNTRY_CODE
              ]
            }
            disabled={
              disabledFields[
                ShipmentEntity[shipmentSection].DELIVERY_DETAILS.ADDRESS
                  .COUNTRY_CODE
              ]
            }
            selectedCountry={selectedCountry}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={10}>
          <Field
            // readonly={isReadonly} TODO: Implement comented out properties
            component={PostcodeFinderInput}
            label={S.POSTAL_ZIP_CODE}
            name={
              ShipmentEntity[shipmentSection].DELIVERY_DETAILS.ADDRESS.POSTCODE
            }
            maxLength={8}
            helperText={S.DESTINATION}
            onChange={() => setDeliveryAddress(null)}
            onBlur={(e, prevValues, nextValues, path) => {
              fetchPickupLocations({
                postcode: nextValues,
              });
              onFieldEntry(e, prevValues, nextValues, path);
            }}
            id={Fields.POSTCODE}
            labelKey={option => option.postcode}
            optionLabelMapper={option =>
              joinStringsWithComma([
                option.organisation,
                option.property,
                option.street,
                option.town,
              ])
            }
            onSelectionChange={values => {
              fetchPickupLocations({
                postcode: values.postcode,
              });
              clearContactDetails();
              onPostcodeSelectionChange(values, shipmentSection);
              onSelectionChange && onSelectionChange(values);
            }}
            onFindClick={onFindClick}
            withAutocomplete={withAutocomplete}
            showFindButton={isCountryGB}
            buttonText={S.FIND_POSTCODE}
            pageSize={200}
            maxItems={200}
            disabled={
              disabledFields[
                ShipmentEntity[shipmentSection].DELIVERY_DETAILS.ADDRESS
                  .POSTCODE
              ]
            }
            required={
              requiredFields[
                ShipmentEntity[shipmentSection].DELIVERY_DETAILS.ADDRESS
                  .POSTCODE
              ]
            }
            selectedCountry={selectedCountry}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={S.ORGANISATION_OR_NAME}
            name={
              ShipmentEntity[shipmentSection].DELIVERY_DETAILS.ADDRESS
                .ORGANISATION
            }
            maxLength={35}
            helperText={S.MAX_35_CHARACTERS}
            onBlur={onFieldEntry}
            disabled={
              disabledFields[
                ShipmentEntity[shipmentSection].DELIVERY_DETAILS.ADDRESS
                  .ORGANISATION
              ]
            }
            required={
              requiredFields[
                ShipmentEntity[shipmentSection].DELIVERY_DETAILS.ADDRESS
                  .ORGANISATION
              ]
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={S.ADDRESS_LINE_1}
            name={
              ShipmentEntity[shipmentSection].DELIVERY_DETAILS.ADDRESS.STREET
            }
            maxLength={35}
            helperText={S.MAX_35_CHARACTERS}
            onBlur={onFieldEntry}
            required={
              requiredFields[
                ShipmentEntity[shipmentSection].DELIVERY_DETAILS.ADDRESS.STREET
              ]
            }
            disabled={
              disabledFields[
                ShipmentEntity[shipmentSection].DELIVERY_DETAILS.ADDRESS.STREET
              ]
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={S.ADDRESS_LINE_2}
            name={
              ShipmentEntity[shipmentSection].DELIVERY_DETAILS.ADDRESS.LOCALITY
            }
            maxLength={35}
            helperText={S.MAX_35_CHARACTERS}
            onBlur={onFieldEntry}
            disabled={
              disabledFields[
                ShipmentEntity[shipmentSection].DELIVERY_DETAILS.ADDRESS
                  .LOCALITY
              ]
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={S.CITY}
            name={ShipmentEntity[shipmentSection].DELIVERY_DETAILS.ADDRESS.TOWN}
            maxLength={35}
            helperText={S.MAX_35_CHARACTERS}
            onBlur={onFieldEntry}
            required={
              requiredFields[
                ShipmentEntity[shipmentSection].DELIVERY_DETAILS.ADDRESS.TOWN
              ]
            }
            disabled={
              disabledFields[
                ShipmentEntity[shipmentSection].DELIVERY_DETAILS.ADDRESS.TOWN
              ]
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={S.COUNTY_STATE}
            name={
              ShipmentEntity[shipmentSection].DELIVERY_DETAILS.ADDRESS.COUNTY
            }
            maxLength={35}
            helperText={S.MAX_35_CHARACTERS}
            onBlur={onFieldEntry}
            disabled={
              disabledFields[
                ShipmentEntity[shipmentSection].DELIVERY_DETAILS.ADDRESS.COUNTY
              ]
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {shipmentSection === OUTBOUND_CONSIGNMENT_UPPERCASE ? (
            <Field
              component={FormControl.InputAutocomplete}
              label={S.DELIVERY_INFORMATION}
              id={ShipmentEntity[shipmentSection].DELIVERY_INSTRUCTION}
              name={ShipmentEntity[shipmentSection].DELIVERY_INSTRUCTION}
              classes={{
                inputAutocomplete: styles.inputAutocomplete,
              }}
              maxLength={50}
              helperText={S.MAX_50_CHARACTERS}
              onBlur={onFieldEntry}
              disabled={
                disabledFields[
                  ShipmentEntity[shipmentSection].DELIVERY_INSTRUCTION
                ]
              }
              options={deliveryInformationOptions}
            />
          ) : (
            <Field
              component={FormControl.Input}
              label={S.DELIVERY_INFORMATION}
              classes={{
                inputAutocomplete: styles.inputAutocomplete,
              }}
              name={ShipmentEntity[shipmentSection].DELIVERY_INSTRUCTION}
              maxLength={50}
              helperText={S.MAX_50_CHARACTERS}
              onBlur={onFieldEntry}
              rows={4}
              disabled={
                disabledFields[
                  ShipmentEntity[shipmentSection].DELIVERY_INSTRUCTION
                ]
              }
            />
          )}
        </Col>
      </Row>
    </>
  );
};

BaseAddressForm.propTypes = {
  onAddressBookSelectionChange: PropTypes.func,
  onFieldEntry: PropTypes.func,
  onSelectionChange: PropTypes.func,
  onFindClick: PropTypes.func,
  onPostcodeSelectionChange: PropTypes.func,
  countries: PropTypes.array,
  clearContactDetails: PropTypes.func,
  searchForm: PropTypes.string,
  addressbookType: PropTypes.string,
  requiredFields: PropTypes.shape(ShipmentTypes.RequiredFields),
  shipmentSection: PropTypes.string.isRequired,
  onCountryChange: PropTypes.func,
  preferences: PropTypes.object,
  disabledFields: PropTypes.object,
  allowedFields: PropTypes.object,
  pageConfig: PropTypes.object,
  selectedAddressBook: PropTypes.object,
  selectedCountry: PropTypes.object,
  onCountrySelectionChange: PropTypes.func,
  setCsvOutboundPackageData: PropTypes.func,
  deliveryInformationOptions: PropTypes.array,
  form: PropTypes.string,
};

export default BaseAddressForm;
