import {
  Banner,
  Button,
  Card,
  Legend,
  Main,
  withOverlay,
  withPrompt,
  withSnackbar,
} from "@dpdgroupuk/mydpd-ui";
import { Col, Row } from "react-bootstrap";
import * as S from "~/constants/strings";
import ShipmentCard from "~/components/ShipmentCard";
import { formatMessage } from "~/utils/string";
import { compose, withHandlers } from "recompose";
import {
  PROJECT_NAME,
  withAppUserPreferences,
  withNotifier,
  withUrlResolver,
} from "@dpdgroupuk/mydpd-app";
import PropTypes from "prop-types";
import { getValue } from "~/utils/object";
import { connect } from "react-redux";
import { ToolsActions, ToolsSelectors } from "~/pages/Tools/redux";
import styles from "./ToolsMenu.module.scss";
import { useCallback, useMemo } from "react";
import useToggle from "~/hooks/useToggle";
import PrintManifestModal from "~/pages/ShipmentReview/components/PrintManifestModal/PrintManifestModal";
import PrintUnprintedModal from "~/pages/ShipmentReview/components/PrintUnprintedModal/PrintUnprintedModal";
import { isWeekend } from "~/utils/date";
import { ShipmentModels } from "~/models";
import { UmsSelectors } from "~/redux";
import withPrint from "~/hocs/withPrint";
import classNames from "classnames";
import { capitalize } from "lodash";
import EndDayModal from "~/pages/ShipmentReview/components/EndDayModal";
import ProgressModal from "~/pages/Tools/components/ProgressModal";

const ToolsMenu = ({
  authUser,
  defaultShipmentDate,
  urlResolver,
  filterDate,
  getDatesRange,
  onClickClear,
  eodUser,
  printUnprintedLabels,
  advancedCustomerConfiguration,
}) => {
  const href = useMemo(
    () => `${urlResolver.getUrl(PROJECT_NAME.MYDPD)}/account/shipping-defaults`,
    []
  );
  const printUnprintedToggle = useToggle();
  const printManifestToggle = useToggle();
  const endDayToggle = useToggle();
  const onPintUnprintedSubmit = useCallback(async values => {
    await printUnprintedLabels(values);
    printUnprintedToggle.switchOff();
  });
  const eodUserData = useMemo(
    () =>
      eodUser
        ? {
            text: S.RUN_END_DAY_RELEASE_FILE,
            onClick: endDayToggle.switchOn,
            buttonName: S.END_OF_DAY,
          }
        : {
            text: capitalize(S.PRINT_MANIFEST),
            onClick: printManifestToggle.switchOn,
            buttonName: S.PRINT_MANIFEST,
          },
    [eodUser]
  );

  return (
    <Main>
      <Main.Body>
        <Legend
          rightMessage={getValue(authUser, "user.username")}
          classes={{ container: "p-0" }}
        />
        <Card.Stack fluid>
          <ShipmentCard title={S.TOOLS}>
            <Row className="m-2">
              <Col
                className={classNames("col-9 pb-2 pl-0 mb-2", styles.column)}
              >
                {S.PRINT_ALL_UNPRINTED}
              </Col>
              <Col
                className={classNames("col-3 pb-2 pl-0 mb-2", styles.column)}
              >
                <Button onClick={printUnprintedToggle.switchOn}>
                  {S.PRINT}
                </Button>
              </Col>
              <Col
                className={classNames("col-9 pb-2 pl-0 mb-2", styles.column)}
              >
                {eodUserData.text}
              </Col>
              <Col
                className={classNames("col-3 pb-2 pl-0 mb-2", styles.column)}
              >
                <Button onClick={eodUserData.onClick}>
                  {eodUserData.buttonName}
                </Button>
              </Col>
              {!advancedCustomerConfiguration.extendedHoldPolicy && (
                <>
                  <Col
                    className={classNames(
                      "col-9 pb-2 pl-0 mb-2",
                      styles.column
                    )}
                  >
                    {S.CLEAR_UNPRINTED_SHIPMENTS}
                  </Col>
                  <Col
                    className={classNames(
                      "col-3 pb-2 pl-0 mb-2",
                      styles.column
                    )}
                  >
                    <Button onClick={onClickClear}>{S.CLEAR}</Button>
                  </Col>
                </>
              )}
              <Col
                className={classNames("col-9 pb-2 pl-0 mb-2", styles.column)}
              >
                {formatMessage(S.DEFAULT_SHIPMENT_DAY_$, defaultShipmentDate)}
              </Col>
              <Col
                className={classNames("col-3 pb-2 pl-0 mb-2", styles.column)}
              >
                <Button>
                  <a href={href} rel="noreferrer" className={styles.btnLik}>
                    {S.CHANGE_DEFAULT_SHIPMENT_DATE}
                  </a>
                </Button>
              </Col>
            </Row>
          </ShipmentCard>
        </Card.Stack>
        <PrintUnprintedModal
          open={printUnprintedToggle.value}
          onCancel={printUnprintedToggle.switchOff}
          onSubmit={onPintUnprintedSubmit}
          filterDate={filterDate}
          getDatesRange={getDatesRange}
        />
        <PrintManifestModal
          open={printManifestToggle.value}
          onCancel={printManifestToggle.switchOff}
        />
        <EndDayModal
          open={endDayToggle.value}
          onCancel={endDayToggle.switchOff}
        />
        <ProgressModal />
      </Main.Body>
    </Main>
  );
};

ToolsMenu.propTypes = {
  authUser: PropTypes.object,
  advancedCustomerConfiguration: PropTypes.object,
  defaultShipmentDate: PropTypes.string,
  filterDate: PropTypes.func,
  getDatesRange: PropTypes.func,
  printUnprintedLabels: PropTypes.func,
  onClickClear: PropTypes.func,
  eodUser: PropTypes.bool,
};

export default compose(
  Banner.withBanner,
  withAppUserPreferences,
  withUrlResolver,
  withSnackbar,
  withPrompt,
  withOverlay,
  withNotifier,
  withPrint,
  connect(
    state => ({
      defaultShipmentDate: ToolsSelectors.getDefaultDate(state),
      preferences: UmsSelectors.getPreferences(state),
      eodUser: UmsSelectors.isEodUser(state),
      advancedCustomerConfiguration:
        UmsSelectors.getAdvancedCustomerConfiguration(state),
    }),
    (dispatch, { prompt, notifier, overlay }) => ({
      deleteUnprintedShipments: overlay.showWhile(
        notifier.runAsync(
          async () => {
            const {
              data: { deleted },
            } = await dispatch(ToolsActions.deleteUnprintedShipments());

            const infoData = deleted
              ? {
                  header: S.SUCCESS,
                  message: formatMessage(
                    S.REMOVED_$_UNPRINTED_SHIPMENTS,
                    deleted
                  ),
                }
              : {
                  header: S.NO_PRINTED_SHIPMENTS,
                  message: S.HAVE_NOT_SHIPMENTS,
                };

            return prompt.showInfo(infoData);
          },
          { entityName: S.SHIPMENT_ENTITY }
        )
      ),
    })
  ),
  withHandlers({
    onClickClear:
      ({ prompt, deleteUnprintedShipments }) =>
      async () => {
        const deleteConfirmation = new Promise(resolve => {
          prompt.showConfirmationDelete({
            header: S.CLEAR_UNPRINTED,
            confirmButtonText: S.CONFIRM,
            closeButtonText: S.CANCEL,
            onConfirm: resolve,
          });
        });

        await deleteConfirmation;
        await deleteUnprintedShipments();
      },
    filterDate:
      ({ preferences }) =>
      date =>
        getValue(preferences, "shippingDefaults.enableWeekend", false) ||
        !isWeekend(date),
    getDatesRange:
      ({ preferences }) =>
      () =>
        // NOTE: always without advancedCustomerConfiguration! (allow select only 5-7 days)
        ShipmentModels.getAvailableDateRange(
          getValue(preferences, "shippingDefaults.enableWeekend", false)
        ),
  })
)(ToolsMenu);
