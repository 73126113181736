import {
  cloneDeep,
  get,
  has,
  includes,
  isArray,
  isBoolean,
  isEmpty,
  isNil,
  isNull,
  omit,
  pick,
  set,
  values,
} from "lodash";
import { reduce } from "lodash/collection";

import * as StringUtil from "@dpdgroupuk/mydpd-app/lib/utils/string";
import {
  INVOICE_REQUIRED,
  REQUIRED_TYPE,
  SHIPMENT_TYPES,
} from "@dpdgroupuk/mydpd-enums";

import * as DATE_FORMAT from "~/constants/dateFormats";
import {
  AddExtraLabelEntity,
  ADDRESS,
  AddressBookEntity,
  COLLECTION_DETAILS,
  CONTACT_DETAILS,
  CREATE_SHIPMENT_FORM,
  DELIVERY_DETAILS,
  EDIT_FAILED_SHIPMENT_FORM,
  EXPORTER_DETAILS,
  Fields,
  IMPORTER_DETAILS,
  INBOUND_CONSIGNMENT,
  INBOUND_CONSIGNMENT_UPPERCASE,
  INVOICE,
  OUTBOUND_CONSIGNMENT,
  ProductDescriptionsEntity,
  ProductEntity,
  SEARCH_TYPE,
  ShipmentEntity,
} from "~/constants/forms";
import { SHIPPING_REF_MAX_LENGTH } from "~/constants/numbers";
import * as S from "~/constants/strings";
import { SHIP_TO_SHOP } from "~/constants/services";
import { NewShipmentModels } from "../newShipmentVersion";
import { AddressModels } from "~/models/address";
import { CardModels } from "~/models/card";
import { CustomsModels } from "~/models/customs";
import { InvoiceModels } from "~/models/invoice";
import { LiabilityModels } from "~/models/liability";
import { ServiceModels } from "~/models/service";
import { formatISODate, isSunday, isWeekend } from "~/utils/date";
import createValidator from "~/utils/joiReduxForm";
import { roundToDecimal } from "~/utils/number";
import {
  flatPathToObject,
  flattenEntityRoutes,
  getValue,
  mapArrayToObjectByKey,
  omitNilValues,
  replaceEmptyStringToUndefined,
  toUppercaseValues,
} from "~/utils/object";
import { stringifyQuery } from "~/utils/query";
import { mapErrorsToReduxForm } from "~/utils/reduxForm";

import postcodeValidation from "../validators/additionalPostcodeValidation";
import { addressBookSchema } from "../validators/addressBookSchema";
import moment from "moment-timezone";
import { SettingsModels } from "~/models";

export const shipmentTypes = {
  noType: {
    value: SHIPMENT_TYPES.NO_TYPE.toString(),
    label: "No",
  },
  swapIt: {
    value: SHIPMENT_TYPES.SWAP_IT.toString(),
    label: "Swap it",
  },
  reverseIt: {
    value: SHIPMENT_TYPES.REVERSE_IT.toString(),
    label: "Reverse it",
  },
};

export const setNetworkShipmentType = ({ shipment, network }) => {
  let shipmentType = SHIPMENT_TYPES.NO_TYPE.toString();

  if (!isEmpty(shipment.inboundConsignment) && !isEmpty(network)) {
    if (network?.product?.productCode === "1^12") {
      shipmentType = SHIPMENT_TYPES.SWAP_IT;
    } else {
      shipmentType = SHIPMENT_TYPES.REVERSE_IT;
    }
  }

  return {
    ...shipment,
    shipmentType,
  };
};

export const getShipmentTypeLabel = shipmentTypeValue => {
  const shipmentType = Object.values(shipmentTypes).find(
    ({ value }) => value === shipmentTypeValue
  );
  return shipmentType ? shipmentType.label : "";
};

export const isSwapItShipmentType = shipmentType =>
  parseInt(shipmentType) === SHIPMENT_TYPES.SWAP_IT;

export const isReverseItShipmentType = shipmentType =>
  parseInt(shipmentType) === SHIPMENT_TYPES.REVERSE_IT;

export const isNoneShipmentType = shipmentType =>
  parseInt(shipmentType) === SHIPMENT_TYPES.NO_TYPE;

export const isSwapItOrReverseItShipmentType = shipmentType =>
  isSwapItShipmentType(shipmentType) || isReverseItShipmentType(shipmentType);

export const getWeekendNextMonday = (date, enableWeekend) =>
  !enableWeekend && isWeekend(date)
    ? moment(date, DATE_FORMAT.DAY_DEFAULT_DATE_FORMAT)
        .add(isSunday(date) ? 1 : 2, "days") // Next Monday
        .format(DATE_FORMAT.DAY_DEFAULT_DATE_FORMAT)
    : moment(date, DATE_FORMAT.DAY_DEFAULT_DATE_FORMAT).format(
        DATE_FORMAT.DAY_DEFAULT_DATE_FORMAT
      );

export const getMinShipmentDate = enableWeekend =>
  getWeekendNextMonday(moment().toDate(), enableWeekend);

export const getPreferredShipmentDate = (shippingDefaults, date) => {
  let minDate = getMinShipmentDate(shippingDefaults.enableWeekend);

  if (
    !date &&
    shippingDefaults.defaultShipmentDate &&
    moment(
      shippingDefaults.defaultShipmentDate,
      DATE_FORMAT.ISO_DATE_FORMAT
    ).isSameOrAfter(moment(minDate, DATE_FORMAT.DAY_DEFAULT_DATE_FORMAT))
  ) {
    minDate = moment(
      shippingDefaults.defaultShipmentDate,
      DATE_FORMAT.ISO_DATE_FORMAT
    ).format(DATE_FORMAT.DAY_DEFAULT_DATE_FORMAT);
  }

  return moment(date, DATE_FORMAT.DAY_DEFAULT_DATE_FORMAT).isSameOrAfter(
    moment(minDate, DATE_FORMAT.DAY_DEFAULT_DATE_FORMAT)
  )
    ? moment(date, DATE_FORMAT.DAY_DEFAULT_DATE_FORMAT).format(
        DATE_FORMAT.DAY_DEFAULT_DATE_FORMAT
      )
    : minDate;
};

// @see: https://it.dpduk.live/version/customer-shipping/mydpd-last-5/sprint-9/diag_oI6csR6C48kIGFaW.html
export const getAvailableDateRange = (enableWeekend, forwardDateOver7Days) => {
  const minDate = moment(
    getMinShipmentDate(enableWeekend),
    DATE_FORMAT.DAY_DEFAULT_DATE_FORMAT
  );
  const currentDate = new Date();
  const count = forwardDateOver7Days
    ? 30
    : isWeekend(currentDate) && !enableWeekend
      ? 5
      : 7;

  return {
    minDate: minDate.toDate(),
    maxDate: minDate.add(count, "d").toDate(),
  };
};

// @see:https://it.dpduk.live/it/diagram/diag_3UNAbiGGAqCIZHup.html?id=1668700474296
export const isRequiredForService = selectedService =>
  [REQUIRED_TYPE.MANDATORY, REQUIRED_TYPE.OPTIONAL].includes(
    get(selectedService, "prodRequired")
  ) && ServiceModels.isFlagRequired(selectedService, "commodityRequired");

// @see: https://it.dpduk.live/it/diagram/diag_7NG2I16GAqAADLol.html
export const isAdditionalCommCodeCheckRequired = (
  selectedCountry,
  selectedService,
  createShipmentValues,
  customer
) => {
  if (!selectedService) return false;

  const serviceCode = parseInt(ServiceModels.getServiceCode(selectedService));
  const networkCode = parseInt(ServiceModels.getNetworkCode(selectedService));
  const countryKey = get(selectedCountry, "countryKey");
  const isNiShipment = AddressModels.isNiShipment(
    getValue(
      createShipmentValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
      ""
    ),
    getValue(
      createShipmentValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
      ""
    )
  );
  const isNewVersion = SettingsModels.isNewVersion(customer.shippingVersion);

  return (
    (isNewVersion && isNiShipment) ||
    serviceCode === 9 ||
    (countryKey === S.IE &&
      (networkCode === 11 ||
        ServiceModels.isFlagRequired(selectedService, "commodityRequired")))
  );
};

export const getSearchAddressInitialValues = preferences => {
  const userType = get(
    preferences,
    "shippingDefaults.shipmentAddressSearchType",
    1
  );
  return {
    searchCriteriaField: SEARCH_TYPE[userType],
    searchCriteriaValue: "",
  };
};

export const isValidDeliveryContactSection = errors =>
  isEmpty(
    pick(errors, [
      ...values(
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
      ),
      ...values(
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS
          .NOTIFICATION_DETAILS
      ),
    ])
  );

export const isValidDeliveryDetailsSection = errors =>
  isEmpty(
    pick(errors, [
      ...values(ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS),
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_INSTRUCTION,
    ])
  );

export const isValidShipmentDetailsSection = ({
  errors = {},
  submitErrors = {},
  asyncErrors = {},
  isProductDescriptionAllowed = false,
}) =>
  isValidDeliveryDetailsSection(errors) &&
  isValidDeliveryContactSection(errors) &&
  isValidPackageDetailsSection(
    errors,
    submitErrors,
    asyncErrors,
    isProductDescriptionAllowed
  );

export const isValidReturnShipmentDetailsSection = (errors = {}) =>
  isValidReturnDetailsSection(errors) &&
  isValidReturnPackageDetailsSection(errors);

export const isIrelandDirect = (countryCode, product) =>
  countryCode === S.IE && ServiceModels.removeBusinessUnit(product) === "18";

export const isValidServiceDetailsFields = errors =>
  isEmpty(
    pick(errors, [
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS,
    ])
  );

export const isServiceDropdownDisabled = ({
  syncErrors,
  submitErrors,
  services,
  productCode,
  selectedCountry,
}) =>
  services.length === 0 ||
  isIrelandDirect(selectedCountry.countryKey, productCode) ||
  !isValidServiceDetailsFields(syncErrors) ||
  submitErrors.outboundConsignment;

export const getCurrencyLabel = currency =>
  S.CURRENCY_TO_SYMBOL[currency] || currency;

export const isValidPackageDetailsSection = (
  errors = {},
  submitErrors = {},
  asyncErrors = {},
  isProductDescriptionAllowed = false
) =>
  isEmpty(
    pick({ ...errors, ...submitErrors, ...asyncErrors }, [
      ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE,
      ShipmentEntity.SHIPMENT_DATE,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_2,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_3,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.CUSTOMS_VALUE,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.GST_VAT_PAID,
      ...(isProductDescriptionAllowed
        ? [ShipmentEntity.OUTBOUND_CONSIGNMENT.PARCELS]
        : []),
    ])
  );

export const isProductDropdownDisabled = (syncErrors, submitErrors, products) =>
  products.length === 0 ||
  !isValidServiceDetailsFields(syncErrors) ||
  submitErrors.outboundConsignment;

export const getRoundedHelperText = (
  selectedCountry = {},
  networkCode = ""
) => {
  if (selectedCountry.countryKey === S.IE && networkCode === "58") {
    return S.ROUNDED_UP;
  }

  return AddressModels.isCountryDomestic(selectedCountry.countryKey)
    ? S.ROUND_UP_TO_THE_NEAREST_WHOLE_NUMBER
    : S.ROUNDED_UP;
};

export const isValidReturnDetailsSection = errors =>
  isEmpty(
    pick(errors, [
      ...values(ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS),
      ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_INSTRUCTIONS,
    ])
  );

export const isValidReturnPackageDetailsSection = errors =>
  isEmpty(
    pick(errors, [
      ShipmentEntity.INBOUND_CONSIGNMENT.NUMBER_OF_PARCELS,
      ShipmentEntity.INBOUND_CONSIGNMENT.TOTAL_WEIGHT,
      ShipmentEntity.INBOUND_CONSIGNMENT.PRODUCT,
      ShipmentEntity.INBOUND_CONSIGNMENT.NETWORK_CODE,
      ShipmentEntity.INBOUND_CONSIGNMENT.SHIPPING_REF_1,
      ShipmentEntity.INBOUND_CONSIGNMENT.SHIPPING_REF_2,
      ShipmentEntity.INBOUND_CONSIGNMENT.SHIPPING_REF_3,
    ])
  );

export const getSearchCriteria = (searchCriteriaField, searchCriteriaValue) => {
  const value = searchCriteriaValue.trim();

  if (value && !searchCriteriaField) {
    searchCriteriaField = Fields.SHORT_NAME;
  }

  return searchCriteriaField && value
    ? stringifyQuery({ [searchCriteriaField]: value }, true)
    : null;
};

export const getPackageDetails = ({
  shipmentDate,
  // omit deliveryDetails from packageDetails
  // eslint-disable-next-line no-unused-vars
  [OUTBOUND_CONSIGNMENT]: { deliveryDetails, ...rest } = {},
} = {}) => ({ ...rest, shipmentDate });

export const getReturnPackageDetails = ({
  shipmentDate,
  // omit deliveryDetails from packageDetails
  // eslint-disable-next-line no-unused-vars
  [INBOUND_CONSIGNMENT]: { deliveryDetails, ...rest } = {},
} = {}) => ({ ...rest, shipmentDate });

export const getDeliveryDetails = formValues =>
  get(formValues, `${OUTBOUND_CONSIGNMENT}.${DELIVERY_DETAILS}`, {});

export const getReturnDetails = formValues =>
  get(formValues, `${INBOUND_CONSIGNMENT}.${DELIVERY_DETAILS}`, {});

export const getInitialInvoiceExporterDetails = (
  formValues,
  shippingVersion
) =>
  SettingsModels.isNewVersion(shippingVersion)
    ? NewShipmentModels.getInitialInvoiceExporterDetails(formValues)
    : get(formValues, `${INVOICE}.${EXPORTER_DETAILS}`, {});

export const getInvoiceImporterDetails = formValues =>
  get(formValues, `${INVOICE}.${IMPORTER_DETAILS}`, {});

export const getInvoiceExporterDetails = formValues =>
  get(formValues, `${INVOICE}.${EXPORTER_DETAILS}`, {});

// @see:https://it.dpduk.live/it/diagram/diag_JGINhl6GAqAADAAt.html
export const getGenerateCustomsDataMessage = (
  service,
  generateCustomsData,
  { prefsInvoiceSettings: { createInvoice } }
) => {
  if (
    generateCustomsData ||
    get(service, "proforma", REQUIRED_TYPE.NEEDLESS) === REQUIRED_TYPE.NEEDLESS
  ) {
    return;
  }

  if (
    isNull(generateCustomsData) &&
    createInvoice === INVOICE_REQUIRED.SELECT_EACH_TIME
  ) {
    return S.CUSTOMS_DATA_IS_REQUIRED;
  }

  if (isBoolean(generateCustomsData) && !generateCustomsData) {
    return S.CUSTOMS_INVOICE_IS_REQUIRED;
  }

  if (ServiceModels.isFlagOptional(service, "proforma")) {
    return S.CUSTOMS_DATA_IS_REQUIRED;
  }
};

export const getDirectShipmentMessage = (service, countryCode) => {
  if (
    !isEmpty(service) &&
    (isIrelandDirect(countryCode, service.product.productKey) ||
      (ServiceModels.isFlagNeedles(service, "proforma") &&
        ServiceModels.isFlagRequired(service, "prodRequired") &&
        ServiceModels.removeBusinessUnit(service.networkKey) !== "20"))
  ) {
    return S.PLEASE_REMEMBER_TO_PLACE_ALL_PACKAGES;
  }
};

export const isValidInvoiceSection = errors => isEmpty(errors.invoice);

export const isOnlyInvoiceImporterSectionInvalid = errors =>
  isValidInvoiceSection(omit(errors, ["invoice.importerDetails"])) &&
  !isEmpty(errors?.invoice?.importerDetails);

export const isShippingCost = field =>
  field === ShipmentEntity.INVOICE.SHIPPING_COST;

export const isProductUnitValue = field => field === ProductEntity.UNIT_VALUE;

export const isProductUnitWeight = field => field === ProductEntity.UNIT_WEIGHT;

export const mapBoundQueryErrorsToReduxForm = (error, fieldPaths) => {
  const errors = {};
  error.errors &&
    error.errors.forEach(({ message, fieldName, fieldPath }) => {
      fieldName &&
        message &&
        fieldPaths[fieldPath] &&
        set(errors, [fieldPaths[fieldPath]], message);
    });

  return errors;
};

export const isEmptyConsignmentErrors = (errors, fields) =>
  isEmpty(pick(errors, fields));

export const getDefaultTotalWeight = (
  profile,
  preferences,
  countryCode,
  networkCode
) => {
  const userTotalWeight = AddressModels.isCountryDomestic(countryCode)
    ? Math.max(get(preferences, "shippingDefaults.domestic.domWeight", 0), 1)
    : Math.max(
        get(preferences, "shippingDefaults.international.intWeight", 0),
        1
      );

  return ServiceModels.roundTotalWeight(
    String(
      (!profile?.useMyDpdAccountSettings && profile?.defaultWeight) ||
        userTotalWeight
    ),
    countryCode,
    networkCode,
    true
  );
};

export const getDefaultNumberOfParcels = (preferences, countryCode) =>
  String(
    AddressModels.isCountryDomestic(countryCode)
      ? Math.max(
          get(preferences, "shippingDefaults.domestic.domNumItems", 0),
          1
        )
      : Math.max(
          get(preferences, "shippingDefaults.international.intNumItems", 0),
          1
        )
  );

export const updateReturnTotalWeight = (values, totalWeight) => {
  const newValues = cloneDeep(values);
  set(newValues, "inboundConsignment.totalWeight", totalWeight);

  return newValues;
};

export const updateTotalWeight = (values, totalWeight) => {
  const newValues = cloneDeep(values);

  if (newValues.outboundConsignment) {
    newValues.outboundConsignment.totalWeight = totalWeight;
  }

  if (
    newValues.invoice &&
    // NOTE: atRisk is available only for newVersion
    !isBoolean(newValues?.invoice?.importerDetails?.atRisk)
  ) {
    newValues.invoice.totalWeight = totalWeight;
  }

  return isSwapItShipmentType(newValues.shipmentType)
    ? updateReturnTotalWeight(newValues, totalWeight)
    : newValues;
};

export const updateTotalExtraWeight = (values, totalWeightOfNewParcels) => {
  const newValues = cloneDeep(values);

  set(
    newValues,
    AddExtraLabelEntity.TOTAL_NEW_PARCELS_WEIGHT,
    totalWeightOfNewParcels
  );

  return newValues;
};

export const isOutboundCountry = field =>
  field ===
  ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE;

export const isInboundCountry = field =>
  field ===
  ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE;

export const getOutboundTotalWeight = values =>
  get(values, ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT, "");

export const getInboundTotalWeight = values =>
  get(values, ShipmentEntity.INBOUND_CONSIGNMENT.TOTAL_WEIGHT, "");

export const isOutboundTotalWeight = field =>
  field === ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT;

export const isInboundTotalWeight = field =>
  field === ShipmentEntity.INBOUND_CONSIGNMENT.TOTAL_WEIGHT;

export const isLabelExtraWeight = field =>
  field === AddExtraLabelEntity.TOTAL_NEW_PARCELS_WEIGHT;

export const isOutboundNumberOfParcels = field =>
  field === ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS;

export const getDefaultShippingRef1 = (preferences, countryCode) =>
  AddressModels.isCountryDomestic(countryCode)
    ? getValue(
        preferences,
        "shippingDefaults.domestic.domReferenceText",
        ""
      ).toUpperCase()
    : getValue(
        preferences,
        "shippingDefaults.international.intReferenceText",
        ""
      ).toUpperCase();

export const getDefaultUniqueShippingRef1 = (
  countryCode,
  uniqueShippingRef1 = "",
  { preferences, shippingSettings }
) => {
  const defaultShippingRef1 = getDefaultShippingRef1(preferences, countryCode);
  if (shippingSettings.allocateSenders && !!uniqueShippingRef1) {
    return defaultShippingRef1 + uniqueShippingRef1;
  }
  return defaultShippingRef1;
};

// @see https://it.dpduk.live/it/diagram/diag_fqzw3B6C48kIGOfl.html?id=1623140408534
export const getCollectionDetails = (
  formValues,
  profile,
  customerAddress,
  customer,
  countries
) => {
  const collectionDetails = get(
    formValues,
    "outboundConsignment.collectionDetails"
  );

  if (collectionDetails && !isEmpty(collectionDetails)) {
    return collectionDetails;
  }

  const address = AddressModels.getOriginUserCollectionAddress({
    customer,
    profile,
    customerAddress,
    countries,
  });

  return {
    address: omitNilValues(AddressModels.truncateAddress(address)),
    // NOTE: contactDetails are unused (can be from profile or customer)
  };
};

const parseInvoiceValue = value => {
  switch (value) {
    case "G":
      return true;
    case "C":
      return false;
    case "S":
      return null;
    default:
      return null;
  }
};

const getCustomsDataPreSelectedValue = (profile, preferences) => {
  const valueFromProfile = parseInvoiceValue(
    get(profile, "invExportInvoiceRequired")
  );
  const valueFromShippingDefaults = parseInvoiceValue(
    get(preferences, "prefsInvoiceSettings.createInvoice")
  );

  return valueFromProfile === null
    ? valueFromShippingDefaults
    : valueFromProfile;
};

// @see https://it.dpduk.live/version/customer-shipping/sprint-2.6/diag_F_RVHF6GAqAADOKk.html?id=1650536806775
const getOldVersionGenerateCustomsDataSettings = (
  service,
  preferences,
  profile
) => {
  switch (get(service, "proforma")) {
    case REQUIRED_TYPE.MANDATORY:
      return {
        dropdownVisible: true,
        dropdownDisabled: true,
        preSelectedValue: true,
      };
    case REQUIRED_TYPE.OPTIONAL: {
      return {
        dropdownVisible: true,
        preSelectedValue: getCustomsDataPreSelectedValue(profile, preferences),
      };
    }
    default:
      return {
        dropdownVisible: false,
        preSelectedValue: null,
      };
  }
};

//@see:https://it.dpduk.live/version/customer-shipping/ni-protocol-shipping/sprint-2/diag_1jl2ul6GAqAADNn6.html
const getNewVersionGenerateCustomsDataSettings = (
  service,
  preferences,
  profile,
  countryCode
) => {
  const isDomestic = AddressModels.isCountryDomestic(countryCode);

  switch (get(service, "proforma")) {
    case REQUIRED_TYPE.MANDATORY:
      return {
        dropdownVisible: !isDomestic,
        preSelectedValue: true,
      };
    case REQUIRED_TYPE.OPTIONAL: {
      return {
        dropdownVisible: true,
        preSelectedValue: getCustomsDataPreSelectedValue(profile, preferences),
      };
    }
    case REQUIRED_TYPE.NEEDLESS: {
      return {
        dropdownVisible: !isDomestic,
        preSelectedValue: false,
      };
    }
    default:
      return {
        dropdownVisible: !isDomestic,
        preSelectedValue: null,
      };
  }
};

export const getGenerateCustomsDataSettings = (
  service,
  preferences,
  profile,
  formValues,
  customer
) =>
  SettingsModels.isNewVersion(customer.shippingVersion)
    ? getNewVersionGenerateCustomsDataSettings(
        service,
        preferences,
        profile,
        getValue(
          formValues,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
            .COUNTRY_CODE,
          ""
        )
      )
    : getOldVersionGenerateCustomsDataSettings(service, preferences, profile);

const getReference6 = (
  selectedAddressBook,
  { searchCriteriaField, searchCriteriaValue }
) => {
  const shortName = getValue(selectedAddressBook, Fields.SHORT_NAME, "");

  switch (searchCriteriaField) {
    case Fields.SHORT_NAME:
      return searchCriteriaValue === shortName ? shortName : "";

    case Fields.ORGANISATION:
      return searchCriteriaValue ===
        getValue(
          selectedAddressBook,
          AddressBookEntity.ADDRESS.ORGANISATION,
          ""
        )
        ? shortName
        : "";

    case Fields.POSTCODE:
      return searchCriteriaValue ===
        getValue(selectedAddressBook, AddressBookEntity.ADDRESS.POSTCODE, "")
        ? shortName
        : "";

    default:
      return "";
  }
};

export const getQueryShipment = (
  formValues,
  selectedAddressBook,
  selectedReturnAddressBook,
  user,
  searchDetails
) => {
  const domesticShipment = omit(formValues, [
    Fields.CUSTOMER_REF_1,
    Fields.INBOUND_CONSIGNMENT,
    ShipmentEntity.SHIPMENT_TYPE,
    ShipmentEntity.INVOICE.TOTAL_WEIGHT,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.DISPLAY_WEIGHT,
    ShipmentEntity.INVOICE.NUMBER_OF_PARCELS,
    isEmpty(selectedAddressBook) &&
      `${Fields.OUTBOUND_CONSIGNMENT}.${Fields.ADDRESS_BOOK_ID}`,
    isEmpty(selectedReturnAddressBook) &&
      `${Fields.INBOUND_CONSIGNMENT}.${Fields.ADDRESS_BOOK_ID}`,
    !SHIP_TO_SHOP.includes(
      get(formValues, ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE)
    ) && "outboundConsignment.pickupDetails",
    "activeField",
  ]);

  if (
    get(
      domesticShipment,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE
    ) === S.GB
  ) {
    const outboundConsignmentPostcode = getValue(
      domesticShipment,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
      ""
    );
    set(
      domesticShipment,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
      StringUtil.addSpaceToUKPostcode(outboundConsignmentPostcode)
    );
  }

  set(
    domesticShipment,
    ShipmentEntity.SHIPMENT_DATE,
    formatISODate(domesticShipment.shipmentDate)
  );

  if (getValue(formValues, Fields.GENERATE_CUSTOMS_DATA)) {
    set(
      domesticShipment,
      ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS,
      get(formValues, ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS)
    );
  }

  // @see https://it.dpduk.live/version/customer-shipping/sprint-2.7/diag_AplIggGGAqCIajUS.html?id=1652793322146
  if (user.includeCustomerCode) {
    set(
      domesticShipment,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_6,
      getReference6(selectedAddressBook, searchDetails).slice(
        0,
        SHIPPING_REF_MAX_LENGTH
      )
    );
  }

  if (isSwapItOrReverseItShipmentType(formValues.shipmentType)) {
    if (
      get(
        domesticShipment,
        ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE
      ) === S.GB
    ) {
      const inboundConsignmentPostcode = getValue(
        domesticShipment,
        ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
        ""
      );
      set(
        domesticShipment,
        ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
        StringUtil.addSpaceToUKPostcode(inboundConsignmentPostcode)
      );
    }

    return {
      ...domesticShipment,
      inboundConsignment: {
        liability: domesticShipment.outboundConsignment.liability,
        ...omit(formValues.inboundConsignment, [
          isReverseItShipmentType(formValues[ShipmentEntity.SHIPMENT_TYPE])
            ? Fields.DELIVERY_DESCRIPTION
            : "",
        ]),
        collectionDetails: pick(
          domesticShipment.outboundConsignment.deliveryDetails,
          ["address", "contactDetails"]
        ),
      },
    };
  }

  return domesticShipment;
};

export const getCollectionDetailsForInbound = formValues => ({
  address: omitNilValues(
    get(
      formValues,
      `${OUTBOUND_CONSIGNMENT}.${DELIVERY_DETAILS}.${ADDRESS}`,
      {}
    )
  ),
  contactDetails: omitNilValues(
    get(
      formValues,
      `${OUTBOUND_CONSIGNMENT}.${DELIVERY_DETAILS}.${CONTACT_DETAILS}`,
      {}
    )
  ),
});

export const getQueryForServices = (
  values,
  servicesRelatedFields,
  collectionDetails,
  profileCode,
  formSection,
  customer
) => {
  const businessFields = {};
  const { shipmentType, ...fieldValues } = pick(values, servicesRelatedFields);
  const {
    totalWeight,
    numberOfParcels,
    deliveryDetails = {},
  } = replaceEmptyStringToUndefined(get(fieldValues, formSection));

  if (
    SettingsModels.isNewVersion(customer.shippingVersion) &&
    formSection === OUTBOUND_CONSIGNMENT &&
    AddressModels.isNiShipment(
      getValue(
        values,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
          .COUNTRY_CODE,
        ""
      ),
      getValue(
        values,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
        ""
      )
    )
  ) {
    businessFields.isBusiness = getValue(
      values,
      ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS,
      false
    );
    businessFields.atRisk = getValue(
      values,
      ShipmentEntity.INVOICE.IMPORTER_DETAILS.AT_RISK,
      false
    );
  }

  return {
    deliveryDetails: AddressModels.transformServiceAddress(
      deliveryDetails?.address
    ),
    collectionDetails: AddressModels.transformServiceAddress(
      collectionDetails?.address
    ),
    profileCode,
    shipmentType,
    numberOfParcels,
    totalWeight: totalWeight?.toString(),
    ...businessFields,
  };
};

export const isValidReturnServiceDetailsFields = errors =>
  isEmpty(
    pick(errors, [
      ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
      ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
      ShipmentEntity.INBOUND_CONSIGNMENT.TOTAL_WEIGHT,
      ShipmentEntity.INBOUND_CONSIGNMENT.NUMBER_OF_PARCELS,
    ])
  );

const blackList = [
  "outboundConsignment.collectionDetails",
  "invoice.exporterDetails",
  "invoice.importerDetails",
];

const replaceList = [
  {
    key: "inboundConsignment.collectionDetails",
    value: "outboundConsignment.deliveryDetails",
  },
];

const filterErrors = errors =>
  errors.filter(
    e => e.fieldPath && !blackList.find(path => e.fieldPath.startsWith(path))
  );

const changeErrorsFieldPath = errors =>
  errors.map(error => {
    const mapObj = replaceList.find(({ key }) =>
      error.fieldPath.startsWith(key)
    );

    return {
      ...error,
      fieldPath: mapObj
        ? error.fieldPath.replace(mapObj.key, mapObj.value)
        : error.fieldPath,
    };
  });

export const getShipmentFormErrors = errors => {
  if (isArray(errors)) {
    const filtered = filterErrors(errors);
    const changed = changeErrorsFieldPath(filtered);
    return changed.length
      ? flatPathToObject(mapErrorsToReduxForm(changed))
      : null;
  }

  return errors;
};

export const getNetworkQueryFromShipment = (
  shipment,
  profileCode,
  formSection,
  shipmentCollectionDetails
) => {
  const {
    shipmentType,
    [formSection]: {
      deliveryDetails,
      collectionDetails,
      numberOfParcels,
      totalWeight,
    },
  } = pick(shipment, [
    formSection,
    ShipmentEntity.PROFILE_CODE,
    ShipmentEntity.SHIPMENT_TYPE,
  ]);

  return {
    deliveryDetails: AddressModels.transformServiceAddress(
      omitNilValues(deliveryDetails?.address)
    ),
    collectionDetails: AddressModels.transformServiceAddress(
      // NOTE: there isn't collectionDetails for initial query in the Copy shipment page
      omitNilValues(
        collectionDetails?.address || shipmentCollectionDetails?.address
      )
    ),
    profileCode,
    shipmentType: shipmentType.toString(),
    // NOTE: blur this field convert value to string
    // as result serviceQuery and newServiceQuery will be different and launch redundant fetching services
    numberOfParcels: numberOfParcels.toString(),
    totalWeight: totalWeight.toString(),
  };
};

export const setupInitialGenerateCustomsData = data => {
  const { shipment, selectedOutboundNetwork, preferences, profile, customer } =
    data;

  const { dropdownVisible, dropdownDisabled, preSelectedValue } =
    getGenerateCustomsDataSettings(
      selectedOutboundNetwork,
      preferences,
      profile,
      shipment,
      customer
    );

  // TODO: check on all pages
  let generateCustomsData = preSelectedValue;

  if (isEmpty(shipment.invoice) && dropdownVisible) {
    generateCustomsData = ServiceModels.isDpdDirectService(
      selectedOutboundNetwork
    );
  }

  if (
    dropdownVisible &&
    (!isEmpty(shipment.invoice) ||
      ServiceModels.isFlagRequired(selectedOutboundNetwork, "proforma"))
  ) {
    generateCustomsData = true;
  }

  // for edit failed shipment there is case when generateCustomsData is invalid but defined
  // we must keep defined value to highlight error on UI
  if (
    dropdownVisible &&
    !dropdownDisabled &&
    has(shipment, "generateCustomsData")
  ) {
    generateCustomsData = shipment.generateCustomsData;
  }

  return {
    ...data,
    shipment: {
      ...shipment,
      generateCustomsData,
    },
  };
};

// @see: https://it.dpduk.live/it/diagram/diag_DWBhjD6GAqAAhaqs.html?id=1655210548970
export const setEditShipmentDate = data => {
  const { shipment, preferences, storageDate } = data;
  const currentShipmentDate = moment(
    shipment.shipmentDate,
    DATE_FORMAT.ISO_DATE_FORMAT
  );
  const isShipmentDateBeforeToday = currentShipmentDate.isBefore(
    moment().startOf("day")
  );

  if (isShipmentDateBeforeToday) {
    const shipmentDate = getPreferredShipmentDate(
      preferences.shippingDefaults,
      storageDate
    );

    return {
      ...data,
      shipment: {
        ...shipment,
        shipmentDate,
      },
    };
  } else {
    const minDate = getMinShipmentDate(
      preferences.shippingDefaults.enableWeekend
    );
    const shipmentDate = currentShipmentDate.isSameOrAfter(
      moment(minDate, DATE_FORMAT.DAY_DEFAULT_DATE_FORMAT)
    )
      ? currentShipmentDate.format(DATE_FORMAT.DAY_DEFAULT_DATE_FORMAT)
      : minDate;

    return {
      ...data,
      shipment: {
        ...shipment,
        shipmentDate,
      },
    };
  }
};

export const removeOutboundCollectionDetails = data => {
  const { shipment } = data;
  const outboundConsignment = shipment.outboundConsignment;

  return {
    ...data,
    shipment: {
      ...shipment,
      outboundConsignment: omit(outboundConsignment, ["collectionDetails"]),
    },
  };
};

export const getDefaultNumberOfParcelFromShipment = value =>
  value === 0 ? 1 : value;

export const setupDefaultValuesForInvalidOutboundConsignment = ({
  currencies,
  shipment,
  preferences,
  profile,
  countriesKeyObject,
  network,
}) => {
  const deliveryCountry =
    countriesKeyObject[
      getValue(
        shipment,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
          .COUNTRY_CODE
      )
    ];
  const defaultCountryCode = getValue(deliveryCountry, "countryKey");
  const isAvailableCurrency = currencies.some(
    item =>
      item.currencyCode ===
      getValue(shipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.CURRENCY)
  );
  const defaultLiability = LiabilityModels.getDefaultLiability(
    preferences,
    defaultCountryCode
  );
  const outboundConsignment = shipment.outboundConsignment;

  // @see: https://geopost.jira.com/browse/CSHIP-6333
  // NOTE: must set correct totalWeight and numberOfParcels for proper fetching outboundServices
  set(
    outboundConsignment,
    Fields.TOTAL_WEIGHT,
    ServiceModels.roundTotalWeight(
      get(shipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT),
      defaultCountryCode,
      getValue(network, Fields.NETWORK_CODE, ""),
      true
    )
  );
  set(
    outboundConsignment,
    Fields.NUMBER_OF_PARCELS,
    ServiceModels.roundNumberOfParcels(
      get(shipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS)
    )
  );

  if (!isAvailableCurrency) {
    set(
      outboundConsignment,
      Fields.CURRENCY,
      CustomsModels.getDefaultCurrency(preferences, profile)
    );
  }

  if (
    !isEmpty(shipment.invoice) &&
    !getValue(outboundConsignment, Fields.DELIVERY_DESCRIPTION)
  ) {
    set(
      outboundConsignment,
      Fields.DELIVERY_DESCRIPTION,
      getValue(
        preferences,
        "shippingDefaults.international.intContentDescription",
        ""
      ).toUpperCase()
    );
  }

  if (
    isNil(getValue(shipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY))
  ) {
    set(outboundConsignment, Fields.LIABILITY, defaultLiability);
  }

  // NOTE: set liabilityValue after liability
  if (
    isNil(
      getValue(shipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY_VALUE)
    )
  ) {
    const defaultLiabilityValue = roundToDecimal(
      LiabilityModels.getDefaultLiabilityValue(
        preferences,
        defaultCountryCode,
        getValue(outboundConsignment, Fields.LIABILITY, false)
      )
    );
    defaultLiabilityValue
      ? set(outboundConsignment, Fields.LIABILITY_VALUE, defaultLiabilityValue)
      : // should remove liabilityValue, because @@redux-form/REINITIALIZE action set undefined as empty string
        delete outboundConsignment.liabilityValue;
  }

  return {
    ...shipment,
    outboundConsignment,
  };
};

export const isReverseItProhibited = customerPrefs =>
  !get(customerPrefs, "allowAdhocReversIt");

export const isSwapItProhibited = customerPrefs =>
  !get(customerPrefs, "allowSwapit");

export const isReturnShipmentTypeProhibited = (customerPrefs, shipmentType) =>
  (isReverseItProhibited(customerPrefs) &&
    isReverseItShipmentType(shipmentType)) ||
  (isSwapItProhibited(customerPrefs) && isSwapItShipmentType(shipmentType));

export const setupWeightAndNumberOfParcels = data => {
  const { shipment } = data;

  set(
    shipment,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT,
    ServiceModels.roundTotalWeight(
      get(data.shipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT),
      get(
        data.shipment,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
          .COUNTRY_CODE
      ),
      getValue(data, "selectedOutboundNetwork.networkKey", "")
    )
  );
  // NOTE: blur this field convert value to string
  // as result serviceQuery and newServiceQuery will be different and launch redundant fetching services
  set(
    shipment,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS,
    get(
      data.shipment,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS
    )?.toString()
  );

  return {
    ...data,
    shipment,
  };
};

export const setupBasicShipment = data => {
  const resultShipment = omit(
    pick(data.shipment, flattenEntityRoutes(ShipmentEntity)),
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_DESCRIPTION]
  );

  set(resultShipment, ShipmentEntity.PROFILE_CODE, data.profile?.profileCode);
  set(
    resultShipment,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE,
    data.selectedOutboundNetwork?.networkKey
  );
  set(
    resultShipment,
    ShipmentEntity.SHIPMENT_TYPE,
    isEmpty(data.shipment.inboundConsignment)
      ? SHIPMENT_TYPES.NO_TYPE.toString()
      : getValue(data.shipment, ShipmentEntity.SHIPMENT_TYPE).toString()
  );

  return { ...data, shipment: resultShipment };
};

export const setupGstFields = data => {
  if (
    !ServiceModels.isVisibleDestinationTaxIdRegNo(
      data.selectedOutboundNetwork,
      data.shipment.generateCustomsData,
      data.customer
    )
  ) {
    delete data.shipment.outboundConsignment.destinationTaxId;
    delete data.shipment.outboundConsignment.gstVatPaid;
  }

  return { ...data, shipment: data.shipment };
};

export const setupReturnShipmentType = data => {
  if (
    isReturnShipmentTypeProhibited(
      data.customerPrefs,
      data.shipment.shipmentType
    )
  ) {
    return {
      ...data,
      shipment: {
        ...omit(data.shipment, [INBOUND_CONSIGNMENT]),
        [ShipmentEntity.SHIPMENT_TYPE]: SHIPMENT_TYPES.NO_TYPE.toString(),
      },
    };
  }

  return { ...data, shipment: data.shipment };
};

export const shouldDisablePrintButtons = (
  isValid,
  isDirty,
  isParcelsDataValid,
  submitErrors,
  activeField,
  isLoadingOutboundServices,
  isLoadingInboundServices
) =>
  isDirty
    ? !isValid ||
      !isParcelsDataValid ||
      !isEmpty(submitErrors) ||
      activeField ||
      isLoadingOutboundServices ||
      isLoadingInboundServices
    : !isDirty;

export const isCreateForm = formName => formName === CREATE_SHIPMENT_FORM;

export const isFailedForm = formName => formName === EDIT_FAILED_SHIPMENT_FORM;

export const isDisabledAdhocEditShipment = customerPrefs =>
  get(customerPrefs, "disableAdhocEditShipment", false);

export const isOldShipment = shipment =>
  moment().diff(moment(shipment.shipmentDate), "days") > 9;

// @see https://it.dpduk.live/it/diagram/diag_j9c6TD6GAqAAhde6.html?id=1639752991650
// @see https://it.dpduk.live/it/diagram/diag_c2oa1D6FYFxijXCV.html
export const isShipmentEditable = (shipment, customerPrefs) => {
  const shipmentType = get(shipment, "shipmentType");
  const allowAdhocReversIt = get(customerPrefs, "allowAdhocReversIt");
  const allowSwapit = get(customerPrefs, "allowSwapit");
  const allowEdit = get(shipment, "allowEdit");

  const networkCode = get(
    shipment,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE
  );
  const deliveryCountryCode = get(
    shipment,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE
  );
  const deliveryPostcode = get(
    shipment,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE
  );
  const isShipToShopService = SHIP_TO_SHOP.includes(networkCode);

  if (
    (isReverseItShipmentType(shipmentType) && !allowAdhocReversIt) ||
    (isSwapItShipmentType(shipmentType) && !allowSwapit) ||
    (isShipToShopService && (!deliveryCountryCode || !deliveryPostcode))
  ) {
    return false;
  }

  return allowEdit;
};

// @see https://it.dpduk.live/version/customer-shipping/sprint-1.19/diag_bBoa1D6FYFxijXdh.html
export const getDeliveryInformationOptions = preferences =>
  reduce(
    getValue(preferences, "shippingDefaults"),
    (acc, value, key) => {
      if (S.DEFAULT_INFORMATIONS.includes(key) && value) {
        acc.push(value);
      }
      return acc;
    },
    []
  );

export const getInitialTaxValues = (profile, preferences) => ({
  [Fields.DESTINATION_TAX_ID_REG_NO]:
    getValue(profile, "invDestinationTaxIdRegNo") ||
    getValue(preferences, "prefsInvoiceSettings.destinationTaxIdRegNo"),
  [Fields.GST_VAT_PAID]: null,
  [Fields.UKIMS_NUMBER]:
    getValue(profile, "invUkImsNumber") ||
    getValue(preferences, "prefsInvoiceSettings.ukimsNumber"),
});

const prepareOutboundAddresses = ({
  shipment,
  profile,
  userCustomerAddress,
  customer,
  countries,
}) => {
  const outboundConsignment = shipment.outboundConsignment;
  const deliveryPath = `${DELIVERY_DETAILS}.${ADDRESS}`;
  const collectionDetails = getCollectionDetails(
    shipment,
    profile,
    userCustomerAddress,
    customer,
    countries
  );
  set(
    outboundConsignment,
    deliveryPath,
    omitNilValues(getValue(outboundConsignment, deliveryPath))
  );
  set(outboundConsignment, COLLECTION_DETAILS, collectionDetails);

  return {
    ...shipment,
    outboundConsignment,
  };
};

const removeEmptyInboundAddressValues = ({ shipment }) => {
  const inboundConsignment = cloneDeep(
    getValue(shipment, INBOUND_CONSIGNMENT, {})
  );
  const deliveryPath = `${DELIVERY_DETAILS}.${ADDRESS}`;
  const collectionPath = `${COLLECTION_DETAILS}.${ADDRESS}`;

  if (isEmpty(inboundConsignment)) {
    return shipment;
  }

  set(
    inboundConsignment,
    deliveryPath,
    omitNilValues(getValue(inboundConsignment, deliveryPath))
  );
  set(
    inboundConsignment,
    collectionPath,
    omitNilValues(getValue(inboundConsignment, collectionPath))
  );

  return {
    ...shipment,
    inboundConsignment,
  };
};

export const setupDefaultValuesForInvalidData = data => {
  const { shipment, network, countries } = data;
  const countriesKeyObject = mapArrayToObjectByKey(countries, "countryKey");
  let selectedCountry = data.deliveryCountry;
  let resultShipment = toUppercaseValues({
    ...shipment.shipmentDetails,
    stagingShipmentId: shipment.stagingShipmentId,
  });

  resultShipment = setNetworkShipmentType({
    shipment: resultShipment,
    network,
  });

  const isReturnShipmentType = isSwapItOrReverseItShipmentType(
    resultShipment.shipmentType
  );

  // NOTE: Defaulting incorrect delivery country for collect on delivery to preserve return details
  if (isReturnShipmentType && (!selectedCountry || isEmpty(selectedCountry))) {
    set(
      resultShipment,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
      S.GB
    );
    set(
      resultShipment,
      "inboundConsignment.collectionDetails.address.countryCode",
      S.GB
    );
    selectedCountry = { countryKey: S.GB };
  }

  resultShipment = prepareOutboundAddresses({
    ...data,
    shipment: resultShipment,
  });
  resultShipment = removeEmptyInboundAddressValues({
    shipment: resultShipment,
  });
  resultShipment = setupDefaultValuesForInvalidOutboundConsignment({
    ...data,
    countriesKeyObject,
    shipment: resultShipment,
  });
  resultShipment = InvoiceModels.setupDefaultValuesForInvalidInvoice({
    ...data,
    shipment: resultShipment,
  });

  // NOTE: do it after setupDefaultValuesForInvalidOutboundConsignment
  if (isSwapItShipmentType(resultShipment.shipmentType)) {
    // NOTE: inbound totalWeight always must be equal outbound totalWeight
    // @see:https://geopost.jira.com/browse/CSHIP-6560 user can pass to the csv any value
    set(
      resultShipment,
      ShipmentEntity.INBOUND_CONSIGNMENT.TOTAL_WEIGHT,
      getValue(resultShipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT)
    );
  }

  return { shipment: resultShipment, selectedCountry };
};

export const getAddressBookRequiredFields = (
  selectedCountry,
  shipmentRequiredFields
) => {
  const isInternationalNonEUCountry =
    !AddressModels.isCountryDomestic(selectedCountry.countryKey) &&
    !get(selectedCountry, "euCountry", false);

  return {
    ...shipmentRequiredFields,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
      .CONTACT_NAME]: isInternationalNonEUCountry,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
      .TELEPHONE]: isInternationalNonEUCountry,
  };
};

export const getAddressBookErrors = (
  values,
  selectedService,
  selectedCountry,
  user,
  customer,
  searchAddressDetailsValues,
  countries,
  requiredFields
) => {
  const props = {
    selectedService,
    selectedCountry,
    user,
    customer,
    searchAddressDetailsValues,
    countries,
    requiredFields,
    values,
  };

  return createValidator(addressBookSchema, [
    () =>
      postcodeValidation(
        props,
        selectedCountry,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE
      ),
  ])(values, props);
};

export const getShipmentRequiredFields = (
  formValues,
  selectedOutboundService,
  selectedInboundService,
  commonRequiredFields,
  selectedCountry,
  customsFieldsFlags,
  isVisibleDestinationTaxIdRegNo,
  invoiceRequiredFields,
  selectedReturnCountry,
  preferences,
  profile,
  customer
) => {
  const liability = get(
    formValues,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY,
    false
  );
  const { networkCode } = getPackageDetails(formValues);
  const notifyRequired = ServiceModels.isFlagRequired(
    selectedOutboundService,
    "notifyRequired"
  );
  const { networkCode: returnNetworkCode } =
    getReturnPackageDetails(formValues);
  const returnNotifyRequired = ServiceModels.isFlagRequired(
    selectedInboundService,
    "notifyRequired"
  );
  const countryCode = get(selectedCountry, "countryKey", "");
  const isReturnPostcodeRequired = get(
    selectedReturnCountry,
    "isPostcodeRequired"
  );
  const { dropdownVisible } = getGenerateCustomsDataSettings(
    selectedOutboundService,
    preferences,
    profile,
    formValues,
    customer
  );
  const isPostcodeRequired = shouldCheckPostcode(selectedCountry);
  const isNewVersion = SettingsModels.isNewVersion(customer.shippingVersion);

  const oldGstVatPaid =
    isVisibleDestinationTaxIdRegNo &&
    !!getValue(
      formValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO
    );

  return {
    ...invoiceRequiredFields,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
      .CONTACT_NAME]: networkCode && notifyRequired,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
      .TELEPHONE]: (countryCode && countryCode !== S.GB) || notifyRequired,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.NETWORK_CODE]: true,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO]:
      isVisibleDestinationTaxIdRegNo &&
      ServiceModels.isFlagRequired(selectedOutboundService, "taxRequired"),
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.GST_VAT_PAID]: isNewVersion
      ? oldGstVatPaid &&
        ServiceModels.isFlagRequired(selectedOutboundService, "vatPaidRequired")
      : oldGstVatPaid,
    [ShipmentEntity.SHIPMENT_DATE]: true,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.TOTAL_WEIGHT]: true,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.NUMBER_OF_PARCELS]: true,
    [ShipmentEntity.GENERATE_CUSTOMS_DATA]: dropdownVisible,
    ...commonRequiredFields,
    // NOTE: must overwrite it here because commonRequiredFields uses for ability to update addressbook
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE]:
      countryCode && isPostcodeRequired,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE]:
      true,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.ORGANISATION]:
      SHIP_TO_SHOP.includes(selectedOutboundService?.networkKey),
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.PICKUP_DETAILS.PICKUP_LOCATION_CODE]:
      SHIP_TO_SHOP.includes(selectedOutboundService?.networkKey),
    [ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.STREET]: true,
    [ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE]:
      true,
    [ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.TOWN]: true,
    [ShipmentEntity.INBOUND_CONSIGNMENT.TOTAL_WEIGHT]: true,
    [ShipmentEntity.INBOUND_CONSIGNMENT.NUMBER_OF_PARCELS]: true,
    [ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE]:
      isReturnPostcodeRequired,
    [ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
      .CONTACT_NAME]: !isEmpty(returnNetworkCode) && returnNotifyRequired,
    [ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.CONTACT_DETAILS
      .TELEPHONE]: countryCode && countryCode !== S.GB,
    [ShipmentEntity.INBOUND_CONSIGNMENT.NETWORK_CODE]: true,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.CURRENCY]:
      CustomsModels.isCustomsFieldsVisible(
        selectedOutboundService,
        selectedCountry,
        formValues,
        customer
      ),
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.CUSTOMS_VALUE]:
      customsFieldsFlags.customsValue,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION]:
      customsFieldsFlags.deliveryDescription,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY_VALUE]:
      liability && !AddressModels.isCountryDomestic(countryCode),
  };
};

export const getShipmentDetailsReview = (
  formValues,
  selectedService,
  allowedFields,
  products,
  shippingFreightCost,
  parcelsTotalValue,
  touchedErrorFields
) =>
  CardModels.mapShipmentDetails(
    getPackageDetails(formValues),
    selectedService,
    get(formValues, Fields.SHIPMENT_TYPE),
    allowedFields,
    products,
    shippingFreightCost,
    parcelsTotalValue,
    touchedErrorFields
  );

export const getInvoiceAllowedFields = ({
  requiredFields,
  formValues,
  selectedService,
  customer,
}) => {
  const isNewVersion = SettingsModels.isNewVersion(customer.shippingVersion);
  const isInvoiceDetailsSectionVisible =
    NewShipmentModels.isShippingInformationVisible(customer, selectedService, {
      generateCustomsData: getValue(
        formValues,
        ShipmentEntity.GENERATE_CUSTOMS_DATA,
        false
      ),
      countryCode: getValue(
        formValues,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
          .COUNTRY_CODE,
        ""
      ),
      postCode: getValue(
        formValues,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
        ""
      ),
    });

  return {
    [ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS]:
      requiredFields[ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS],
    [ShipmentEntity.INVOICE.IMPORTER_DETAILS.AT_RISK]:
      requiredFields[ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS] &&
      getValue(
        formValues,
        ShipmentEntity.INVOICE.IMPORTER_DETAILS.IS_BUSINESS,
        false
      ) &&
      AddressModels.isNiShipment(
        getValue(
          formValues,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
            .COUNTRY_CODE,
          ""
        ),
        getValue(
          formValues,
          ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
          ""
        )
      ),
    [ShipmentEntity.INVOICE.EXPORT_REASON]: InvoiceModels.isVisibleExportReason(
      selectedService,
      customer
    ),
    [ShipmentEntity.INVOICE.SHIPPING_COST]: InvoiceModels.isVisibleShippingCost(
      selectedService,
      customer
    ),
    [ShipmentEntity.INVOICE.EXPORTER_DETAILS.FDA_NUMBER]:
      isNewVersion && ServiceModels.isVisibleFdaNumber(selectedService),
    ...isInvoiceDetailsSectionVisible, // NOTE: use only for 2-nd version
  };
};

export const getCommonAllowedFields = (
  { deliveryDescriptionHidden },
  requiredFields,
  isAllowedDestinationTaxIdRegNo,
  formValues,
  isCustomsFieldsAllowed,
  securitySettings,
  selectedService,
  shipmentTypes,
  preferences,
  profile,
  customer
) => {
  const isNewVersion = SettingsModels.isNewVersion(customer.shippingVersion);
  const { dropdownVisible } = getGenerateCustomsDataSettings(
    selectedService,
    preferences,
    profile,
    formValues,
    customer
  );
  const isCustomsDataCardAllowed =
    getGenerateCustomsDataMessage(
      selectedService,
      formValues?.[ShipmentEntity.GENERATE_CUSTOMS_DATA],
      preferences
    ) ||
    getDirectShipmentMessage(
      selectedService,
      get(
        formValues,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
          .COUNTRY_CODE,
        ""
      )
    );
  const isShipToShopService = SHIP_TO_SHOP.includes(
    get(selectedService, "networkKey", "")
  );
  const oldGstVatPaid =
    isAllowedDestinationTaxIdRegNo &&
    !!getValue(
      formValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO
    );
  const invoiceAllowedFields = getInvoiceAllowedFields({
    requiredFields,
    formValues,
    selectedService,
    customer,
  });

  return {
    searchAddress: {
      [INBOUND_CONSIGNMENT_UPPERCASE]: true,
    },
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION]:
      !deliveryDescriptionHidden,
    deliveryContactCard:
      requiredFields[
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS
          .NOTIFICATION_DETAILS.MOBILE
      ] ||
      requiredFields[
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS
          .NOTIFICATION_DETAILS.EMAIL
      ],
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.DESTINATION_TAX_ID_REG_NO]:
      isAllowedDestinationTaxIdRegNo,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.GST_VAT_PAID]: isNewVersion
      ? oldGstVatPaid &&
        !ServiceModels.isFlagNeedles(selectedService, "vatPaidRequired")
      : oldGstVatPaid,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY_VALUE]:
      LiabilityModels.isLiabilityValueVisible(formValues),
    domesticService: AddressModels.isCountryDomestic(
      get(
        formValues,
        ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
          .COUNTRY_CODE,
        ""
      )
    ),
    customs: isCustomsFieldsAllowed,
    extendedLiability: LiabilityModels.getIsExtendedLiabilityFlagAllowed(
      securitySettings,
      selectedService
    ),
    // @see: https://it.dpduk.live/version/customer-shipping/ni-protocol-shipping/sprint-2/diag_OgY5Pp6C48kIGGQx.html
    [ShipmentEntity.SHIPMENT_TYPE]:
      get(selectedService, "collectOnDelivery", false) &&
      shipmentTypes.length > 0 &&
      !isShipToShopService,
    [ShipmentEntity.GENERATE_CUSTOMS_DATA]: dropdownVisible,
    [ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DESCRIPTION]:
      isSwapItShipmentType(formValues?.[ShipmentEntity.SHIPMENT_TYPE]),
    returnCards: isSwapItOrReverseItShipmentType(
      formValues?.[ShipmentEntity.SHIPMENT_TYPE]
    ),
    customsDataCard: isCustomsDataCardAllowed,
    productDetailsData: NewShipmentModels.isProductsRequired(
      customer,
      selectedService,
      formValues
    ),
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1]: true,
    editExporter: true,
    editImporter: true,
    [ProductDescriptionsEntity.PRODUCT_DESCRIPTION]:
      ServiceModels.isFlagDescription(selectedService, "prodRequired"),
    shipToShop: includes(SHIP_TO_SHOP, selectedService?.networkKey),
    ...invoiceAllowedFields,
  };
};

// @see: https://it.dpduk.live/it/diagram/diag_dIlhMP6GAqCIalTS.html
export const setUniqueShippingRef1 = data => {
  const { shipment, preferences, shippingSettings, uniqueSenderRef1 } = data;

  if (
    getValue(shipment, ShipmentEntity.OUTBOUND_CONSIGNMENT.SHIPPING_REF_1, "")
  ) {
    return data;
  }

  const outboundConsignment = shipment.outboundConsignment;
  const ref1 = getDefaultUniqueShippingRef1(
    getValue(
      shipment,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE
    ),
    uniqueSenderRef1,
    {
      preferences,
      shippingSettings,
    }
  );
  set(outboundConsignment, Fields.SHIPPING_REF_1, ref1);

  return {
    ...data,
    shipment: {
      ...shipment,
      outboundConsignment,
    },
  };
};

export const shouldGenerateUniqueSenderRef1 = (formName, allocateSenders) =>
  (isCreateForm(formName) || isFailedForm(formName)) && allocateSenders;

export const shouldCheckPostcode = country => {
  let shouldCheckPostcodePattern = false;
  const postcodePattern = getValue(country, "postcodePattern");
  const isPostcodeRequired = get(country, "isPostcodeRequired", false);

  if (postcodePattern?.length) {
    shouldCheckPostcodePattern = postcodePattern.some(
      pattern => pattern.trim() === ""
    );
  }

  return isPostcodeRequired && !shouldCheckPostcodePattern;
};
