import { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { FormControl } from "@dpdgroupuk/mydpd-ui";

import { PostcodeModels } from "~/models";
import {
  $_IS_NOT_VALID,
  $_MUST_BE_ENTERED,
  POSTCODE,
} from "~/constants/strings";
import { Search } from "~/components/Icon";
import { formatMessage } from "~/utils/string";

import styles from "./Filters.module.scss";

const SearchInput = ({ onSubmit, deliveryAddress }) => {
  const [postcode, setPostcode] = useState(deliveryAddress.postcode || "");
  const [active, setActive] = useState(false);

  const isPostcodeValid =
    !!postcode && PostcodeModels.isGBPostCodeFormat(postcode);

  const showError = !active && !isPostcodeValid;
  const errorMessage = postcode
    ? formatMessage($_IS_NOT_VALID, POSTCODE, "")
    : formatMessage($_MUST_BE_ENTERED, POSTCODE);

  return (
    <form
      className={classNames(
        "d-flex align-items-center mr-2",
        active && "border-primary",
        showError && "border-danger",
        styles.searchInputContainer
      )}
    >
      <FormControl
        type="text"
        label="Postcode"
        onKeyDown={event => {
          if (event.key === "Enter") {
            event.preventDefault();
            event.target.blur();
            isPostcodeValid && onSubmit(postcode);
          }
        }}
        required
        classes={{
          group: classNames("mb-0", styles.searchInput),
          input: classNames(styles.searchInput),
        }}
        value={postcode}
        meta={{
          active,
          touched: true,
          error: showError && errorMessage,
        }}
        onBlur={() => setActive(false)}
        onFocus={() => setActive(true)}
        onChange={setPostcode}
      />

      <div onClick={() => isPostcodeValid && onSubmit(postcode)} type="submit">
        <Search active={active} invalid={showError} />
      </div>
    </form>
  );
};

SearchInput.propTypes = {
  onSubmit: PropTypes.func,
  deliveryAddress: PropTypes.object,
};

export default SearchInput;
