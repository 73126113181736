import { PropTypes } from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Field } from "redux-form";

import { FormControl } from "@dpdgroupuk/mydpd-ui";

import { ShipmentEntity } from "~/constants/forms";
import { invoiceTypes } from "~/constants/invoice";
import * as S from "~/constants/strings";
import { Normalizers } from "~/models";

const NewInvoiceDetails = ({
  disabledFields,
  shippingInfoDefaults,
  onFieldEntry,
  requiredFields,
  shippingCostLabel,
  allowedFields,
}) => (
  <>
    <Row>
      <Col xs={12}>
        <Field
          component={FormControl.Dropdown}
          name={ShipmentEntity.INVOICE.INVOICE_TYPE}
          label={S.INVOICE_TYPE}
          helperText
          onBlur={onFieldEntry}
          values={invoiceTypes}
          required
          textTransform={S.UPPERCASE}
          disabled={disabledFields[ShipmentEntity.INVOICE.INVOICE_TYPE]}
        />
      </Col>
    </Row>
    <Row>
      <Col xs={12}>
        <Field
          component={FormControl.Input}
          name={ShipmentEntity.INVOICE.INVOICE_REFERENCE}
          label={S.INVOICE_REFERENCE}
          helperText={S.MAX_30_CHARACTERS}
          onBlur={onFieldEntry}
          maxLength={30}
          disabled={disabledFields[ShipmentEntity.INVOICE.INVOICE_REFERENCE]}
        />
      </Col>
    </Row>
    {allowedFields[ShipmentEntity.INVOICE.EXPORT_REASON] && (
      <Row>
        <Col xs={12}>
          <Field
            component={FormControl.Dropdown}
            name={ShipmentEntity.INVOICE.EXPORT_REASON}
            label={S.REASON_FOR_EXPORT}
            helperText
            onBlur={onFieldEntry}
            values={shippingInfoDefaults.exportReasons}
            required={requiredFields[ShipmentEntity.INVOICE.EXPORT_REASON]}
            textTransform={S.UPPERCASE}
            disabled={disabledFields[ShipmentEntity.INVOICE.EXPORT_REASON]}
          />
        </Col>
      </Row>
    )}
    <Row>
      <Col xs={12}>
        <Field
          component={FormControl.Dropdown}
          name={ShipmentEntity.INVOICE.INTERNATIONAL_BILLING_TERMS}
          label={S.INTERNATIONAL_BILLING_TERMS}
          helperText
          onBlur={onFieldEntry}
          values={shippingInfoDefaults.internationalBillings}
          required
          textTransform={S.UPPERCASE}
          disabled={
            disabledFields[ShipmentEntity.INVOICE.INTERNATIONAL_BILLING_TERMS]
          }
        />
      </Col>
    </Row>
    {allowedFields[ShipmentEntity.INVOICE.SHIPPING_COST] && (
      <Row>
        <Col xs={12}>
          <Field
            component={FormControl.Input}
            name={ShipmentEntity.INVOICE.SHIPPING_COST}
            label={shippingCostLabel}
            helperText
            onBlur={onFieldEntry}
            normalize={Normalizers.floatNormalize}
            maxLength={11}
            required={requiredFields[ShipmentEntity.INVOICE.SHIPPING_COST]}
            disabled={disabledFields[ShipmentEntity.INVOICE.SHIPPING_COST]}
          />
        </Col>
      </Row>
    )}
    {allowedFields[ShipmentEntity.INVOICE.EXPORTER_DETAILS.FDA_NUMBER] && (
      <Row>
        <Col>
          <Field
            component={FormControl.Input}
            label={S.FDA_NUMBER}
            name={ShipmentEntity.INVOICE.EXPORTER_DETAILS.FDA_NUMBER}
            onBlur={onFieldEntry}
            required={
              requiredFields[ShipmentEntity.INVOICE.EXPORTER_DETAILS.FDA_NUMBER]
            }
            helperText={S.MAX_30_CHARACTERS}
            maxLength={30}
            normalize={Normalizers.emptyValueNormalize}
            disabled={disabledFields.invoiceDetails}
          />
        </Col>
      </Row>
    )}
  </>
);

NewInvoiceDetails.propTypes = {
  shippingCostLabel: PropTypes.string,
  requiredFields: PropTypes.object,
  allowedFields: PropTypes.object,
  disabledFields: PropTypes.object,
  shippingInfoDefaults: PropTypes.object,
  onFieldEntry: PropTypes.func,
};
export default NewInvoiceDetails;
