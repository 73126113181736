import { get, set } from "lodash";

import { Fields, ShipmentEntity } from "~/constants/forms";
import { roundToDecimal } from "~/utils/number";
import { getValue } from "~/utils/object";
import { AddressModels } from "~/models";

// @see https://it.dpduk.live/version/customer-shipping/sprint-1.19/diag_veA81N6GAqAAha2L.html?id=1639728457038
export const getDefaultLiability = (preferences, countryCode) => {
  const domestic = AddressModels.isCountryDomestic(countryCode);
  const intLiability = !!get(
    preferences,
    "shippingDefaults.international.intLiability",
    false
  );

  if (domestic) {
    return false;
  }

  return intLiability;
};

export const isLiabilityValueVisible = formValues =>
  get(formValues, ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY, false) &&
  !AddressModels.isCountryDomestic(
    get(
      formValues,
      ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE,
      ""
    )
  );

// @see https://it.dpduk.live/version/customer-shipping/sprint-1.19/diag_veA81N6GAqAAha2L.html?id=1639728457038
export const getDefaultLiabilityValue = (
  preferences,
  countryCode,
  liability
) => {
  let intLiabilityValue = getValue(
    preferences,
    "shippingDefaults.international.intLiabilityValue",
    ""
  );
  const formValues = {
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY]: liability,
    [ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE]:
      countryCode,
  };

  if (intLiabilityValue) {
    intLiabilityValue = parseFloat(intLiabilityValue)?.toFixed(2);
  }

  return isLiabilityValueVisible(formValues) ? intLiabilityValue : undefined;
};

export const getIsExtendedLiabilityFlagAllowed = (
  securitySettings,
  selectedService
) => {
  const insurance = get(selectedService, "insurance", false);
  const disableExtendedLiability = get(
    securitySettings,
    "disableExtendedLiability"
  );

  return insurance && !disableExtendedLiability;
};

export const setupInitialExtendedLiability = data => {
  const { shipment, securitySettings, selectedOutboundNetwork } = data;
  const shipmentLiabilityValue = getValue(
    shipment,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY_VALUE
  );
  const outboundConsignment = shipment.outboundConsignment;

  if (
    !getIsExtendedLiabilityFlagAllowed(
      securitySettings,
      selectedOutboundNetwork
    )
  ) {
    set(outboundConsignment, Fields.LIABILITY, false);
    delete outboundConsignment.liabilityValue;

    return {
      ...data,
      shipment: {
        ...shipment,
        outboundConsignment,
      },
    };
  }

  set(
    outboundConsignment,
    Fields.LIABILITY_VALUE,
    roundToDecimal(shipmentLiabilityValue)
  );

  if (!shipmentLiabilityValue) {
    delete outboundConsignment.liabilityValue;
  }

  return {
    ...data,
    shipment: {
      ...shipment,
      outboundConsignment,
    },
  };
};

export const isLiabilityValue = field =>
  field === ShipmentEntity.OUTBOUND_CONSIGNMENT.LIABILITY_VALUE;
