import { get, includes, isEmpty, isNil, set } from "lodash";
import uniq from "lodash/uniq";

import { REQUIRED_TYPE, SHIPMENT_TYPES } from "@dpdgroupuk/mydpd-enums";

import { Fields, INBOUND_CONSIGNMENT, ShipmentEntity } from "~/constants/forms";
import {
  AIR_CLASSIC_PARCEL_NETWORKS,
  AIR_EXPRESS_PARCEL_NETWORKS,
  DPD_CLASSIC_FREIGHT_NETWORKS,
  DPD_CLASSIC_PALLET_NETWORKS,
  DPD_CLASSIC_PARCEL_NETWORKS,
  DPD_DIRECT_LITE,
  DPD_DIRECT_PARCEL_NETWORKS,
  DPD_SMALL_PARCEL_NETWORKS,
  SHIP_TO_SHOP,
} from "~/constants/services";
import { IE } from "~/constants/strings";
import { AddressModels, SettingsModels } from "~/models";
import * as postcodeModels from "~/models/postcode";
import { getValue } from "~/utils/object";

// @see https://it.dpduk.live/version/customer-shipping/ship2shop/sprint-1/diag_8yWj4R6C48kIGDBa.html?id=1733924984923
export const getUmsFilteredServices = (
  services = [],
  customerSpecialServices,
  excludeSpecialServices
) =>
  services.filter(({ networkKey, umsCheck }) => {
    const isSpecialServiceExclude = includes(
      excludeSpecialServices,
      networkKey
    );

    const isCustomerSpecialService = includes(
      customerSpecialServices,
      networkKey
    );

    return !umsCheck || (isCustomerSpecialService && !isSpecialServiceExclude);
  });

// @see https://it.dpduk.live/it/diagram/diag_M5hNSf6GAqCIatTB.html
export const getServicesForUser = (
  { serviceExclusions, excludeSpecialServices },
  profile = {},
  services = [],
  customer,
  formValues,
  isOutboundServices
) => {
  if (isEmpty(profile)) {
    return services;
  }

  const {
    enableAirClassicParcel,
    enableAirExpressParcel,
    enableDpdDirectParcel,
    enableDpdClassicParcel,
    enableDpdSmallParcel,
    enableDpdClassicFreight,
    enableDpdClassicPallet,
    enableDpdDirectLite,
  } = profile;
  let exclusions = [...serviceExclusions];

  if (!enableAirClassicParcel) {
    exclusions = [...exclusions, ...AIR_CLASSIC_PARCEL_NETWORKS];
  }

  if (!enableAirExpressParcel) {
    exclusions = [...exclusions, ...AIR_EXPRESS_PARCEL_NETWORKS];
  }

  if (!enableDpdDirectParcel) {
    exclusions = [...exclusions, ...DPD_DIRECT_PARCEL_NETWORKS];
  }

  if (!enableDpdClassicParcel) {
    exclusions = [...exclusions, ...DPD_CLASSIC_PARCEL_NETWORKS];
  }

  if (!enableDpdSmallParcel) {
    exclusions = [...exclusions, ...DPD_SMALL_PARCEL_NETWORKS];
  }

  if (!enableDpdClassicFreight) {
    exclusions = [...exclusions, ...DPD_CLASSIC_FREIGHT_NETWORKS];
  }

  if (!enableDpdClassicPallet) {
    exclusions = [...exclusions, ...DPD_CLASSIC_PALLET_NETWORKS];
  }

  if (!enableDpdDirectLite) {
    exclusions = [...exclusions, ...DPD_DIRECT_LITE];
  }

  exclusions = uniq(exclusions);

  const customerSpecialServices = getValue(
    customer,
    "accountSettings.specialServices",
    []
  );

  if (
    isOutboundServices &&
    SettingsModels.isNewVersion(customer.shippingVersion) &&
    // NOTE: filter services if generateCustomsData=false
    !getValue(formValues, ShipmentEntity.GENERATE_CUSTOMS_DATA, true)
  ) {
    return getUmsFilteredServices(
      services.filter(
        service =>
          !exclusions.includes(get(service, "networkKey")) &&
          !(
            isFlagRequired(service, "proforma") ||
            (isFlagNeedles(service, "proforma") &&
              isFlagRequired(service, "prodRequired"))
          )
      ),
      customerSpecialServices,
      excludeSpecialServices
    );
  }

  return getUmsFilteredServices(
    services.filter(
      service => !exclusions.includes(get(service, "networkKey"))
    ),
    customerSpecialServices,
    excludeSpecialServices
  );
};

export const getProductRelatedServices = (productKey, services) =>
  productKey
    ? services.filter(service => service.product.productKey === productKey)
    : [];

export const getServicesKeyValue = services =>
  services.map(service => ({
    label: get(service, "service.serviceDesc"),
    value: get(service, "service.serviceKey"),
  }));

export const getNetworksKeyValue = (services = []) =>
  services.map(service => ({
    label: get(service, "networkDesc"),
    value: get(service, "networkKey"),
  }));

export const getProductRelatedServicesKeyValue = (productKey, services) =>
  getServicesKeyValue(getProductRelatedServices(productKey, services));

export const getServiceByProductAndServiceKeys = (
  services,
  productKey,
  serviceKey
) =>
  services.find(
    service =>
      service.product.productKey === productKey &&
      service.service.serviceKey === serviceKey
  );

export const getServiceByNetworkCode = (services, networkCode) =>
  services.find(service => service.networkKey === networkCode);

// @see: https://it.dpduk.live/it/diagram/diag_rzIOER6C48kIGL0n.html
export const roundTotalWeight = (
  value,
  country,
  networkCode = "",
  setDefault = false
) => {
  let result = Number.parseFloat(value);

  if (!value || isNaN(result)) {
    // NOTE: return default value
    // @see: https://geopost.jira.com/browse/CSHIP-6333
    return setDefault ? 1 : value;
  }

  if (
    !AddressModels.isCountryDomestic(country) ||
    (country === IE && removeBusinessUnit(networkCode) === "58")
  ) {
    const baseValue = Math.ceil(result * 2) / 2;
    result = baseValue !== 0 ? baseValue.toFixed(1) : 0.5;
  } else {
    result = Math.round(result);
    result = result !== 0 ? result : 1;
  }

  return result;
};
// @see: https://it.dpduk.live/it/diagram/diag_oilhMP6GAqCIal0b.html
export const roundNumberOfParcels = value => {
  let result = Number.parseFloat(value);

  if (!value || isNaN(result)) {
    // NOTE: return default value
    // @see: https://geopost.jira.com/browse/CSHIP-6333
    return 1;
  }

  result = Math.round(result);
  result = result !== 0 ? result : 1;

  return result;
};

export const removeBusinessUnit = (value = "") =>
  value.replace(/^[12]{1}\^/, "");

export const getServiceCode = service =>
  removeBusinessUnit(getValue(service, "service.serviceKey", ""));

export const getNetworkCode = service =>
  removeBusinessUnit(getValue(service, "networkKey", ""));

// @see: https://it.dpduk.live/version/customer-shipping/ni-protocol-shipping/sprint-2/diag_lfeXaN6GAqAAhXnF.html
// @see: https://it.dpduk.live/version/customer-shipping/ni-protocol-shipping/sprint-2/diag_F_RVHF6GAqAADOKk.html
export const shouldHideBusinessConsumerOptions = (service, shippingVersion) =>
  SettingsModels.isNewVersion(shippingVersion)
    ? false
    : isFlagNeedles(service, "eoriRequired") ||
      isFlagNeedles(service, "pidRequired") ||
      isFlagNeedles(service, "vatRequired");

export const isVisibleFdaNumber = service =>
  service &&
  !isFlagNeedles(service, "proforma") &&
  !isFlagNeedles(service, "fdaRequired");

export const isVisibleExporterTaxRequiredFields = (
  service = {},
  destinationTaxId,
  shippingVersion
) => {
  const isDestinationTaxIdRegNoEmpty =
    typeof destinationTaxId === "string"
      ? isEmpty(destinationTaxId?.trim())
      : isNil(destinationTaxId);
  const isNewVersion = SettingsModels.isNewVersion(shippingVersion);

  return {
    [Fields.DESTINATION_TAX_ID_REG_NO]:
      !isNewVersion && !isFlagNeedles(service, "taxRequired"),
    [Fields.GST_VAT_PAID]:
      !isNewVersion &&
      !isFlagNeedles(service, "taxRequired") &&
      !isDestinationTaxIdRegNoEmpty,
    [Fields.FDA_NUMBER]: isVisibleFdaNumber(service),
  };
};

export const isFlagRequired = (selectedService, path) =>
  getValue(selectedService, path, "") === REQUIRED_TYPE.MANDATORY;

export const isFlagNeedles = (selectedService, path) =>
  getValue(selectedService, path, "") === REQUIRED_TYPE.NEEDLESS;

export const isFlagOptional = (selectedService, path) =>
  getValue(selectedService, path, "") === REQUIRED_TYPE.OPTIONAL;

export const isFlagDescription = (selectedService, path) =>
  getValue(selectedService, path, "") === REQUIRED_TYPE.DESCRIPTION;

export const isProductOnlyShipment = service =>
  isFlagNeedles(service, "proforma") && isFlagRequired(service, "prodRequired");

const getDefaultInboundProfileService = ({
  profile = {},
  services = [],
  selectedCountry,
  createShipmentValues,
}) => {
  const { defaultDpdOffshoreExchangeService, defaultDpdExchangeReturnService } =
    profile;
  const postcode = getValue(
    createShipmentValues,
    ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
    ""
  );
  const countryCode =
    get(selectedCountry, "countryKey") ||
    get(
      createShipmentValues,
      ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE
    );
  const isPostcodeOffshoreFlag = postcodeModels.isPostcodeOffshore(
    postcode,
    countryCode
  );
  const defaultReturnNetworkKey = isPostcodeOffshoreFlag
    ? defaultDpdOffshoreExchangeService
    : defaultDpdExchangeReturnService;

  return services.find(
    ({ networkKey }) => networkKey === defaultReturnNetworkKey
  );
};
const getInboundServiceFromDefaultList = (services = [], businessId) =>
  services.find(
    service =>
      service.networkKey === `${businessId}^42` ||
      service.networkKey === `${businessId}^55`
  ) || {};

// @see: https://it.dpduk.live/it/diagram/diag_T2WNcf6GAqCIahnF.html
// @see: comments from https://geopost.jira.com/browse/CSHIP-6234 and https://geopost.jira.com/browse/CSHIP-6372
export const getDefaultInboundService = ({
  profile = {},
  services,
  selectedCountry,
  createShipmentValues,
  authUser,
}) => {
  const profileService = getDefaultInboundProfileService({
    profile,
    services,
    selectedCountry,
    createShipmentValues,
  });

  return (
    profileService ||
    getInboundServiceFromDefaultList(services, get(authUser, "user.businessId"))
  );
};

export const isVisibleDestinationTaxIdRegNo = (
  service,
  generateCustomsData,
  customer
) =>
  SettingsModels.isNewVersion(customer.shippingVersion)
    ? // @see: https://it.dpduk.live/it/diagram/diag_jvfq1N6GAqAAhQLW.html
      !isEmpty(service) && !isFlagNeedles(service, "taxRequired")
    : !isEmpty(service) &&
      !isFlagNeedles(service, "taxRequired") &&
      (isFlagNeedles(service, "proforma") ||
        (!isFlagNeedles(service, "proforma") && !generateCustomsData));

const getOutboundServiceFromDefaultList = (services, businessId) =>
  services.find(
    service =>
      service.networkKey === `${businessId}^11` ||
      service.networkKey === "2^12" ||
      service.networkKey === `${businessId}^19` ||
      service.networkKey === `${businessId}^50` ||
      service.networkKey === `${businessId}^41`
  ) || {};

// @see: https://it.dpduk.live/it/diagram/diag_iFsP4R6C48kIGDG8.html
export const getDefaultOutboundService = ({
  services,
  authUser,
  preferences,
  profile,
  createShipmentValues,
  selectedCountry,
  prevService,
  setupDefaultServiceFromList = true,
}) => {
  const selectedNetworkByPrevConfiguration =
    prevService &&
    getServiceByProductAndServiceKeys(
      services,
      prevService?.product?.productKey,
      prevService?.service?.serviceKey
    );

  if (selectedNetworkByPrevConfiguration) {
    return selectedNetworkByPrevConfiguration;
  }

  const businessId = get(authUser, "user.businessId");
  const { domService, domProduct } = get(
    preferences,
    "shippingDefaults.domestic"
  );
  const intProduct = get(
    preferences,
    "shippingDefaults.international.intProduct"
  );
  const {
    useMyDpdAccountSettings,
    defaultDpdInternationalOutboundService,
    defaultDpdOffshoreExchangeService,
    defaultDpdOffshoreOutboundService,
    defaultDpdExchangeOutboundService,
    defaultDpdDomesticOutboundService,
    defaultDpdLocalInternationalOutboundService,
    defaultInterlinkDomesticOutboundService,
    defaultInterlinkOffshoreOutboundService,
  } = profile;
  const postcode = getValue(
    createShipmentValues,
    ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.POSTCODE,
    ""
  );
  const countryCode = get(selectedCountry, "countryKey");
  const shipmentType = Number.parseInt(
    get(createShipmentValues, "shipmentType")
  );
  const isDomestic = AddressModels.isCountryDomestic(countryCode);
  const isPostcodeOffshoreFlag = postcodeModels.isPostcodeOffshore(
    postcode,
    countryCode
  );

  let defaultProduct = "";
  let defaultService = "";
  let defaultDomesticNetworkKey;
  let defaultIntNetworkKey;
  let currentService;

  if (!useMyDpdAccountSettings) {
    if (businessId === 1) {
      // DPD
      if (isDomestic) {
        // DOMESTIC
        if (isPostcodeOffshoreFlag) {
          defaultDomesticNetworkKey =
            shipmentType === SHIPMENT_TYPES.SWAP_IT
              ? defaultDpdOffshoreExchangeService
              : defaultDpdOffshoreOutboundService;
        } else {
          defaultDomesticNetworkKey =
            shipmentType === SHIPMENT_TYPES.SWAP_IT
              ? defaultDpdExchangeOutboundService
              : defaultDpdDomesticOutboundService;
        }
      } else {
        // INTERNATIONAL
        defaultIntNetworkKey =
          defaultDpdInternationalOutboundService || intProduct;
      }
    } else {
      // DPD LOCAL
      if (isDomestic) {
        // DOMESTIC
        defaultDomesticNetworkKey = isPostcodeOffshoreFlag
          ? defaultInterlinkOffshoreOutboundService
          : defaultInterlinkDomesticOutboundService;
      } else {
        // INTERNATIONAL
        defaultIntNetworkKey =
          defaultDpdLocalInternationalOutboundService || intProduct;
      }
    }

    const service = services.find(
      ({ networkKey }) => networkKey === defaultDomesticNetworkKey
    );

    if (service) {
      defaultProduct = service.product?.productKey;
      defaultService = service.service?.serviceKey;
    }
  } else {
    if (isDomestic) {
      if (services.length !== 0) {
        defaultProduct = services.find(
          ({ product }) => product.productKey === `${businessId}^${domProduct}`
        )?.product?.productKey;
      }

      if (defaultProduct) {
        const servicesKeyValue = getProductRelatedServicesKeyValue(
          defaultProduct,
          services
        );
        defaultService =
          servicesKeyValue.find(
            service => service.value === `${businessId}^${domService}`
          )?.value || "";
      }
    } else {
      defaultIntNetworkKey = intProduct;
    }
  }

  if (isDomestic) {
    currentService = getServiceByProductAndServiceKeys(
      services,
      defaultProduct,
      defaultService
    );
  } else {
    currentService = services.find(
      ({ networkKey }) => networkKey === defaultIntNetworkKey
    );
  }

  return (
    currentService ||
    (setupDefaultServiceFromList
      ? getOutboundServiceFromDefaultList(services, businessId)
      : {})
  );
};

export const setupInitialInboundService = data => {
  const { inboundNetworks, shipment, profile, authUser } = data;
  const inboundConsignment = getValue(shipment, INBOUND_CONSIGNMENT, {});

  if (isEmpty(inboundConsignment)) {
    return {
      ...data,
      shipment,
    };
  }

  const inboundNetwork =
    inboundNetworks?.find(
      service => service.networkKey === shipment.inboundConsignment.networkCode
    ) ||
    getDefaultInboundService({
      profile,
      services: inboundNetworks,
      createShipmentValues: shipment,
      authUser,
    });

  set(inboundConsignment, Fields.NETWORK_CODE, inboundNetwork?.networkKey);
  set(
    inboundConsignment,
    Fields.TOTAL_WEIGHT,
    roundTotalWeight(
      get(shipment, ShipmentEntity.INBOUND_CONSIGNMENT.TOTAL_WEIGHT),
      get(
        shipment,
        ShipmentEntity.INBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS.COUNTRY_CODE
      ),
      inboundNetwork?.networkKey,
      true
    )
  );
  // NOTE: blur this field convert value to string
  // as result serviceQuery and newServiceQuery will be different and launch redundant fetching services
  set(
    inboundConsignment,
    Fields.NUMBER_OF_PARCELS,
    get(
      shipment,
      ShipmentEntity.INBOUND_CONSIGNMENT.NUMBER_OF_PARCELS
    )?.toString()
  );

  return {
    ...data,
    shipment: {
      ...shipment,
      inboundConsignment,
    },
  };
};

// @see: comments from https://geopost.jira.com/browse/CSHIP-6432
export const findPreviousSelectedNetwork = ({
  services = [],
  networkKey,
  serviceKey,
  findByServiceKey,
  csvNetworkCode,
}) => {
  if (findByServiceKey) {
    return services?.data?.find(
      service => service.service.serviceKey === serviceKey
    );
  }

  // do it after looking for by findByServiceKey
  if (csvNetworkCode) {
    // @see: comments from https://geopost.jira.com/browse/CSHIP-6433
    return services?.data?.find(
      service => service.networkKey === csvNetworkCode
    );
  }

  return services?.data?.find(service => service.networkKey === networkKey);
};

export const isDpdDirectService = service =>
  [...DPD_DIRECT_PARCEL_NETWORKS, ...DPD_DIRECT_LITE].includes(
    getValue(service, "networkKey", "")
  );

export const hasShipToShopNotify = service => {
  const networkKey = getValue(service, "networkKey", "");
  return (
    SHIP_TO_SHOP.includes(networkKey) &&
    isFlagOptional(service, "notifyRequired")
  );
};
