import { round } from "lodash";
import PropTypes from "prop-types";

import { CLOSED, OPEN } from "~/constants/strings";
import { getOpenStatusObject, getPickupPointType } from "~/models/pickupPoint";

import styles from "./PickupDetails.module.scss";

const PickupDetails = ({ pickupPoint, displayOpenClosedDetails }) => {
  const { isOpen, openStatus } = getOpenStatusObject(pickupPoint);

  return (
    <div className={styles.detailsContainer}>
      <span>{getPickupPointType(pickupPoint)}</span>
      <span>{`${round(pickupPoint.distance, 1)}mi`}</span>
      {displayOpenClosedDetails && (
        <>
          <span className={isOpen ? styles.open : styles.closed}>
            {isOpen ? OPEN : CLOSED}
          </span>
          <span>{openStatus}</span>
        </>
      )}
    </div>
  );
};

PickupDetails.propTypes = {
  pickupPoint: PropTypes.object,
  displayOpenClosedDetails: PropTypes.bool,
};

export default PickupDetails;
