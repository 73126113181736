import { Fields } from "~/constants/forms";
import { formatMessage } from "~/utils/string";

// COMMON
export const DPD_DIRECT_SERVICE = "80";

// Shipping menu
export const CREATE_SHIPMENT = "Create Shipment";
export const SHIPMENT_REVIEW = "Shipment Review";
export const CONTAINER = "Container";
export const IMPORTS = "Imports";
export const IMPORT_ACTIVITY = "Import Activity";
export const FAILED_IMPORTS = "Failed Imports";
export const TOOLS = "Tools";
export const SCANNING = "Scanning";
export const GROUP_DISPATCH = "Group Dispatch";
export const BULK_REVERSE_IT = "Bulk ReverseIt";

// Titles
export const PACKAGE_DETAILS = "Package Details";
export const SHIPMENT_DETAILS = "Shipment Details";
export const CONTAINER_LABELS = "Container Labels";
export const RECEIVER_DETAILS = "Receiver Details";

// Labels
export const TOTAL_WEIGHT = "Total weight (kg)";
export const PRODUCT_TYPE = "Product Type";
export const COMMODITY_CODE = "Commodity Code";
export const POSTCODE = "Postcode";
export const ORGANISATION_OR_NAME = "Organisation / Name";
export const ADDRESS_LINE_1 = "Address Line 1";
export const ADDRESS_LINE_2 = "Address Line 2";
export const CITY = "City";
export const PRODUCT = "Product";
export const SHIPMENT_DATE = "Shipment Date";
export const EXTENDED_LIABILITY = "Extended Liability";
export const TYPE_OF_LABELS = "Type of Labels";
export const ACCOUNT = "Account";
export const PROFILE = "Profile";
export const NOTIFICATION_EMAIL = "Notification Email";
export const CLICK_TO_TRACK = "Click to Track";
export const NOT_PRINTED = "Not printed";
export const TOTAL_PACKAGES_AND_WEIGHT = "Total Packages & Weight";

// CREATE SHIPMENT

// Step titles
export const DELIVERY_DETAILS_TITLE = "Delivery Details";
export const SHIPPING_INFORMATION_TITLE = "Customs - Shipping Information";
export const DELIVERY_CONTACT_TITLE = "Delivery Contact";
export const SHIPMENT_REVIEW_TITLE = "Review Your Shipment";
export const RETURN_DETAILS_TITLE = "Return Details";
export const RETURN_CONTACT_TITLE = "Return Contact";
export const PACKAGE_RETURN_DETAILS = "Package Return Details";
export const CUSTOMS_PACKAGE_CONTENTS_PRODUCT_DETAILS_TITLE =
  "Customs - Package Contents & Product Details";

// New step titles
export const SHIPMENT_DETAILS_TITLE = "Shipment Details";
export const REVIEW_YOUR_SHIPMENT = "Review Your Shipment";

// Section titles
export const ADDRESS_DETAILS = "Address Details";
export const CONTACT_DETAILS = "Contact Details";
export const PARCEL_DETAILS = "Parcel Details";
export const PACKAGE_SERVICE_DETAILS = "Package/Service Details";
export const INVOICE_DETAILS = "Invoice Details";
export const RETURN_ADDRESS_DETAILS = "Return Address Details";
export const RETURN_CONTACT_DETAILS = "Contact Details";
export const RETURN_PARCEL_DETAILS = "Parcel Details";
export const RETURN_PACKAGE_SERVICE_DETAILS = "Package/Service Details";
export const PRODUCT_DESCRIPTIONS = "Product Description(s)";

// Titles
export const EXPORTER_INVOICE_DETAILS = "Exporter’s Invoice Details";
export const IMPORTER_INVOICE_DETAILS = "Importer’s Invoice Details";
export const ALL_FIELDS_MARKED_REQUIRED =
  "All Fields Marked With * Are Required";
export const RETURN_PACKAGE = "Return Package";
export const RETURN_DETAILS = "Return Details";
export const EDIT_EXPORTER = "Edit Exporter";
export const EDIT_IMPORTER = "Edit Importer";
export const WARNING = "Warning";

// Placeholders
export const PLEASE_SELECT = "Please Select";

// Labels
export const CLEAR = "Clear";
export const DELIVERY = "Delivery";
export const COLLECTION = "Collection";
export const PRINT_LATER = "Print Later";
export const PRINT_NOW = "Print Now";
export const PRINT = "Print";
export const ADDRESS_BOOK = "Address Book";
export const PRODUCT_BOOK = "Product Book";
export const SEARCH = "Search";
export const CONFIRM_PLEASE = "Confirm please";
export const SEARCH_BY = "Search By";
export const SEARCH_TERMS = "Search Terms";
export const NUMBERS_OF_LABELS = "Numbers of Labels";
export const EDIT = "Edit";
export const DESTINATION_COUNTRY = "Destination Country";
export const POSTAL_ZIP_CODE = "Postal / Zip code";
export const FIND_POSTCODE = "Find Postcode";
export const ORGANISATION = "Organisation";
export const ORG_NAME = "Org/Name";
export const YOUR_REFERENCE = "Your reference(s)";
export const YOUR_REFERENCE_1 = "Your reference 1";
export const YOUR_REFERENCE_2 = "Your reference 2";
export const YOUR_REFERENCE_3 = "Your reference 3";
export const COUNTY_STATE = "County / State";
export const DELIVERY_INFORMATION = "Delivery Information";
export const CONTACT_NAME = "Contact Name";
export const CONTACT_TELEPHONE = "Contact Telephone";
export const SHORT_NAME = "Short Name";
export const TELEPHONE_NUMBER = "Telephone number";
export const EMAIL_ADDRESS = "email address";
export const MOBILE_NOTIFICATION_TEXT = "Mobile Notification Text";
export const NOTIFICATION_EMAIL_OR_MOBILE_TEXT = `${NOTIFICATION_EMAIL} or ${MOBILE_NOTIFICATION_TEXT}`;
export const TOTAL_PACKAGES_NUMBER = "Total Number of Packages";
export const SERVICE = "Service";
export const PRODUCT_SERVICE = "Product/Service";
export const DATE = "Date";
export const REMEMBER_DATE = "Remember Date";
export const ITEM_DESCRIPTION = "Item Description";
export const LIABILITY_VALUE_$ = "Liability Value ({0})";
export const LIABILITY_VALUE = "Liability Value";
export const ADD_ADDRESS_BOOK = "Add to address book";
export const UPDATE_ADDRESS_BOOK = "Update address book";
export const COLLECT_DELIVERY = "Collect On Delivery";
export const GENERATE_CUSTOMS_DATA = "Generate Customs Data";
export const INVOICE_TYPE = "Type Of Shipment";
export const REASON_FOR_EXPORT = "Reason For Export";
export const INTERNATIONAL_BILLING_TERMS = "International Billing Terms";
export const INVOICE_REFERENCE = "Invoice Reference";
export const SHIPPING_COST_$ = "Shipping/Freight Cost ({0})";
export const EXPORTERS = "Exporter’s";
export const IMPORTERS = "Importer’s";
export const PID = "PID";
export const EORI = "EORI";
export const VAT = "VAT";
export const UKIMS = "UKIMS";
export const $_$_NUMBER = "{0} {1} Number";
export const DEST_TAX_ID_REG_NUMBER = "Destination Tax ID/Reg Number";
export const FDA_NUMBER = "FDA Registration Number";
export const GST_VAT_PAID = "GST/VAT Paid";
export const IMPORTER_EMAIL = "Importer Email";
export const IMPORTER_TELEPHONE = "Importer Telephone";
export const IMPORTER_EMAIL_OR_IMPORTER_TELEPHONE = `${IMPORTER_EMAIL} or ${IMPORTER_TELEPHONE}`;
export const PKG_NUMBER = "PKG N°";
export const NUMBER = "N°";
export const VALUE_$ = "VALUE ({0})";
export const PRODUCT_DESCRIPTION = "Product Description";
export const TOTAL_VALUE = "Total Value";
export const SHIPPING_FREIGHT_COST = "Shipping/Freight Cost";
export const TOTAL_PACKAGES = "Total Packages";
export const PACKAGE_CONTENTS_DECLARED = "Package Contents Declared";
export const TOTAL_DECLARED_VALUE = "Total Declared Value";
export const TOTAL_PRODUCT_UNITS = "Total Product Units";
export const TOTAL_DECLARED_PRODUCTS = "Total Declared Products";
export const ADD_NEW_PRODUCT = "Add New Product";
export const DELETE = "Delete";
export const VOID = "Void";
export const PACKAGE_NUMBER = "Package Number";
export const SELECT_PACKAGE = "Select the package which contains this product";
export const PRODUCT_CODE = "Product Code";
export const PRODUCT_URL = "Product URL";
export const ENTER_PRODUCT_CODE_IDENTIFIER =
  "Enter the product code identifier (SKU)";
export const PRODUCT_TYPE_EXAMPLE = "Example: Women’s bags/men’s shoes";
export const ENTER_PRODUCT_URL = "Enter product URL";
export const DETAILED_PRODUCT_DESCRIPTION = "Detailed Product Description";
export const DETAILED_PRODUCT_DESCRIPTION_EXAMPLE =
  "Example: Men’s patent leather shoes";
export const PRODUCT_COMPOSITION = "Product Composition";
export const PRODUCT_COMPOSITION_EXAMPLE = "Example: Woven 100% silk";
export const COUNTRY_OF_ORIGIN = "Country Of Origin";
export const CLARIFY_YOUR_GOODS =
  "Classify your goods correctly for your export declaration";
export const PRODUCT_UNIT_WEIGHT = "Product Unit Weight (Kg)";
export const MAXIMUM_TOTAL_WEIGHT_PER_PACKAGE =
  "The maximum total weight per package is 30Kg";
export const PRODUCT_QUANTITY = "Product Quantity";
export const TOTAL_PRODUCT_QUANTITY_ITEMS_PER_PARCEL =
  "The total quantity of product items for this parcel";
export const PRODUCT_UNIT_VALUE_$ = "Product Unit Value ({0})";
export const INDIVIDUAL_VALUE_FOR_EACH_PRODUCT_IN_PARCEL =
  "The individual value for each product in this parcel";
export const TOTAL_PRODUCT_VALUE = "Total Product Value";
export const PRODUCT_UNIT_VALUE = "Product Unit Value";
export const WEBSITE_ADDRESS = "Website Address";
export const SAVE_PRODUCT = "Save Product";
export const CUSTOMS_VALUE = "Customs Value";
export const CONTENTS_DESCRIPTION = "Contents Description";
export const IMPORTER_TYPE = "Importer Type";
export const BUSINESS = "Business";
export const CURRENCY = "Currency";
export const CONSUMER = "Consumer";
export const SAVE_TO_PRODUCT_BOOK = "Save To Product Book";
export const TOWN = "Town";
export const LOCALITY = "Locality";
export const EXPORTER_COUNTRY = "Exporter Country";
export const IMPORTER_COUNTRY = "Importer Country";
export const SENDER_REFERENCE = "Sender Reference";
export const DELIVERY_COUNTRY_CODE = "delivery country code";
export const PICKUP_LOCATION_CODE = "Pickup Location Code";
export const ADDRESS_$ = "Address {0}";
export const AT_RISK_OF_EU = "At Risk of Entering EU?";
export const ADD = "Add";
export const CURRENT_DESCRIPTIONS = "Current descriptions";

// Messages
export const ENTER_OR_SELECT_DESTINATION_COUNTRY =
  "Enter or select the destination country";
export const MANDATORY_INFORMATION_IS_NEEDED =
  "Mandatory information is needed to contact the receiver where duties/tax are payable before delivery:";
export const DESTINATION = "Destination";
export const PLACE_LABEL_ON_CONTAINER =
  "Please place the label on a green bag or container.";
export const MAX_$_CHARACTERS = "Max {0} characters";
export const MAX_2_CHARACTERS = formatMessage(MAX_$_CHARACTERS, 2);
export const MAX_15_CHARACTERS = formatMessage(MAX_$_CHARACTERS, 15);
export const MAX_25_CHARACTERS = formatMessage(MAX_$_CHARACTERS, 25);
export const MAX_30_CHARACTERS = formatMessage(MAX_$_CHARACTERS, 30);
export const MAX_32_CHARACTERS = formatMessage(MAX_$_CHARACTERS, 32);
export const MAX_35_CHARACTERS = formatMessage(MAX_$_CHARACTERS, 35);
export const MAX_50_CHARACTERS = formatMessage(MAX_$_CHARACTERS, 50);
export const MAX_100_CHARACTERS = formatMessage(MAX_$_CHARACTERS, 100);
export const MAX_150_CHARACTERS = formatMessage(MAX_$_CHARACTERS, 150);
export const MAX_45_CHARACTERS = formatMessage(MAX_$_CHARACTERS, 45);
export const MAX_200_CHARACTERS = formatMessage(MAX_$_CHARACTERS, 200);
export const MAX_15_CHARACTERS_INCLUDING_SPACES = `${MAX_15_CHARACTERS} including spaces`;
export const ROUNDED_UP = "Round up to the nearest 0.5Kg";
export const ROUND_UP_TO_THE_NEAREST_WHOLE_NUMBER =
  "Round up to the nearest whole number";
export const PLEASE_SELECT_$ = "Please select {0}";
export const YOUR_PRODUCT_TYPE = "your product type";
export const YOUR_PRODUCT_SERVICE = "your product service";
export const COLLECTION_TYPE = "collection type";
export const ONLY_CSV = "only .csv files";
export const $_HAS_INVALID_TYPE = "{0} has invalid type";

export const FORWARD_SHIPMENT_DATE = "Forward date your shipment";
export const SELECT_PRINT_DATE = "Select print date";
export const LOCK_SHIPMENT_DATE = "Locks selected shipment date";
export const PROTECT_AGAINST_LOSS_DAMAGE = "Protect against loss and damage";
export const YOUR_SHIPMENT_VALUE = "What is the value of your shipment?";

export const PRODUCT_AND_SERVICE_COMBINATION_INVALID =
  "The Product and Service combination is invalid";
export const VALID_EXPORTERS_EORI_NO_MUST_BE_ENTERED =
  "A valid Exporter’s EORI Number must be entered";
export const DELAY_IN_$_POSTCODE_AREA = "Delay in your {0} Postcode Area";
export const DELIVER = "deliver";
export const COLLECT = "collect";
export const DELAY_POSTCODE_AREA_MESSAGE_$_$_$ =
  "Due to {0} in the area we are unable to {2} your parcel. The current delay is {1} day(s), however you can still prepare your shipments and we will {2} them as soon as we are able to gain access. Apologies for any inconvenience this may have caused.";
export const CANCEL = "Cancel";
export const CONTINUE = "Continue";
export const SAVE = "Save";
export const CUSTOMS_INVOICE_IS_REQUIRED =
  "A customs invoice is required for the selected country, which must accompany the shipment at all times. \n \n <p style='color:#DC0032';> Please remember to print 3 copies and attach to the outside of the parcel in a ‘documents enclosed wallet’.</p>";
export const CUSTOMS_DATA_IS_REQUIRED =
  "Customs data is required for the selected country";
export const GB = "GB";
export const UK = "UK";
export const UNITED_KINGDOM = "UNITED KINGDOM";
export const IE = "IE";
export const US = "US";
export const UPPERCASE = "uppercase";
export const NONE = "none";
export const PLEASE_EDIT_ENTER_DETAILS = "please edit & enter details";
export const EORI_MAX_14_ERROR_MESSAGE =
  "Importer's EORI No must be a maximum of 14 characters";
export const COMMODITY_CODE_STRICT_ERROR_MESSAGE = "Commodity code is invalid";
export const IOSS_ERROR_MESSAGE =
  "The service and destination country option chosen is unavailable for one of the following reasons. \n\n EITHER \n\n The destination selected is not available to a consumer without an IOSS VAT ID number for goods valued below €150. Please enter your IOSS VAT ID to proceed or select an air service. \n\n OR \n\n The destination is not to a business address with a customs value below €150. Please select an air service to proceed.";
export const PRINT_INVOICE_NOW_CONFIRMATION_MESSAGE =
  "Customs information has been captured based on the information entered, would you like to print a copy for your records?";
export const COMMODITY_CODE_ERROR = "Commodity Code Error";
export const COMMODITY_CODE_ERROR_MESSAGE = error => (
  <>
    {error}, please enter a valid commodity code
    <br />
    <br />
    For more support in classifying products visit{" "}
    <a href="https://www.gov.uk/trade-tariff" target="_blank" rel="noreferrer">
      https://www.gov.uk/trade-tariff
    </a>
    <br />
    DPD accepts no liability for any inaccuracy of a duty calculation or a delay
    or return of a parcel as the consequence of the code supplied.
  </>
);

export const FAILED_TO_VALIDATE_COMMODITY = "Failed to validate commodity code";
export const AUTO_CALCULATED_VALUE = "Auto calculated value";
export const PLEASE_REMEMBER_TO_PLACE_ALL_PACKAGES =
  "Please remember to place all packages in a green bag or container.";
export const INVALID_COUNTRY_ENTERED = "You’ve entered an invalid country name";
export const CANNOT_BE_UK =
  "Invalid Importer Invoice Address, cannot be a UK address";
export const TEST_IMPORT_FILE_HELP_MESSAGE =
  "This will tell you if the structure of your file is correct or contains errors";
export const INFO_CARD_MESSAGE =
  "Remember to print 3 copies of your invoice for customs clearance";
export const TOTAL_NEW_PARCELS_ERROR =
  "Number of new parcels added to current number of parcels must be less than 99";
export const DATE_IS_NON_WORKING_DAY =
  "The specified shipment date is non working day";
export const SHIPMENT_DATE_GREATER_THAN_5_DAYS_IN_FUTURE =
  "The specified Shipment Date is greater than 5 days in future";
export const SHIPMENT_NOT_FOUND = "Shipment is not found";
export const PLEASE_SAVE_$_BEFORE_PRINTING =
  "Please save changes in ‘{0}’ before printing";

export const GBUNREG = "GBUNREG";
export const MANDATORY_INFORMATION = "Mandatory Information";
export const IMPORTER_BUSINESS = "BUSINESS";
export const IMPORTER_CONSUMER = "CONSUMER";
export const GBP = "GBP";
export const EUR = "EUR";

export const IS_GOING_TO_BUSINESS_OR_CONSUMER =
  "Is this going to a business or consumer";
export const PLEASE_DESCRIBE_YOUR_PRODUCT = "Please describe your product";

// Snackbars
export const FAILED_TO_FETCH = "Failed to fetch";
export const SOMETHING_WENT_WRONG = "Something went wrong";
export const FAILED_TO_FETCH_ERROR_MESSAGE_$ = `${FAILED_TO_FETCH} {0}`;
export const COUNTRIES = "countries";
export const EXPORT_REASONS = "export reasons";
export const ADDRESS_BOOKS = "address books";
export const PRODUCT_BOOKS = "product books";
export const POSTCODE_ISSUES = "postcode issues";
export const POSTCODES = "postcodes";
export const OUTBOUND_SERVICES = "outbound services";
export const INBOUND_SERVICES = "inbound services";
export const DEFAULT_ADDRESS_BOOK = "default address book";
export const PROFILES = "profiles";
export const COLLECTIONS = "collections";
export const LABELS = "labels";
export const INVOICE = "invoice";
export const NUMBER_LABELS_VALIDATION_ERROR =
  "Number of labels must be more than 0";
export const UNPRINTED_LABELS = "unprinted labels";
export const CONSIGNMENT_NUMBER_COPIED =
  "Consignment number copied to clipboard";
export const COMMODITY_CODES = "commodity codes";
export const ADDRESS_BOOK_ENTITY = "address book";
export const CREATE_$ = "You have successfully created {0}.";
export const EDIT_$ = "You have successfully edited {0}.";
export const CREATE_ADDRESS_BOOK = formatMessage(CREATE_$, ADDRESS_BOOK);
export const CREATE_PRODUCT_BOOK = formatMessage(CREATE_$, PRODUCT_BOOK);
export const EDIT_ADDRESS_BOOK = formatMessage(EDIT_$, ADDRESS_BOOK);
export const EDIT_PRODUCT_BOOK = formatMessage(EDIT_$, PRODUCT_BOOK);
export const SHIPMENT_ENTITY = "shipment";
export const FAILED_TO_EDIT_SHIPMENT = "Failed to edit the shipment";
export const NETWORKS = "networks";

// Banners
export const SHIPMENT_SAVED_SUCCESSFULLY =
  "Your shipment has been saved successfully. Your consignment number is:";
export const COPY_NUMBER = "Copy number";
export const ARRANGE_COLLECTION_FOR_WEEKEND =
  "When shipping parcels Saturday and Sunday, please make sure you have arranged a collection";
export const ARRANGE_COLLECTION_$ =
  "You currently do not have a collection raised for {0}. If you would like to raise a collection now please";
export const SHIPMENT_CURRENTLY_EDITING =
  "You are currently editing consignment";
export const SHIPMENT_UPDATED_SUCCESSFULLY =
  "You successfully edited consignment";
export const YOU_DO_NOT_HAVE_$_TO_$ = "You do not have {0} to {1}";
export const NO_PERMISSIONS_TO_CREATE_UPDATE_SHIPMENT = formatMessage(
  YOU_DO_NOT_HAVE_$_TO_$,
  "permissions",
  "create/update the shipment"
);
export const SUFFICIENT_PRIVILEGES = "sufficient privileges ";
export const NO_PRIVILEGES_TO_ACCESS_SYSTEM = formatMessage(
  YOU_DO_NOT_HAVE_$_TO_$,
  SUFFICIENT_PRIVILEGES,
  "access this system"
);
export const NO_PERMISSIONS_TO_CREATE_SHIPMENT = formatMessage(
  YOU_DO_NOT_HAVE_$_TO_$,
  SUFFICIENT_PRIVILEGES,
  "create the shipment"
);
export const CLICK_TO_CONFIGURE = "Click here to configure";
export const REFRESH_STATUS = "Refresh status";

export const joiMessages = {
  any: {
    required: "{{label}} must be entered",
    allowOnly: "{{label}} is invalid",
  },
  string: {
    max: "{{label}} must be a maximum of {{limit}} characters",
    min: "{{label}} must be more than {{limit}} characters",
    empty: "{{label}} must be entered",
    regex: {
      name: "A valid {{label}} must be entered",
    },
  },
  number: {
    base: "{{label}} must be a number",
    min: "{{label}} must be more than {{limit}}",
    max: "{{label}} must be a maximum of {{limit}}",
    greater: "{{label}} must be greater than {{limit}}",
    less: "{{label}} must be equal or less than {{limit}}",
  },
  boolean: {
    base: "{{label}} is invalid",
  },
};

// Joi Validation
export const $_MUST_BE_ENTERED = "{0} must be entered";
export const $_MUST_BE_VALID_$ = "{0} must be a valid {1}";
export const $_IS_NOT_VALID = "{0} is not valid";

export const POSTCODE_IS_NOT_VALID = "Postcode is not a valid Postcode";
export const WEIGHT_PER_PARCEL_ERROR =
  "Weight per Parcel should be equal or less then 30KG";
export const DECREASED = "decreased";
export const INCREASED = "increased";
export const EDIT_NUMBER_OF_PACKAGES_ERROR_$_CANNOT_$ =
  "The number of parcels ({0}) cannot be {1}";
export const NO_PRODUCTS_AND_SERVICES_FOUND_$ =
  "No products and services found ({0})";
export const MAXIMUM_NUMBER_OF_PARCELS_FOR_$_IS_$ = `${NO_PRODUCTS_AND_SERVICES_FOUND_$}; the maximum number of parcels allowed for {1} is {2}`;
export const TOTAL_NUMBER_OF_PARCELS_SHOULD_BE_EQUAL_$ =
  "Total Number of Packages Should be equal to {0} ";
export const DELIMITERS_FIELDS_ERROR_$ = "{0} and {1} cannot be the same";
export const TOTAL_NUMBER_OF_PARCELS_SHOULD_BE_LESS_OR_EQUAL_$ =
  "Total Number of Packages Should be less or equal to {0} ";
export const UNVOID_TO_PROCEED =
  "Unable to increase parcel count, unvoid parcels to proceed.";
export const $_OR_$_MUST_BE_PROVIDED = "{0} or {1} must be provided";
export const $_MUST_BE_PROVIDED_WITH_$ = "{0} must be provided with {1}";
export const $_LENGTH_MUST_BE_$_LONG = "{0} length must be {1} characters long";

export const CURRENCY_TO_SYMBOL = {
  GBP: "£",
  USD: "$",
  EUR: "€",
};

export const BOOLEAN_TO_STRING = {
  true: "Yes",
  false: "No",
};

export const BOOLEAN_TO_VOIDED_STATUS = {
  true: "Voided",
  false: "Open",
};

export const BOOLEAN_TO_MANDATORY = {
  true: "Yes",
  false: "-",
};

export const DEFAULT_BOOLEAN_KEY_VALUE = [
  {
    label: "Yes",
    value: "1",
  },
  {
    label: "No",
    value: "0",
  },
];

export const BUSINESS_KEY_VALUE = {
  label: IMPORTER_BUSINESS,
  value: true,
};

export const CONSUMER_KEY_VALUE = {
  label: IMPORTER_CONSUMER,
  value: false,
};

export const IMPORTER_TYPE_KEY_VALUE = [BUSINESS_KEY_VALUE, CONSUMER_KEY_VALUE];

export const CONTAINER_LABEL_TYPES = [
  {
    label: "DPD Direct",
    value: "1",
  },
];

export const DEFAULT_ZERO_VALUE = "0.00";

export const DEFAULT_INFORMATIONS = [
  "defaultInformation1",
  "defaultInformation2",
  "defaultInformation3",
  "defaultInformation4",
  "defaultInformation5",
];

// SHIPMENT REVIEW

// Titles
export const SHIPMENT_REVIEW_PAGE_TITLE = "Shipment Review";
export const OUTBOUND = "Outbound";
export const INBOUND = "Inbound";
export const COLLECTION_INFORMATION = "Collection Information";
export const SENDER_INFORMATION = "Notification/Sender Information";
export const CUSTOMS = "Customs";
export const CUSTOMS_DETAILS = "Customs Details";
export const CUSTOMS_NOTICE = "Customs Notice";
export const PACKAGES = "Packages";
export const RETURN = "Return";
export const ADD_EXTRA_LABEL = "Add Extra Label";

// Labels
export const PRODUCT_NUMBER = "Product number";
export const DETAILED_DESCRIPTION = "Detailed description";
export const UNIT_WEIGHT = "Unit weight";
export const UNIT_VALUE = "Unit value";
export const QUANTITY = "Quantity";
export const PARCEL = "Parcel";
export const PARCELS = "Parcels";
export const STATUS = "Status";
export const COUNTRY = "Country";
export const COUNTY = "County";
export const CONTACT = "Contact";
export const TELEPHONE = "Telephone";
export const EMAIL = "Email";
export const MOBILE = "Mobile";
export const CONSIGNMENT_NUMBER = "Consignment No.";
export const PRINTED = "Printed";
export const VOIDED = "Voided";
export const MANIFESTED = "Manifested";
export const ADDITIONAL_INFO = "Additional Info";
export const CUSTOMS_VALUE_$ = "Customs Value ({0})";
export const CONTENTS = "Contents";
export const NOTIFICATION_TEXT = "Notification Text";
export const PROFILE_CODE = "Profile Code";
export const PROFILE_NAME = "Profile Name";
export const REFERENCE_$ = "Reference {0}";
export const CREATED_DATE = "Created Date";
export const CONSIGNMENTS = "Consignments";
export const TOTAL_NEW_PARCELS = "Total New Parcels";
export const TOTAL_WEIGHT_OF_NEW_PARCELS = "Total Weight Of New Parcels";

// Buttons
export const UNVOID = "Unvoid";
export const HOLD = "Hold";
export const UNHOLD = "Unhold";
export const SEND_TO_FILE = "Send To File";
export const CHANGE_DATE = "Change Date";
export const PRINT_SHIPMENT = "Print Shipment";
export const VOID_SELECTED = "Void Selected";
export const UNVOID_SELECTED = "Unvoid Selected";
export const REPRINT = "Reprint";
export const RETURN_TO_LIST = "Return To List";
export const EDIT_SHIPMENT = "Edit Shipment";
export const COPY_SHIPMENT = "Copy Shipment";
export const PRINT_UNPRINTED = "Print Unprinted";
export const PRINT_MANIFEST = "Print Manifest";
export const GENERATE_MANIFEST = "Generate Manifest";
export const ADD_ALL_FIELDS = "Add All Fields >";
export const ADD_FIELD = "Add Field >";
export const REMOVE_ALL_FIELDS = "< Remove All Fields";
export const REMOVE_FIELD = "< Remove Field";
export const MOVE_UP = "Move Up";
export const MOVE_DOWN = "Move Down";
export const REPRINT_INVOICE = "Reprint Invoice";
export const CREATE_LABELS = "Create Labels";
export const RETRY = "Retry";
export const GO_BACK = "Go back";

// Messages
export const VOID_PARCELS = "Void parcels";
export const UNVOID_PARCELS = "Unvoid parcels";
export const SHOWING = "Showing";
export const SHIPMENT = "Shipment";
export const MANIFEST = "Manifest";
export const SUCCESS = "Success!";
export const NO_SHIPMENTS_TO_MANIFEST = "No shipments to manifest";
export const MANIFEST_NOTHING_TO_PROCESS =
  "Nothing to process. There are no shipments to manifest.";
export const SHIPMENTS_VOIDED = "Shipments Voided";
export const SHIPMENTS_HELD = "Shipments Held";
export const SHIPMENTS_UNHELD = "Shipments Unheld";
export const PARCELS_VOIDED = "Parcels voided";
export const PARCEL_VOIDED = "Parcel voided";
export const PARCELS_UNVOIDED = "Parcels unvoided";
export const PARCEL_UNVOIDED = "Parcel unvoided";
export const SHIPMENT_VOIDED = "Shipment Voided";
export const SHIPMENT_HELD = "Shipment Held";
export const SHIPMENT_UNHELD = "Shipment Unheld";
export const SHIPMENTS_VOIDED_MESSAGE =
  "Shipments have been successfully voided";
export const SHIPMENTS_HELD_MESSAGE = "Shipments have been successfully held";
export const SHIPMENTS_UNHELD_MESSAGE =
  "Shipments have been successfully unheld";
export const $_PARCELS_VOIDED_MESSAGE =
  "Parcels {0} have been successfully voided";
export const $_PARCEL_VOIDED_MESSAGE = "{0} has been successfully voided";
export const $_PARCELS_UNVOIDED_MESSAGE =
  "Parcels {0} have been successfully unvoided";
export const $_PARCEL_UNVOIDED_MESSAGE = "{0} has been successfully unvoided";
export const $_SHIPMENT_VOIDED_MESSAGE =
  "Your shipment with consignment {0} has been successfully voided";
export const $_SHIPMENT_HELD_MESSAGE =
  "Your shipment with consignment {0} has been successfully held";
export const $_SHIPMENT_UNHELD_MESSAGE =
  "Your shipment with consignment {0} has been successfully unheld";
export const SHIPMENTS_UNVOIDED = "Shipments Unvoided";
export const SHIPMENT_UNVOIDED = "Shipment Unvoided";
export const SHIPMENTS_UNVOIDED_MESSAGE =
  "Shipments have been successfully unvoided";
export const $_SHIPMENT_UNVOIDED_MESSAGE =
  "Your shipment with consignment {0} has been successfully unvoided";
export const SHIPMENTS_DATE_CHANGED = "Shipments Date Changed";
export const SHIPMENT_DATE_CHANGED = "Shipment Date Changed";
export const SHIPMENTS_DATE_CHANGED_MESSAGE_TO_$ =
  "Shipments date has been successfully changed to {0}";
export const $_SHIPMENT_DATE_CHANGED_MESSAGE_TO_$ =
  "Your shipment with consignment {0} date has been successfully changed to {1}";
export const SHIPMENTS_DELETED = "Shipments Deleted";
export const SHIPMENT_DELETED = "Shipment Deleted";
export const SHIPMENTS_DELETED_MESSAGE =
  "Shipments have been successfully deleted";
export const $_SHIPMENT_DELETED_MESSAGE =
  "Your shipment with consignment {0} has been successfully deleted";
export const NO_UNPRINTED_SHIPMENTS = "No Unprinted Shipments";
export const YOU_DONT_HAVE_UNPRINTED_SHIPMENTS =
  "You currently do not have any unprinted shipments to print";
export const PLEASE_COMPLETE_ALL_REQUIRED_FIELDS =
  "Please complete all required fields as indicated *";
export const SHIPMENT_LABELS_ADDED = "Shipment Labels Added";
export const SHIPMENT_LABELS_ADDED_MESSAGE =
  "Shipment labels have been successfully added";
export const CLICK_RETRY =
  "Please correct the following record and click 'Retry' to re-import.";
export const NAVIGATE_TO_IMPORT_ACTIVITY_PAGE =
  "You will need to navigate to Import Activity page to view the activity of your amended record.";
export const TOTAL_PARCELS_$ = "Parcels ready for manifest: Total Parcels: {0}";
export const TOTAL_CONSIGNMENTS_$ = "Total Consignments: {0}";
export const TOTAL_WEIGHT_$ = "Total Weight: {0} Kg";

export const ROW_ID_FIELD_NAME = "shipmentId";
export const ROW_ID_STAGING_FIELD_NAME = "stagingShipmentId";

export const USER_ACCESS = {
  READ_ONLY: "readOnly",
  READ_WRITE: "readAndWrite",
};

export const CONSIGNMENT_DIRECTION = {
  INBOUND: "inbound",
  OUTBOUND: "outbound",
};

export const returnCountries = [GB, IE];

export const DELIVERY_CONTACT_MAP = {
  [Fields.CONTACT_NAME]: CONTACT,
  [Fields.TELEPHONE]: TELEPHONE,
  [Fields.EMAIL]: EMAIL,
  [Fields.MOBILE]: MOBILE,
};

export const EXPORTER_MAP = {
  [Fields.CONTACT_NAME]: CONTACT,
  [Fields.TELEPHONE]: TELEPHONE,
  [Fields.EORI_NUMBER]: formatMessage($_$_NUMBER, EXPORTERS, EORI),
  [Fields.VAT_NUMBER]: formatMessage($_$_NUMBER, EXPORTERS, VAT),
  [Fields.DESTINATION_TAX_ID_REG_NO]: DEST_TAX_ID_REG_NUMBER,
  [Fields.FDA_NUMBER]: FDA_NUMBER,
  [Fields.UKIMS_NUMBER]: formatMessage($_$_NUMBER, EXPORTERS, UKIMS),
};

export const IMPORTER_MAP = {
  ...DELIVERY_CONTACT_MAP,
  [Fields.EORI_NUMBER]: formatMessage($_$_NUMBER, IMPORTERS, EORI),
  [Fields.VAT_NUMBER]: formatMessage($_$_NUMBER, IMPORTERS, VAT),
  [Fields.PID_NUMBER]: formatMessage($_$_NUMBER, IMPORTERS, PID),
  [Fields.UKIMS_NUMBER]: formatMessage($_$_NUMBER, IMPORTERS, UKIMS),
};

// IMPORTS
// Messages
export const CANNOT_$ = "Cannot {0}";
export const RECEIPT_TEMPLATE_MUST_CONTAIN_DELIVERY_FIELDS =
  "Shipment receipt template must contain either Delivery consignment number or Delivery parcel numbers field";

// BUTTONS
export const IMPORT = "Import";
export const START_AUTO_IMPORT = "Start Auto Import";
export const STOP_AUTO_IMPORT = "Stop Auto Import";
export const EXPORT = "Export";
export const CHANGE_PRINTER_SETTINGS = "Change Printer Settings";

// Titles
export const AVAILABLE_FIELDS = "Available Fields";
export const DATA_FILE_FIELD_ORDER = "Data File - Field Order";
export const FILE_OPTIONS = "File Options";
export const IMPORT_DIRECTORY = "Import Directory";
export const IMPORT_EXTENSION = "Import Extension";
export const FIELD_DELIMITER = "Field Delimiter";
export const GROUP_NAME_DELIMITER = "Group Name Delimiter";
export const PRODUCT_DELIMITER = "Product Delimiter";
export const FILE_FIELD_DELIMITER = "File Field Delimiter";
export const PRODUCT_FIELD_DELIMITER = "Product Field Delimiter";
export const PRINT_LABEL_ON_IMPORT = "PRINT LABEL ON IMPORT";
export const SELECT_PRINTING_SEQUENCE = "Select Printing Sequence";
export const DISABLE_BAD_LABELS = "DISABLE BAD LABELS";
export const AUTO_IMPORT = "AUTO IMPORT";
export const HEADER_LINES = "Header Lines";
export const FOOTER_LINES = "Footer Lines";
export const WEIGHT_OVERRIDE_CHECKBOX = "WEIGHT = 0 SET TO";
export const TEMPLATE_PROFILE = "Template Profile";
export const DEFAULT_TEMPLATE = "DEFAULT TEMPLATE";
export const TEMPLATES = "Templates";
export const FILE_DEFINITION = "File Definition";
export const OVERRIDE_WEIGHT = "Override Weight";
export const SAVE_TEMPLATE = "Save Template";
export const CREATE_RECEIPT_FILE = "CREATE RECEIPT FILE";
export const ONE_RECEIPT_PER_SHIPMENT = "ONE RECEIPT PER SHIPMENT";
export const ONE_LINE_PER_SHIPMENT = "ONE LINE PER SHIPMENT";
export const USE_REFERENCE_FIVE = "USE REFERENCE 5 FOR NAME";
export const EXPORT_DIRECTORY = "Export Directory";
export const EXPORT_EXTENSION = "Export Extension";
export const EXPORT_FILE = "Export File";
export const CANNOT_APPEND_EXPORT_FILE = "Cannot append export file";
export const CANNOT_READ_EXPORT_FILE = "Cannot read export file";
export const CANNOT_MOVE_EXPORT_FILE = "Cannot move export file";
export const CANNOT_WRITE_EXPORT_FILE = "Cannot write export file";
export const EXPORT_FILE_BEHAVIOR = "Export File Behavior";
export const TEST_FILE_LOCATION = "Test File Location";
export const SELECT_FILE = "Select File";
export const PRODUCTBOOK_DELIMITER = "Productbook delimiter";

// Labels
export const FIELD_ORDER = "Data File Field Order";
export const MANDATORY = "Mandatory";
export const TEMPLATE_NAME = "Template Name";
export const TRANSLATION = "TRANSLATION";

// Snackbars
export const TEMPLATES_TYPE_LIST = "Shipment Templates Type List";
export const DELIMITER_LIST = "Shipment Templates Delimiter List";
export const ADDRESSBOOK_DELIMITER_LIST =
  "Addressbook Templates Delimiter List";
export const PRODUCT_BOOK_DELIMITER_LIST =
  "Product Book Templates Delimiter List";
export const TEMPLATE = "Template";
export const _$_TEMPLATE = `{0} ${TEMPLATE}`;
export const TEMPLATE_TRANSLATIONS = "Template translations";
export const SHIPMENT_IMPORT_TEMPLATES = "Shipment import templates";
export const SHIPMENT_EXPORT_TEMPLATE = "Shipment export template";
export const IMPORT_ORDER_DEFAULT = "Import order (default)";
export const NO_TRANSLATION = "No translation";
export const USER_DIRECTORIES = "User directories";
export const UPLOAD_FILE_CHANGED =
  "File has been changed. Please try to select again.";

export const ADDRESSBOOK_TYPE_CODES = {
  deliveryAddressbook: "0",
  returnAddressbook: "1",
};

export const REFERENCE_FIELDS = [
  "customer_ref1",
  "customer_ref2",
  "customer_ref3",
  "customer_ref4",
];

export const IMPORT_DEFAULT_SEQUENCE = {
  label: IMPORT_ORDER_DEFAULT,
  value: 0,
};

export const NO_TRANSLATION_DEFAULT = { label: NO_TRANSLATION, value: "" };

export const BEHAVIOR_EXPORT_FILE = [
  { label: "Append", value: "A" },
  { label: "Overwrite", value: "OW" },
  { label: "Overwrite with Backup", value: "OW_BK" },
];

// Messages
export const TEMPLATE_SAVED = "Template Saved";
export const TEMPLATE_SAVED_MESSAGE =
  "Your template changes have been saved successfully.";
export const TEMPLATE_IMPORTED_SUCCESSFULLY =
  "Template was successfully imported.";
export const TEMPLATE_DELETE_MESSAGE =
  "Are you sure you want to delete this template?";
export const TEMPLATE_DELETED = "Template Deleted";
export const TEMPLATE_DELETED_MESSAGE =
  "Your template was successfully removed";

// IMPORT ACTIVITY
export const MYDPD_CUSTOMER_APP = "MyDPD Customer App";
export const UNABLE_TO_RETRIEVE_EXPORT_DATA =
  "Unable to retrieve the Export data from the server. Please try again later";
export const PROCESS_FINISHED_WAS_NOT_GENERATED_PLEASE_SELECT_EXPORT_FILE_BEHAVIOR_FOR_EXPORT_TEMPLATE =
  "Process finished, export file was not generated, please select export file behavior for export template";
export const FAILED_TO_GET_THE_EXPORT_FILE_TEMPLATE =
  "Failed to get the export file template";
export const NO_DATA_TO_EXPORT = "There is no data to export";
export const CANNOT_CREATE_EXPORT_FILE =
  "Cannot create Export file with the Export File path provided. Please correct the Export File path and try again";
export const GENERATED_EXPORT_FILE_$ = "Generated export file: {0}";
export const CANNOT_WRITE_RECEIPT_FILE_$ = `Cannot write receipt file, check ${MYDPD_CUSTOMER_APP} is running and that directory {0} exists and is writable`;
export const TEMPLATE_IMPORT_CONFIRMATION_MESSAGE =
  "Are you sure you want to export the shipment data?";
export const EXPORT_FILE_WOS_NOT_GENERATED =
  "The export file was not generated. No shipments exist";
export const PROCESS_FINISHED_EXPORT_FILE_WOS_NOT_GENERATED =
  "Process finished, export file was not generated, please select export file behavior for export template";
export const ERROR_DURING_EXPORT = "There was an error during export";
export const EXPORT_COMPLETED_SUCCESSFULLY = "Export completed successfully";

// Buttons
export const CLEAR_ACTIVITY = "Clear activity";
export const GENERATE = "Generate";
export const TEST = "Test";
export const IMPORT_ON_DEMAND = "Import On Demand";
export const YES = "Yes";
export const NO = "No";
export const OK = "Ok";

// Titles
export const GENERATE_EXPORT_FILE = "Generate Export File";
export const ARE_YOU_SURE = "Are you sure?";
export const TEST_IMPORT_FILE = "Test Import File";
export const TEST_IMPORT_ERRORS = "Test Import Errors";
export const ISSUES = "Issues";

// Messages
export const CLEAR_IMPORT_ACTIVITY_MESSAGE =
  "Are you sure you want to clear your import activity? Confirming this action will permanently delete all entries.";
export const FAILED_TO_CONNECT_WITH_APP = `Failed to connect with ${MYDPD_CUSTOMER_APP}`;
export const TXT_EXTENSION = ".txt";
export const CSV_EXTENSION = ".csv";
export const INCOMPLETE_EXPORT_FILE_NAME = ` The Export File name is incomplete. Please add a 3 character extension to the Export File name and try again. Example: ("${TXT_EXTENSION}", "${CSV_EXTENSION}")`;
export const ADD_3_CHARACTERS_EXTENSION =
  "Please add a 3 character extension to the Export File name";
export const PROVIDE_CORRECT_FILE_PATH =
  "Please provide the correct Export File path";

// FAILED IMPORTS

// Titles
export const EDIT_FAILED_SHIPMENT = "Edit Failed Shipment";

// Messages
export const FAILED_SHIPMENT = "Failed shipment";
export const FAILED_SHIPMENT_DELETED = "Failed shipment Deleted";
export const FAILED_SHIPMENTS_DELETED = "Failed shipments Deleted";
export const FAILED_SHIPMENT_DELETED_MESSAGE =
  "Failed shipment has been successfully deleted";
export const FAILED_SHIPMENTS_DELETED_MESSAGE =
  "Failed shipments have been successfully deleted";
export const TEST_IMPORT_FILE_SUCCESS = "Successfully tested import file.";
export const TEST_IMPORT_FILE_SUCCESS_NO_ERRORS_FOUND =
  "Successfully tested import file. No errors found.";
export const PRODUCT_ERROR_MESSAGE = "Product error message";
export const PRODUCT_CURRENCY_MISMATCH =
  "Product currency mismatch with the shipment currency";

export const ADDRESS_BOOK_TYPES = {
  DELIVERY: "0",
  RETURN: "1",
};

export const FAILED_TO_RUN_IMPORT_ON_DEMAND = "Failed to run Import on Demand";

export const VALUE_FOR_$_IS_ALREADY_IN_USE = forWhat =>
  `The value for '${forWhat}' is already in use`;

export const UNIQUE_REFERENCE_1_ERROR_MESSAGE_$ = consignmentNumber =>
  `The shipment details you have entered cannot be saved. The value you have entered for 'Your Reference 1' is already in use on consignment ${consignmentNumber}. Please change your Reference 1 and then try again.`;

// SCANNING

// Labels
export const SCAN_REFERENCE = "Scan Reference";

// Messages
export const SHIPMENT_IS_VOIDED = "Shipment is voided";
export const SHIPMENT_IS_PRINTED = "Shipment has already been printed";
export const NO_PERMISSIONS_TO_PRINT_SHIPMENT =
  "You don't have the correct permissions to print this shipment";
export const UNABLE_TO_RETURN_SHIPMENT_OLDER_THAN_10_DAYS_IN_PAST =
  "Unable to return shipment: older than 10 days in the past";

// TOOLS page
// Titles
export const EDIT_DETAILS_TITLE = "Edit Details";
export const CLEAR_UNPRINTED = "Clear Unprinted";

// labels
export const GROUP_DISPATCH_LABEL = "groupDispatch";
export const EDIT_DETAILS = "editDetails";
export const SHIPMENTS_PER_LINE = "Shipments Per Line";
export const WEIGHT_PER_SHIPMENT = "Weight Per Shipment";
export const LABELS_PER_LINE = "Labels Per Line";
export const WEIGHT_PER_LABEL = "Weight Per Label";
export const REQUIRED_SERVICE = "Required Service";
export const RETURN_ADDRESS = "Return Address";
export const GROUP = "Group";
export const NAME = "Name";
export const GROUP_DISPATCH_MODE = "GROUP_DISPATCH";
export const BULK_REVERSE_MODE = "BULK_REVERSE";
export const VALIDATION_ERROR_$ = "Validation Errors ({0})";
export const ALL = "All";

// Buttons
export const CLICK_HERE = "Click Here";
export const CONFIRM = "Confirm";
export const CHANGE_DEFAULT_SHIPMENT_DATE = "Change Default Shipment Date";
export const END_OF_DAY = "End of Day";
export const RUN_END_OF_DAY = "Run End-of-Day";
export const CLOSE = "Close";
export const SEE_MORE = "See More";

// Snackbars
export const ADDRESS_BOOK_GROUPS = "addressbook groups";
export const GROUP_DISPATCH_SERVICES = "group dispatch services";
export const GROUP_ADDRESSES = "group addresses";

// Messages
export const SERVICE_IS_RESTRICTED =
  "Service is restricted, please select a valid service.";
export const SWITCH_TO_THERMAL_PRINTER_TO_USE_$ =
  "Please switch to thermal printer in order to use {0}.";
export const SELECT_ADDRESSES =
  "Please select addresses before creating labels.";
export const ERRORS_ON_ADDRESS_$ = "There were errors on address {0}:";
export const DISABLE_ERROR_MESSAGES = "Disable Error Messages";
export const PRINT_ALL_UNPRINTED = "Print all unprinted";
export const CLEAR_UNPRINTED_SHIPMENTS = "Clear unprinted shipments";
export const DEFAULT_SHIPMENT_DAY_$ = "Your default shipment day is: {0}";
export const REMOVED_$_UNPRINTED_SHIPMENTS =
  "You have successfully removed {0} unprinted shipments";
export const NO_PRINTED_SHIPMENTS = "No printed shipments";
export const HAVE_NOT_SHIPMENTS =
  "You currently don't have any unprinted shipments to clear";
export const RUN_END_DAY_RELEASE_FILE =
  "Run End of Day (incl. Release File if applicable)";
export const DISABLED_AS_MASTER_EOD_USER =
  "A4 manifest printing is disabled as Master EOD user";
export const DISABLED_FOR_MANIFEST = "A4 print was disabled for this manifest";
export const PRINTING_COMPLETED = "Printing completed!";
export const EOD_IS_RUNNING = "EOD is running ...";
export const MANIFESTING_SHIPMENTS = "Manifesting shipments ...";
export const GETTING_SHIPMENTS_FOR_EXPORT = "Getting shipments for export ...";

// Ship to shop
export const SELECT_PICKUP_LOCATION_TITLE = "Select Pickup Location";
export const SHOP = "Shop";
export const LOCKER = "Locker";
export const _$_MILES = "{0} Miles";
export const SELECT_PICKUP_LOCATION = "Select Pickup Location:";
export const OPENS_$ = "Opens {0}";
export const CLOSES_$ = "Closes {0}";
export const OPEN = "Open";
export const CLOSED = "Closed";
export const CLOSED_FOR_LUNCH = "Closed for lunch";
export const CHANGE = "Change";
export const SELECT_PICKUP_POINT = "Select Pickup Point";
export const FILTERS = "Filters";
export const PICKUP_LOCATIONS = "pickup locations";
export const PLEASE_SELECT_PICKUP_LOCATION = "Please select a pickup location";
export const SORRY = "Sorry";
export const NO_PICKUP_LOCATIONS_AVAILABLE =
  "There are no pickup locations available in this area.";
export const TRY_DIFFERENT_POSTCODE = "Please try different postcode.";
export const PICKUP_LOCATION = "Pickup Location";
export const LIST_VIEW = "List View";
export const MAP_VIEW = "Map View";
