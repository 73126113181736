const PickupShopWindowLabel = props => (
  <svg width="55" height="55" viewBox="0 0 200 200" fill="none" {...props}>
    <path d="M200,0H0v117.6h200V0z" fill="#FFFFFF" />
    <path
      fill="#414042"
      d="M121,75c-2.6,0.7-6.1,1-9.1,1c-7.7,0-12.8-4.1-12.8-11.6c0-7.1,4.7-11.7,11.7-11.7c1.6,0,3.2,0.2,4.2,0.7V43.2
	h6C121,43.2,121,75,121,75z M115,58.8c-1-0.5-2.2-0.7-3.7-0.7c-3.6,0-6.1,2.3-6.1,6.2c0,4.2,2.6,6.6,6.9,6.6c0.8,0,1.9-0.1,2.9-0.2
	L115,58.8L115,58.8z M170.2,75c-2.7,0.7-6.1,1-9.1,1c-7.7,0-12.8-4.1-12.8-11.6c0-7.1,4.8-11.7,11.7-11.7c1.5,0,3.2,0.2,4.2,0.7
	V43.2h6L170.2,75L170.2,75z M164.2,58.8c-1-0.5-2.2-0.7-3.7-0.7c-3.6,0-6.1,2.3-6.1,6.2c0,4.2,2.6,6.6,6.9,6.6
	c0.8,0,1.9-0.1,2.9-0.2L164.2,58.8L164.2,58.8z M130.4,58.7c1-0.4,2.4-0.5,3.6-0.5c3.7,0,6.2,2.1,6.2,6c0,4.5-2.8,6.6-6.6,6.6V76
	c0.1,0,0.2,0,0.3,0c7.7,0,12.4-4.3,12.4-12.1c0-7-4.9-11.2-12.2-11.2c-3.7,0-7.3,0.8-9.7,1.8v29.9h6.1V58.7H130.4z"
    />
    <path
      fill="#DC0032"
      d="M56.3,91.3c0.3,0,0.7-0.1,0.9-0.2l24.5-14.4c0.5-0.3,0.9-1.1,0.9-1.7V47.9l-16.1,9.4c-0.2,0.1-0.6,0.1-0.9,0
	l-1.4-0.8c-0.1-0.1-0.2-0.2-0.3-0.3l0,0c-0.1-0.1-0.1-0.3-0.1-0.4v-1.7c0-0.3,0.2-0.6,0.4-0.8l17.1-10l-24-13.2
	c-0.3-0.1-0.6-0.2-1-0.2l0,0c-0.3,0-0.7,0.1-1,0.2l-24,13.1l26.9,15.7c0.2,0.1,0.4,0.5,0.4,0.8v22.9c0,0.3-0.2,0.6-0.5,0.8l-1.5,0.8
	c-0.1,0.1-0.3,0.1-0.4,0.1l0,0c-0.2,0-0.3,0-0.5-0.1l-1.5-0.8c-0.2-0.1-0.5-0.5-0.5-0.8V62.1c0-0.1-0.1-0.3-0.2-0.4L30,47.9V75
	c0,0.6,0.4,1.4,1,1.7L55.4,91C55.6,91.2,56,91.3,56.3,91.3z"
    />
    <path fill="#DC0032" d="M200,117.6H0V200h200V117.6z" />
    <path
      fill="#FFFFFF"
      d="M45.9,162.7c0.5,0.3,1.2,0.7,1.9,1c0.8,0.3,1.6,0.7,2.4,0.9c0.9,0.3,1.8,0.5,2.8,0.7s2,0.3,3.1,0.3
	c2.4,0,4.2-0.4,5.7-1.3c1.4-0.8,2.2-2.1,2.2-3.7c0-1.1-0.4-2-1.3-2.7c-0.8-0.7-2.3-1.3-4.2-1.7l-5.8-1.4c-2.7-0.6-4.7-1.7-6-3.1
	c-1.3-1.4-2-3.2-2-5.3c0-1.3,0.2-2.4,0.7-3.5s1.3-2.1,2.3-3c1-0.8,2.3-1.5,3.9-2c1.6-0.5,3.4-0.8,5.6-0.8c1.1,0,2.2,0.1,3.3,0.2
	c1.1,0.1,2.1,0.3,3.1,0.6c1,0.2,1.9,0.5,2.7,0.8c0.8,0.3,1.6,0.6,2.2,0.9l-1.5,4.5c-0.6-0.3-1.2-0.7-2-0.9c-0.8-0.3-1.6-0.6-2.5-0.8
	c-0.9-0.3-1.8-0.4-2.7-0.6c-0.9-0.2-1.9-0.2-2.7-0.2c-2.5,0-4.3,0.4-5.5,1.3c-1.1,0.8-1.7,1.8-1.7,3c0,1.1,0.4,2,1.1,2.6
	c0.8,0.6,1.9,1.1,3.3,1.5l6.2,1.6c2.9,0.8,5,1.8,6.5,3.2s2.2,3.2,2.2,5.4c0,1.5-0.3,2.8-1,4.1c-0.6,1.2-1.5,2.2-2.7,3.1
	c-1.2,0.8-2.5,1.5-4.2,2c-1.6,0.5-3.3,0.7-5.2,0.7c-1.2,0-2.3-0.1-3.4-0.3c-1.1-0.2-2.2-0.4-3.2-0.7s-1.9-0.6-2.7-0.9
	c-0.8-0.3-1.4-0.7-1.9-0.9L45.9,162.7z M78,136.2v12.5c0.9-1.3,2.1-2.3,3.6-3c1.5-0.8,3.2-1.1,5.2-1.1c1.7,0,3.1,0.3,4.3,0.8
	s2.2,1.3,3,2.3s1.4,2.1,1.7,3.4c0.4,1.3,0.6,2.8,0.6,4.3v14.2h-4.9v-13.4c0-2.1-0.5-3.8-1.5-5.1s-2.6-2-4.8-2c-1,0-1.9,0.2-2.7,0.5
	c-0.8,0.3-1.5,0.7-2.1,1.2s-1.1,1.1-1.5,1.7c-0.4,0.6-0.7,1.3-0.9,1.9v15.2h-4.9v-33.5H78V136.2z M100.3,157.4
	c0-1.9,0.3-3.6,0.9-5.2c0.7-1.6,1.6-3,2.7-4.2c1.2-1.2,2.5-2.1,4.2-2.7c1.6-0.7,3.4-1,5.3-1s3.7,0.3,5.3,1c1.6,0.7,3,1.6,4.2,2.7
	c1.2,1.2,2.1,2.5,2.7,4.2c0.7,1.6,1,3.3,1,5.2s-0.3,3.6-1,5.1c-0.7,1.6-1.6,2.9-2.7,4.1c-1.2,1.2-2.6,2.1-4.2,2.7
	c-1.6,0.6-3.4,0.9-5.3,0.9s-3.7-0.3-5.3-0.9c-1.6-0.7-3-1.6-4.2-2.7c-1.2-1.2-2.1-2.5-2.7-4.1C100.6,161,100.3,159.3,100.3,157.4z
	 M105,157.4c0,1.2,0.2,2.3,0.6,3.3s1,1.9,1.7,2.6c0.8,0.8,1.7,1.4,2.7,1.8c1,0.4,2.2,0.6,3.4,0.6c1.3,0,2.4-0.2,3.4-0.6
	c1.1-0.4,2-1,2.7-1.8c0.8-0.8,1.3-1.6,1.7-2.6c0.4-1,0.6-2.2,0.6-3.3c0-1.2-0.2-2.4-0.6-3.4s-1-1.9-1.7-2.7s-1.6-1.4-2.7-1.8
	c-1-0.4-2.2-0.7-3.4-0.7c-1.2,0-2.4,0.2-3.4,0.7c-1,0.4-1.9,1-2.7,1.8c-0.7,0.8-1.3,1.7-1.7,2.7C105.2,155.1,105,156.2,105,157.4z
	 M155.4,157.4c0,1.9-0.3,3.6-0.8,5.1c-0.6,1.5-1.4,2.9-2.4,4s-2.3,2-3.7,2.6s-3,0.9-4.8,0.9c-1.7,0-3.2-0.3-4.5-0.9
	c-1.3-0.6-2.4-1.3-3.3-2.2v11.5H131V145h4.9v2.8c0.9-0.9,2-1.7,3.3-2.3s2.8-0.9,4.6-0.9s3.4,0.3,4.8,0.9c1.4,0.6,2.7,1.5,3.7,2.6
	s1.8,2.5,2.4,4.1C155.1,153.8,155.4,155.6,155.4,157.4z M150.5,157.4c0-1.3-0.2-2.4-0.6-3.4c-0.4-1.1-0.9-2-1.7-2.7
	c-0.7-0.8-1.5-1.3-2.5-1.7s-2.1-0.6-3.3-0.6c-1.4,0-2.7,0.3-3.9,0.9c-1.2,0.6-2.1,1.2-2.7,1.9v11.4c0.7,0.7,1.6,1.4,2.7,1.9
	c1.2,0.6,2.4,0.8,3.8,0.8c1.2,0,2.3-0.2,3.3-0.6c1-0.4,1.9-1,2.5-1.7c0.7-0.8,1.3-1.7,1.7-2.7C150.3,159.9,150.5,158.7,150.5,157.4z"
    />
  </svg>
);

export default PickupShopWindowLabel;
