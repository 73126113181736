import values from "lodash/values";
import zipObjectDeep from "lodash/zipObjectDeep";
import { connect } from "react-redux";
import { compose, withHandlers } from "recompose";

import { OUTBOUND_CONSIGNMENT, ShipmentEntity } from "~/constants/forms";
import { AddressModels, InvoiceModels, NewShipmentModels } from "~/models";
import { getValue, toUppercaseValues } from "~/utils/object";
import { touchFields } from "~/utils/reduxForm";
import { DELIVERY } from "~/constants/strings";

// eslint-disable-next-line import/no-anonymous-default-export
export default formSection =>
  compose(
    connect(null, (dispatch, { reInitializeForm }) => ({
      updateForm: values => reInitializeForm(values),
    })),
    withHandlers({
      onPostcodeSelectionChange:
        ({
          createShipmentValues,
          updateForm,
          dispatch,
          pageConfig,
          checkPostcodeIssue,
          prevPostcodeIssue,
          setPrevPostcodeIssue,
          selectedService,
          customer,
          setDeliveryAddress,
        }) =>
        (address = {}, shipmentSection) => {
          // set ship to shop service data
          setDeliveryAddress(address);

          // @see: comments in the https://geopost.jira.com/browse/CSHIP-5594
          shipmentSection &&
            updateForm(
              zipObjectDeep([
                ...values(
                  ShipmentEntity[shipmentSection].DELIVERY_DETAILS
                    .CONTACT_DETAILS
                ),
                ...values(
                  ShipmentEntity[shipmentSection].DELIVERY_DETAILS
                    .NOTIFICATION_DETAILS
                ),
              ])
            );

          const result = {
            ...createShipmentValues,
            ...AddressModels.updateDeliveryDetails(formSection, address),
          };
          const { exporterImporterDetails } =
            NewShipmentModels.isShippingInformationVisible(
              customer,
              selectedService,
              {
                generateCustomsData: getValue(
                  result,
                  ShipmentEntity.GENERATE_CUSTOMS_DATA,
                  false
                ),
                countryCode: getValue(
                  result,
                  ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
                    .COUNTRY_CODE,
                  ""
                ),
                postCode: getValue(
                  result,
                  ShipmentEntity.OUTBOUND_CONSIGNMENT.DELIVERY_DETAILS.ADDRESS
                    .POSTCODE,
                  ""
                ),
              }
            );

          // CASE: select addressBook, then select address with other street
          // if invoice was available - should update importerDetails (without new fetchServices)
          if (
            formSection === OUTBOUND_CONSIGNMENT &&
            result[ShipmentEntity.GENERATE_CUSTOMS_DATA] &&
            exporterImporterDetails
          ) {
            result.invoice.importerDetails = {
              ...result.invoice.importerDetails,
              ...InvoiceModels.addImporterAddressDetails(result),
            };
          }
          updateForm(toUppercaseValues(result));
          dispatch(touchFields(pageConfig.formName, result));

          if (prevPostcodeIssue !== address.postcode) {
            checkPostcodeIssue(
              address.postcode,
              DELIVERY,
              issue => issue?.postcodeIssue?.[0]?.postcodeDelay !== 0
            );
            setPrevPostcodeIssue(address.postcode);
          }
        },
    })
  );
