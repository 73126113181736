import classNames from "classnames";
import { get } from "lodash";
import { Button } from "@dpdgroupuk/mydpd-ui";

import {
  getPickupAmenities,
  getPickupOpenWindowList,
} from "~/models/pickupPoint";
import {
  PickupLockerWindowLabel,
  PickupShopWindowLabel,
} from "~/components/Icon";
import PickupPointImage from "~/assets/images/pickup-point.png";
import PickupDetails from "../PickupDetails/PickupDetails";
import PickupAmenities from "../PickupAmenities/PickupAmenities";
import OpenWindowList from "../OpenWindowList/OpenWindowList";
import { SELECT_PICKUP_POINT } from "~/constants/strings";
import { PICKUP_POINT_TYPE } from "~/constants/pickupPoint";
import { joinStringsWithComma } from "~/utils/string";

import styles from "./ActivePickupPoint.module.scss";

const ActivePickupPoint = ({ pickupPoint, onSelectPickupPoint }) => (
  <div className={classNames(styles.container, "d-flex flex-column")}>
    <div className={classNames(styles.partnerLogoContainer, styles.divider)}>
      <img src={PickupPointImage} alt="Partner Logo" className={styles.image} />
      {pickupPoint.pickupLocation.kind === PICKUP_POINT_TYPE.SHOP ? (
        <PickupShopWindowLabel className={styles.pickupLabel} />
      ) : (
        <PickupLockerWindowLabel className={styles.pickupLabel} />
      )}
    </div>
    <div className={styles.pickupDetails}>
      <div
        className={classNames(
          styles.sectionContainer,
          "d-flex flex-column justify-content-center"
        )}
      >
        <h5 className="mt-1">
          {get(pickupPoint, "pickupLocation.address.organisation")}
        </h5>
        <span className={styles.address}>
          {joinStringsWithComma([
            get(pickupPoint, "pickupLocation.address.street"),
            get(pickupPoint, "pickupLocation.address.city"),
            get(pickupPoint, "pickupLocation.address.postcode"),
          ])}
        </span>
        <div className="py-2 px-4">
          <Button
            variant="danger"
            onClick={onSelectPickupPoint}
            className="w-100 p-2 rounded"
          >
            {SELECT_PICKUP_POINT.toUpperCase()}
          </Button>
        </div>
      </div>
      <div className={styles.sectionContainer}>
        <PickupDetails pickupPoint={pickupPoint} displayOpenClosedDetails />
      </div>
      <div className={classNames(styles.sectionContainer, styles.divider)}>
        <OpenWindowList openWindows={getPickupOpenWindowList(pickupPoint)} />
      </div>
      <div className={styles.sectionContainer}>
        <PickupAmenities {...getPickupAmenities(pickupPoint)} />
      </div>
    </div>
  </div>
);

export default ActivePickupPoint;
