const PickupLockerWindowLabel = props => (
  <svg width="55" height="55" viewBox="0 0 200 200" {...props}>
    <path fill="#FFFFFF" d="M200,0H0v117.6h200V0z" />
    <path fill="#DC0032" d="M200,117.6H0V200h200V117.6z" />
    <rect x="42" y="20" fill="#F2F2F2" width="116" height="79" />
    <path
      fill="#808285"
      d="M94.4,73.9h11.2c1,0,1.9-0.8,1.9-1.9c0-1-0.8-1.9-1.9-1.9H94.4c-1,0-1.9,0.8-1.9,1.9S93.3,73.9,94.4,73.9z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      fill="#808285"
      d="M160,100V23.6c0-3.1-2.5-5.6-5.6-5.6H45.6c-3.1,0-5.6,2.5-5.6,5.6V100H160z M111.2,91H88.7V21.7h22.5V91z
		   M43.8,91V73.9H85V91H43.8z M156.2,91H115V73.9h41.3V91H156.2z M85,47.8v22.4H43.8V47.8H85z M85,44.1H43.8V23.6
		  c0-1,0.8-1.9,1.9-1.9H85V44.1z M115,21.7v22.4h41.3V23.6c0-1-0.8-1.9-1.9-1.9H115z M115,70.2h41.2V47.8H115V70.2z"
    />
    <path
      fill="#CAC4BE"
      d="M107.5,62.7h-15V40.4h15V62.7z M96.3,59h7.5V44.1h-7.5V59z"
    />
    <path fill="#CAC4BE" d="M93,41h14v21H93V41z" />
    <path
      fill="#FFFFFF"
      d="M32.5,169v-30.6h4.7V165h16.7v4.1H32.5V169z M55.1,157.4c0-1.8,0.3-3.4,0.9-4.9c0.6-1.5,1.5-2.8,2.6-4
		  c1.1-1.1,2.4-2,4-2.6c1.5-0.6,3.2-0.9,5-0.9s3.5,0.3,5.1,0.9c1.6,0.6,2.9,1.5,4,2.6s2,2.4,2.6,4c0.6,1.5,0.9,3.1,0.9,4.9
		  c0,1.8-0.3,3.4-0.9,4.9s-1.5,2.8-2.6,3.9c-1.1,1.1-2.4,2-4,2.6s-3.3,0.9-5.1,0.9c-1.8,0-3.5-0.3-5-0.9c-1.5-0.6-2.8-1.5-4-2.6
		  c-1.1-1.1-2-2.4-2.6-3.9C55.4,160.8,55.1,159.1,55.1,157.4z M59.6,157.4c0,1.1,0.2,2.2,0.6,3.2c0.4,1,0.9,1.8,1.6,2.5
		  c0.7,0.7,1.6,1.3,2.6,1.7s2.1,0.6,3.2,0.6c1.2,0,2.3-0.2,3.3-0.6c1-0.4,1.9-1,2.6-1.7s1.3-1.6,1.7-2.5c0.4-1,0.6-2.1,0.6-3.2
		  c0-1.2-0.2-2.2-0.6-3.2s-0.9-1.8-1.7-2.6c-0.7-0.7-1.5-1.3-2.6-1.7c-1-0.4-2.1-0.6-3.3-0.6c-1.2,0-2.2,0.2-3.2,0.6
		  c-1,0.4-1.8,1-2.6,1.7c-0.7,0.7-1.2,1.6-1.6,2.6C59.8,155.1,59.6,156.2,59.6,157.4z M82.7,157.3c0-1.8,0.3-3.4,0.9-4.9
		  s1.5-2.8,2.6-3.9c1.1-1.1,2.4-2,4-2.6s3.3-0.9,5.1-0.9c1.8,0,3.4,0.3,4.9,0.9c1.5,0.6,2.8,1.3,4,2.3l-2.3,3.5
		  c-0.7-0.7-1.6-1.3-2.7-1.7c-1-0.4-2.2-0.7-3.6-0.7c-1.2,0-2.3,0.2-3.3,0.6c-1,0.4-1.8,0.9-2.5,1.7c-0.7,0.7-1.2,1.5-1.6,2.5
		  s-0.6,2.1-0.6,3.2s0.2,2.2,0.6,3.2s0.9,1.8,1.6,2.6c0.7,0.7,1.5,1.3,2.5,1.7s2.1,0.6,3.3,0.6c1.4,0,2.6-0.2,3.6-0.7
		  c1-0.4,1.9-1,2.7-1.7l2.3,3.5c-1.1,1-2.5,1.8-4,2.4c-1.5,0.6-3.1,0.9-4.9,0.9c-1.9,0-3.6-0.3-5.1-0.9c-1.5-0.6-2.8-1.5-4-2.6
		  c-1.1-1.1-2-2.4-2.6-3.9C83,160.7,82.7,159,82.7,157.3z M128.4,146.3l-11.3,10.3l12.1,11.6v0.8h-5.6l-11.1-11v11h-4.7v-31.9h4.7
		  v18.4l10.7-10h5.2V146.3z M152.7,166.5c-1.2,1-2.7,1.8-4.3,2.3c-1.6,0.6-3.5,0.9-5.4,0.9c-2.2,0-4.1-0.3-5.8-0.9
		  c-1.6-0.7-2.9-1.5-4-2.7c-1-1.1-1.8-2.4-2.3-3.9s-0.7-3.1-0.7-4.8s0.3-3.4,0.9-4.9c0.6-1.5,1.4-2.8,2.5-4c1.1-1.1,2.4-2,3.9-2.7
		  s3.2-1,5.1-1c1.8,0,3.3,0.3,4.6,0.8s2.4,1.2,3.3,2.1c0.9,0.9,1.5,1.9,1.9,3.1c0.4,1.2,0.6,2.4,0.6,3.8c0,0.7,0,1.5-0.1,2.3
		  s-0.2,1.4-0.4,1.8H135c0.2,2.1,1,3.7,2.2,5c1.3,1.3,3.2,1.9,5.8,1.9c1.6,0,3.1-0.3,4.4-0.8c1.3-0.5,2.5-1.2,3.4-1.8L152.7,166.5z
		   M148.5,155.2c0-0.2,0-0.4,0-0.6c0-0.2,0-0.5,0-0.7c0-0.7-0.1-1.3-0.4-1.9c-0.2-0.6-0.6-1.2-1.1-1.6c-0.5-0.5-1.1-0.9-1.9-1.1
		  c-0.7-0.3-1.6-0.4-2.7-0.4c-1.1,0-2.1,0.2-3,0.5s-1.6,0.8-2.2,1.4c-0.6,0.6-1.1,1.2-1.4,2c-0.4,0.8-0.6,1.6-0.7,2.4L148.5,155.2
		  L148.5,155.2z M157.2,145.5h4.7v4.6c0.7-1.5,1.6-2.7,2.7-3.6c1.1-0.9,2.4-1.4,4-1.4c1,0,2,0.2,2.7,0.5c0.8,0.3,1.6,0.7,2.2,1.2
		  l-1.8,3.9c-0.5-0.4-1.1-0.7-1.8-0.9s-1.3-0.4-2.1-0.4c-1.5,0-2.8,0.5-3.8,1.6c-1,1-1.7,2.3-2.2,3.7V169h-4.7v-23.5H157.2z"
    />
    <path
      fill="#DC0032"
      opacity="0.5"
      d="M70.5,32.8c-0.3,0.3-0.8,0.3-1.1,0c-0.9-0.6-0.8-0.5-0.9-1.6c0-0.4,0.2-0.8,0.6-1c3.2-1.9,6.4-3.8,9.6-5.7
		  l0.4-0.3l-0.3-0.3c-1.3-0.7-2.7-1.5-4-2.2H53.3l-4,2.2l-0.4,0.3l0.5,0.3L64,33.1l1.1,0.6c0.2,0.1,0.3,0.4,0.3,0.6s0,0.3,0,0.4V48
		  c0,0.1,0,0.2,0,0.3c0,0.2-0.2,0.5-0.4,0.6l-0.7,0.4c-0.2,0.1-0.5,0.1-0.7,0l-0.7-0.4c-0.3-0.1-0.4-0.3-0.4-0.6v-0.4
		  c0-3.9,0-7.8,0-11.7c0-0.3-0.2-0.7-0.5-0.8l-13.5-8L48,27.1c0,0.2,0,0.3,0,0.5c0,5.2,0,10.4,0,15.7c0,0.7,0.3,1.2,0.7,1.6
		  c4.8,2.9,9.5,5.7,14.3,8.6c0.5,0.3,1.2,0.3,1.7,0c1.4-0.8,2.9-1.7,4.3-2.6l10-6c0.5-0.3,0.8-0.8,0.8-1.3v-16c0-0.2,0-0.3,0-0.4
		  l-0.5,0.3C76.4,29.3,73.4,31,70.5,32.8z"
    />
  </svg>
);

export default PickupLockerWindowLabel;
