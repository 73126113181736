import { useEffect, useMemo, useState } from "react";
import Map, { Marker, NavigationControl } from "react-map-gl";
import PropTypes from "prop-types";
import { get, pick, slice } from "lodash";
import classNames from "classnames";

import {
  calculateMapFitBounds,
  getPickupLocationCode,
} from "~/models/pickupPoint";
import { PICKUP_POINT_TYPE } from "~/constants/pickupPoint";
import PickupLocationPin from "../Icon/PickupLocationPin";
import LocationPin from "../Icon/LocationPin";

import styles from "./PickupPointsMap.module.scss";

import "mapbox-gl/dist/mapbox-gl.css";

const defaultCoordinates = {
  latitude: 54.9,
  longitude: -4,
};

const defaultMapZoom = 3.7;

const PickupLocationMarker = ({ pickupPoint, number, onPickupPointClick }) => {
  const pickupLocationCode = getPickupLocationCode(pickupPoint);
  const [isHighlighted, setIsHighlighted] = useState(false);

  return (
    <Marker
      latitude={pickupPoint?.pickupLocation?.addressPoint?.latitude}
      longitude={pickupPoint?.pickupLocation?.addressPoint?.longitude}
      key={pickupLocationCode}
      anchor="bottom"
      onClick={() => onPickupPointClick(pickupPoint)}
      style={{ zIndex: isHighlighted ? 2 : 1 }}
    >
      <div
        className={classNames(
          "d-flex justify-content-center",
          styles.mapPinContainer
        )}
        onMouseEnter={() => setIsHighlighted(true)}
        onMouseLeave={() => setIsHighlighted(false)}
      >
        <span className={styles.pickupNumber}>{number}</span>
        <PickupLocationPin
          isShop={pickupPoint.pickupLocation.kind === PICKUP_POINT_TYPE.SHOP}
        />
      </div>
    </Marker>
  );
};

PickupLocationMarker.propTypes = {
  pickupPoints: PropTypes.array,
  number: PropTypes.number,
  onPickupPointClick: PropTypes.func,
};

const PickupPointsMap = ({
  deliveryAddress,
  pickupPoints = [],
  onPickupPointClick,
  isSearchByDifferentPostcode,
}) => {
  const [mapRef, setMapRef] = useState(null);

  const isDeliveryAddressCoordinatesAvailable = !!(
    deliveryAddress?.latitude && deliveryAddress?.longitude
  );

  const pickupPointsCoordinates = pickupPoints.map(pickupPoint =>
    pick(get(pickupPoint, "pickupLocation.addressPoint"), [
      "latitude",
      "longitude",
    ])
  );

  const coordinatesToFit = useMemo(
    () =>
      isDeliveryAddressCoordinatesAvailable && !isSearchByDifferentPostcode
        ? [
            pick(deliveryAddress, ["latitude", "longitude"]),
            ...pickupPointsCoordinates,
          ]
        : pickupPointsCoordinates,
    [
      deliveryAddress,
      pickupPointsCoordinates,
      isDeliveryAddressCoordinatesAvailable,
      isSearchByDifferentPostcode,
    ]
  );

  const initialCoordinates = coordinatesToFit[0];

  const fitBounds = useMemo(
    () =>
      coordinatesToFit.length > 1
        ? calculateMapFitBounds(slice(coordinatesToFit, 0, 5))
        : null,
    [coordinatesToFit]
  );

  useEffect(() => {
    if (mapRef && coordinatesToFit.length === 1) {
      mapRef.flyTo({
        center: [
          get(coordinatesToFit, "[0].longitude"),
          get(coordinatesToFit, "[0].latitude"),
        ],
        essential: true,
        duration: 3000,
        zoom: 14,
      });
    }

    if (mapRef && coordinatesToFit.length > 1) {
      mapRef.fitBounds(fitBounds, {
        padding: {
          top: 85,
          bottom: 25,
          left: 55,
          right: 55,
        },
        duration: 3000,
      });
    }
  }, [mapRef, fitBounds, coordinatesToFit, initialCoordinates]);

  return (
    <Map
      mapboxAccessToken={process.env.REACT_APP_MAPBOX_API_TOKEN}
      ref={ref => setMapRef(ref)}
      initialViewState={
        initialCoordinates
          ? {
              ...initialCoordinates,
              zoom: 12,
            }
          : { ...defaultCoordinates, zoom: defaultMapZoom }
      }
      mapStyle="mapbox://styles/mapbox/streets-v11"
      logoPosition="top-right"
      fitBounds={fitBounds}
      style={{
        width: "100%",
        height: "100%",
        position: "relative",
        background: "rgb(26, 29, 33)",
        borderRadius: 0,
        border: "none",
        zIndex: "1",
      }}
    >
      <NavigationControl position="bottom-right" />
      {pickupPoints.map((pickupPoint, index) => (
        <PickupLocationMarker
          pickupPoint={pickupPoint}
          number={index + 1}
          onPickupPointClick={onPickupPointClick}
        />
      ))}
      {isDeliveryAddressCoordinatesAvailable && (
        <Marker
          latitude={deliveryAddress?.latitude}
          longitude={deliveryAddress?.longitude}
          anchor="bottom"
        >
          <LocationPin />
        </Marker>
      )}
    </Map>
  );
};

PickupPointsMap.propTypes = {
  deliveryAddress: PropTypes.object,
  pickupPoints: PropTypes.array,
  onPickupPointClick: PropTypes.func,
  isSearchByDifferentPostcode: PropTypes.bool,
};

export default PickupPointsMap;
