const LeftArrow = props => (
  <svg
    width="8"
    height="10"
    viewBox="0 0 8 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.0876 1.175L5.9126 0L0.912598 5L5.9126 10L7.0876 8.825L3.27093 5L7.0876 1.175Z"
      fill="black"
      fill-opacity="0.56"
    />
  </svg>
);

export default LeftArrow;
